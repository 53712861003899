/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  fleetQuickFilterInitialValues,
  tripQuickFilterInitialValues,
  transactionsQuickFilterInitialValues
} from './types';

export * from './types';

export const initialState = {
  fleet: fleetQuickFilterInitialValues,
  trips: tripQuickFilterInitialValues,
  transactions: transactionsQuickFilterInitialValues
};

export const filtersSlice = createSlice({
  name: 'quickFilters',
  initialState,
  reducers: {
    setFleetmissingAssetsAndTransponders: (state, action: PayloadAction<string[]>) => {
      state.fleet.missingItems = action.payload;
    },
    clearFleetmissingAssetsAndTransponders: state => {
      state.fleet.missingItems = [];
    },
    setTransactionsType: (state, action: PayloadAction<string[]>) => {
      state.transactions.transactionsType = action.payload;
    },
    clearTransactionsType: state => {
      state.transactions.transactionsType = [];
    },
    setFleetVehicleNames: (state, action: PayloadAction<string[]>) => {
      state.fleet.vehicleNames = action.payload;
    },
    clearFleetVehicleNames: state => {
      state.fleet.vehicleNames = [];
    },
    setFleetVehicleLicensePlateNumbers: (state, action: PayloadAction<string[]>) => {
      state.fleet.licensePlateNumbers = action.payload;
    },
    clearFleetVehicleLicensePlateNumbers: state => {
      state.fleet.licensePlateNumbers = [];
    },
    setFleetVehicleLicensePlateStates: (state, action: PayloadAction<string[]>) => {
      state.fleet.licensePlateStates = action.payload;
    },
    clearFleetVehicleLicensePlateStates: state => {
      state.fleet.licensePlateStates = [];
    },
    setFleetTagNumbers: (state, action: PayloadAction<string[]>) => {
      state.fleet.tagNumbers = action.payload;
    },
    clearFleetFleetTagNumbers: state => {
      state.fleet.tagNumbers = [];
    },
    setTripVehicleNames: (state, action: PayloadAction<string[]>) => {
      state.trips.vehicleNames = action.payload;
    },
    clearTripVehicleNames: state => {
      state.trips.vehicleNames = [];
    },
    setTripVehicleLicensePlateNumbers: (state, action: PayloadAction<string[]>) => {
      state.trips.licensePlateNumbers = action.payload;
    },
    setTripVehicleLicensePlateStates: (state, action: PayloadAction<string[]>) => {
      state.trips.licensePlateStates = action.payload;
    },
    clearTripVehicleLicensePlateNumbers: state => {
      state.trips.licensePlateNumbers = [];
    },
    clearTripVehicleLicensePlateStates: state => {
      state.trips.licensePlateStates = [];
    },
    setTripAmount: (state, action: PayloadAction<{ from: number; to: number }>) => {
      state.trips.tollAmount = action.payload;
    },
    clearTripAmount: state => {
      state.trips.tollAmount = tripQuickFilterInitialValues.tollAmount;
    },
    clearFleetFilters: state => {
      state.fleet.licensePlateNumbers = [];
      state.fleet.licensePlateStates = [];
      state.fleet.tagNumbers = [];
      state.fleet.vehicleNames = [];
      state.fleet.missingItems = [];
    },
    clearTripFilters: state => {
      state.trips.vehicleNames = [];
      state.trips.licensePlateNumbers = [];
    }
  }
});

export const {
  setTransactionsType,
  clearTransactionsType,
  setFleetmissingAssetsAndTransponders,
  clearFleetmissingAssetsAndTransponders,
  setFleetVehicleNames,
  clearFleetVehicleNames,
  setFleetVehicleLicensePlateNumbers,
  clearFleetVehicleLicensePlateNumbers,
  setFleetVehicleLicensePlateStates,
  clearFleetVehicleLicensePlateStates,
  setTripVehicleNames,
  clearTripVehicleNames,
  setTripVehicleLicensePlateNumbers,
  setTripVehicleLicensePlateStates,
  clearTripVehicleLicensePlateStates,
  clearTripVehicleLicensePlateNumbers,
  clearFleetFilters,
  clearTripFilters,
  setTripAmount,
  clearTripAmount,
  setFleetTagNumbers,
  clearFleetFleetTagNumbers
} = filtersSlice.actions;

export default filtersSlice.reducer;
