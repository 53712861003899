import { useEffect, useState } from 'react';
import api from '@api/services/common';
import { v4 as uuid } from 'uuid';
import { useAppSelector } from '@store/hooks';
import { ApiResponse, IData } from '../types';

export const useGetSOData = (selectedMonth: string) => {
  const customerId = useAppSelector(state => state.customer.id);
  const [data, setData] = useState<IData[]>([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setLoader(true);
    if (customerId) {
      api
        .post('/insights/savings-opportunities', {
          moduleId: 'MONTHLY_DETAIL',
          monthDate: selectedMonth
        })
        .then((res: ApiResponse) => {
          setLoader(true);
          if (res.isSuccess) {
            const categoryOrder = ['OVERCHARGES', 'POTENTIALSAVINGS', 'TRIPS', 'VIOLATIONS'];

            const sortedData = res?.data?.sort((a, b) => {
              const indexA = categoryOrder.indexOf(a.category);
              const indexB = categoryOrder.indexOf(b.category);

              if (indexA !== -1 && indexB !== -1) {
                return indexA - indexB;
              }

              if (indexA !== -1) {
                return -1;
              }

              if (indexB !== -1) {
                return 1;
              }

              return 0;
            });

            const modifiedData: IData[] = sortedData.map(item => ({
              id: uuid(),
              title: item?.category_name,
              savings: item?.total_amount,
              isOpen: false,
              momInPercent: item?.mom_change_perc,
              status: item?.score_text,
              score: item?.score,
              category: item?.category,
              content: item?.category_desc
            }));

            setData(modifiedData);
          }
        })
        .catch(err => {
          console.error('err ', err);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [customerId, selectedMonth]);

  const handleActiveTab = (id: string, value: boolean) => {
    setData(prevData => {
      const updatedData = prevData.map(item => ({
        ...item,
        isOpen: item.id === id ? !value : false
      }));
      return updatedData;
    });
  };

  return {
    data,
    loader,
    handleActiveTab
  };
};
