import { FC } from 'react';
import { motion } from 'framer-motion';
import { ETableName } from '@common/utils/enums';
// import _ from 'lodash';
import { useDataExport } from '@common/hooks';
import { Export, TollID } from '@assets/icons';
import Button from '@common/ui/Button';
import { openSubscribe } from '@store/features/subscribe';
import { useAppDispatch } from '@store/hooks';
import { routes } from '@common/utils/route-names';
import { useEditPermission } from '@pages/tables/hooks/useEditPermission';
import styles from './selected-entries.module.scss';
import { SelectedEntriesProps } from './types';

const SelectedEntries: FC<SelectedEntriesProps> = ({ table, selected }) => {
  const dispatch = useAppDispatch();
  const hasEditAccess = !useEditPermission(routes.fleet);
  const tableName = table.name as ETableName;
  const { handleDataExport } = useDataExport();

  return (
    <motion.section
      initial={{ height: 0, opacity: 0 }}
      animate={{
        opacity: 1,
        height: '5.5rem'
      }}
      exit={{ height: 0, opacity: 0 }}
      transition={{ duration: 0.2 }}
      layout
      className={styles.container}
    >
      <div className={styles.wrapper}>
        <p className={styles.entries}>
          {selected.length > 0 &&
            `${selected.length} ${selected.length === 1 ? 'entry' : 'entries'} selected`}
        </p>
        <Button
          appearance="button"
          label="Export"
          icon={<Export />}
          variant="secondary"
          size="xs"
          onClick={() => handleDataExport('xlsx', tableName, table.data || [], selected)}
        />
        {tableName === ETableName.FLEET && (
          <Button
            appearance="button"
            label="Subscribe"
            icon={<TollID />}
            variant="secondary"
            size="xs"
            onClick={() => dispatch(openSubscribe(true))}
            disabled={hasEditAccess}
          />
        )}
        {tableName === ETableName.TRIPS && (
          <Button
            appearance="button"
            label="Export Detail Data"
            icon={<Export />}
            variant="secondary"
            size="xs"
            onClick={() => handleDataExport('xlsx', tableName, table.data || [], selected, true)}
          />
        )}
      </div>
    </motion.section>
  );
};

export default SelectedEntries;
