import { memo, useEffect, useState } from 'react';
import { ChartOptions } from 'chart.js';
import MaterialCard from '@common/ui/Mui/Card/MaterialCard';
import BarChart from '@common/ui/Chart/BarChart/BarChart';
import { Button, Grid, useMediaQuery } from '@mui/material';
import { formatNumberToUSStandard } from '@pages/dashboard/common/helper';
import { useTheme } from '@mui/material/styles';
import { Repeat } from '@assets/icons';
import moment from 'moment-timezone';
import styles from './ptc.module.scss';
import usePlateTollData from './hook/usePlateTollData';
import { IFtoProps } from '../type';

const PlateTollConversion = ({
  width = '100%',
  height = '25.7rem',
  isParentLoading
}: IFtoProps) => {
  const {
    plateTollData,
    isLoading,
    showError,
    aggData,
    fetchPlateTollAggData,
    handleBarChatClick
  } = usePlateTollData(isParentLoading);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md')); // 1152 px
  const isMd2 = window.innerWidth > 950 && window.innerWidth < 970; /** range between 950 to 970 */
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  const [chartOptions, setChartOptions] = useState<ChartOptions<'bar'>>({
    responsive: true,
    layout: {
      autoPadding: true,
      padding: {
        right: 130, // lg : 130 xl : 130 md : 250 sm : 0 xs : 0
        bottom: 80 // lg : 80 xl : 80 md : 80 sm : 0 xs : 0
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            const dataIndex = tooltipItem.dataIndex || 0;

            const tolls = aggData?.tolls?.[dataIndex] ?? 0;
            const amount = aggData?.amount?.[dataIndex] ?? 0;

            const Amount = ` Amount: $${formatNumberToUSStandard(amount)}`;
            const Toll = ` Toll Count: ${formatNumberToUSStandard(tolls)}`;

            return [Toll, Amount];
          }
        }
      }
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Plate Tolls (count)',
          font: {
            weight: 'bold' // Set the title weight
          }
        },
        ticks: {
          font: {
            weight: 'bold'
          }
        }
      },
      x: {
        ticks: {
          font: {
            weight: 'bold'
          }
        }
      }
    },
    onClick: (event, element) => handleBarChatClick(event, element)
  });

  useEffect(() => {
    let newPaddingRight = 0;
    let newPaddingBottom = 0;

    switch (true) {
      case isXs:
      case isSm:
        newPaddingRight = 0;
        newPaddingBottom = 0;
        break;
      case !isMd2 && isMd:
        newPaddingRight = 200;
        newPaddingBottom = 80;
        break;
      case isMd2:
        newPaddingRight = 250;
        newPaddingBottom = 80;
        break;
      case isLg:
        newPaddingRight = 115;
        newPaddingBottom = 80;
        break;
      case isXl:
        newPaddingRight = 40;
        newPaddingBottom = 80;
        break;
      default:
        newPaddingRight = 0;
        newPaddingBottom = 0;
        break;
    }

    setChartOptions(prevOptions => ({
      ...prevOptions,
      layout: {
        ...prevOptions.layout,
        padding: {
          right: newPaddingRight,
          bottom: newPaddingBottom
        }
      }
    }));
  }, [isXs, isSm, isMd, isMd2, isLg, isXl]);

  return (
    <div className={styles.container}>
      <MaterialCard
        title="PlateTolls"
        subTitle={
          !isLoading && (
            <>
              Showing <b>{moment.utc(aggData.lastMonth).format('MMMM')}</b>
            </>
          )
        }
        cardVariant="child"
        cardWidth={width}
        cardHeight={height}
        noGrid
        showHeaderArrow
      >
        {isLoading && <div className={styles.loader}>Loading...</div>}
        {!isLoading && !showError && plateTollData && (
          <Grid container>
            <Grid xs={6} sm={6} md={6} lg={6}>
              <div className={styles.total}>
                <h4>Plate Tolls</h4>
                <p>{formatNumberToUSStandard(aggData.totalTolls)}</p>
              </div>
            </Grid>
            <Grid xs={6} sm={6} md={6} lg={6}>
              <div className={styles.total}>
                <h4>Total</h4>
                <p>{`$${formatNumberToUSStandard(aggData.total)}`}</p>
              </div>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12}>
              <BarChart
                data={plateTollData}
                width={isSm || isXs ? '100%' : '45rem'}
                options={chartOptions}
              />
            </Grid>
          </Grid>
        )}
        {!isLoading && (showError || !plateTollData) && (
          <div className={styles.error}>
            Something went wrong!
            <div className={styles.retry}>
              <Button
                size="small"
                variant="contained"
                onClick={fetchPlateTollAggData}
                startIcon={<Repeat className={styles.icon} />}
              >
                Retry
              </Button>
            </div>
          </div>
        )}
      </MaterialCard>
    </div>
  );
};

export default memo(PlateTollConversion);
