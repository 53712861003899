import { useEffect, useMemo, useState } from 'react';
import { Skeleton, useMediaQuery, useTheme } from '@mui/material';
import MaterialCard from '@common/ui/Mui/Card/MaterialCard';
import Savings from './savings/Savings';
import PlateTollConversion from './plate-toll-conversion/PlateTollConversion';
import DuplicateTolls from './duplicate-tolls/DuplicateTolls';
import TollSpend from './toll-spend/TollSpend';
import TotalDistanceTravelled from './total-distance-travelled/TotalDistanceTraveled';
import DisputableTolls from './disputable-tolls/DisputableTolls';
import useFtoAggData from './hooks/useFtoAggData';

const FleetTollOverview = () => {
  const commonDimension = useMemo(() => {
    return {
      height: '30rem',
      width: '100%'
    };
  }, []);
  const theme = useTheme();
  const { ftoAggData, isLoading } = useFtoAggData();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));
  const [cardHeight, setCardHeight] = useState('70rem');
  useEffect(() => {
    let newHeight = '71.5rem'; // Default height for LG
    switch (true) {
      case isLg:
        newHeight = '71.5rem';
        break;
      case isXl:
        newHeight = '71.5rem';
        break;
      case isMd:
        newHeight = '71.5rem';
        break;
      case isSm:
        newHeight = '80rem';
        break;
      default:
        newHeight = '80rem';
        break;
    }
    setCardHeight(newHeight);
  }, [isXs, isSm, isMd, isLg, isXl]);
  return (
    <>
      {!isLoading && (
        <MaterialCard
          cardHeight={cardHeight}
          title="Fleet Tolling Overview"
          helpLocation="dashboard_1"
          hasBorder
        >
          <Savings
            ftoAggData={ftoAggData}
            isParentLoading={isLoading}
            width={commonDimension.width}
            height={commonDimension.height}
          />
          <PlateTollConversion
            isParentLoading={isLoading}
            width={commonDimension.width}
            height={commonDimension.height}
          />
          <DuplicateTolls
            isParentLoading={isLoading}
            width={commonDimension.width}
            height={commonDimension.height}
          />
          <TollSpend
            isParentLoading={isLoading}
            width={commonDimension.width}
            height={commonDimension.height}
          />
          <TotalDistanceTravelled
            isParentLoading={isLoading}
            width={commonDimension.width}
            height={commonDimension.height}
          />
          <DisputableTolls
            isParentLoading={isLoading}
            ftoAggData={ftoAggData}
            width={commonDimension.width}
            height={commonDimension.height}
          />
        </MaterialCard>
      )}
      {isLoading && (
        <MaterialCard
          cardHeight={cardHeight}
          title="Fleet Tolling Overview"
          helpLocation="dashboard_1"
          hasBorder
        >
          {[1, 2, 3, 4, 5, 6].map(() => (
            <Skeleton
              style={{ marginTop: '1rem' }}
              height="28rem"
              width={commonDimension.width}
              variant="rectangular"
            />
          ))}{' '}
        </MaterialCard>
      )}
    </>
  );
};

export default FleetTollOverview;
