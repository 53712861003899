/*eslint-disable */
import Tabs from '@common/ui/Tabs';
import dayjs from 'dayjs';
import { useAppSelector } from '@store/hooks';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './dashboard.module.scss';
import { routes } from '@common/utils/route-names';
import { dashboardTabs } from '@common/utils/dashboard-tabs';
import api from '@api/services/common';

const Dashboards = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tabInUrl, setTabInUrl] = useState<string | undefined>(undefined);
  const customer = useAppSelector(state => state.customer);
  const [dashboards, setDashboards] = useState({
    [dashboardTabs[0].value.toLowerCase()]: '',
    [dashboardTabs[1].value.toLowerCase()]: '',
    [dashboardTabs[2].value.toLowerCase()]: ''
  });

  const [activeTab, setActiveTab] = useState(
    tabInUrl ? tabInUrl : dashboardTabs[0].value.toLowerCase()
  );
  const [isLoading, setIsLoading] = useState(false);
  const handleActiveTab = (value: string) => {
    if (_.camelCase(value).toLowerCase() === _.camelCase(dashboardTabs[0].value).toLowerCase()) {
      navigate(routes.dashboard);
    } else {
      navigate(`${routes.dashboard}?tab=${_.kebabCase(value)}`);
    }
  };
  useEffect(() => {
    const queryParams = queryString.parse(location.search, {
      parseBooleans: true,
      parseNumbers: true
    });
    if (queryParams.tab && typeof queryParams.tab === 'string') {
      setTabInUrl(_.kebabCase(queryParams.tab));
    } else {
      setTabInUrl(_.kebabCase(dashboardTabs[0].label));
    }
  }, [location.search]);
  useEffect(() => {
    setActiveTab(tabInUrl ? tabInUrl : _.kebabCase(dashboardTabs[0].label));
  }, [location.search, tabInUrl]);
  useEffect(() => {
    const getDashboardURL = async () => {
      try {
        setIsLoading(true);
        const convertedActiveTab = dashboardTabs
          .find(x => _.kebabCase(x.label).toLowerCase() === _.kebabCase(activeTab).toLowerCase())
          ?.value.toLowerCase();
        if (convertedActiveTab) {
          const body = {
            dashboardName: convertedActiveTab,
            moduleId: convertedActiveTab.toUpperCase()
          };
          dashboards[convertedActiveTab] = '';
          const today = new Date();
          const year = today.getFullYear();
          const month = today.getMonth();
          const startOfMonth = new Date(year, month, 1);
          const previousMonthStart = new Date(year, month - 1, 1);
          const previousMonthEnd = new Date((startOfMonth as any) - 1);
          let dashboardDate = {
            start: dayjs(previousMonthStart).format('YYYY/MM/DD'),
            end: dayjs(previousMonthEnd).format('YYYY/MM/DD')
          };
          if (convertedActiveTab !== 'gpscalculatedtolls') {
            const dateResponse = await api.get('/toll-document/most-recent-dates');
            if (
              dateResponse?.isSuccess &&
              dateResponse?.data?.first_day_of_month &&
              dateResponse?.data?.last_day_of_month
            ) {
              dashboardDate.start = dateResponse.data.first_day_of_month;
              dashboardDate.end = dateResponse.data.last_day_of_month;
            }
          }
          const response = await api.post('/quick-sight/get-url', body);
          if (response?.isSuccess && response?.data?.url) {
            dashboards[convertedActiveTab] = `${response.data.url}#p.pcustomerid=${
              customer.id
            }&p.pcustomername=${_.upperFirst(customer.companyName)}&p.startDate2=${
              dashboardDate.start
            }&p.endDate=${dashboardDate.end}&p.atcstartdate=${dashboardDate.start}&p.atcenddate=${
              dashboardDate.end
            }&p.defaultStartDate=${dashboardDate.start}&p.defaultEndDate=${dashboardDate.end}`;
            setDashboards({ ...dashboards });
          }
        }
      } catch (err) {
        console.log('Error in getting dashboard url ==> ', err);
      } finally {
        setIsLoading(false);
      }
    };
    if (customer.id) getDashboardURL();
  }, [activeTab, customer.id]);

  return (
    <div className={styles.tabs}>
      <section className={styles.container}>
        {isLoading ? (
          <div className={styles.loaderht}>Loading...</div>
        ) : (
          <>
            <iframe
              name="qs-dashboards"
              title="dashboard"
              width="100%"
              height="100%"
              src={
                dashboards[
                  dashboardTabs
                    .find(x => _.kebabCase(x.label).toLowerCase() === activeTab.toLowerCase())
                    ?.value.toLowerCase() || dashboardTabs[0].value.toLowerCase()
                ]
              }
              sandbox="allow-scripts allow-downloads allow-same-origin allow-popups-to-escape-sandbox allow-popups allow-modals allow-forms"
            />
          </>
        )}
      </section>
    </div>
  );
};

export default Dashboards;
