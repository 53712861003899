/* eslint-disable */
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { cookieGeoTabPath, cookiesSessionUser, credentials } from '@common/services/auth';
import { sessionLogOut, setAuthLoaded } from '@store/features/auth';
import { rtkqController } from './base-api';
import { store } from '../../store';
import { AUTH_BE_URL } from './api-urls';
import { setHeaders } from './header';

export async function SessionLogOut(isGeotab: boolean) {
  if (!isGeotab) {
    toast.error(`Your session has expired. Please log in again`, {
      position: toast.POSITION.TOP_CENTER
    });
    const cookieSession = cookiesSessionUser.get();
    if (cookieSession.email) {
      const { email, accessToken } = cookieSession;
      rtkqController.abort();
      await axios
        .post(
          `${AUTH_BE_URL}/signout`,
          { email, accessToken },
          {
            headers: setHeaders()
          }
        )
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          store.dispatch(sessionLogOut());
          cookiesSessionUser.clearSession();
          store.dispatch(setAuthLoaded(false));
        });
    } else {
      store.dispatch(sessionLogOut());
      cookiesSessionUser.clearSession();
      store.dispatch(setAuthLoaded(false));
    }
  } else {
    const geotab_url = location.href
    const path = new URL(geotab_url).pathname;
    Cookies.set(cookieGeoTabPath, JSON.stringify(path), {
        sameSite: 'None',
        secure: true
      });
    const cookieValue = Cookies.get(credentials);
    const credential = cookieValue ? JSON.parse(Cookies.get(credentials) || '') : '';
    const url = credential
      ? `/geotab-login/${credential?.username}/${credential?.database}/${credential?.sessionId}`
      : `/login`;
    window.location.assign(`${process.env.REACT_APP_SOURCE_URL}${url}`);
  }
}
