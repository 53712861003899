/*eslint-disable*/
import { UnionDataInterface } from '@common/types/data';
import { ETableName } from '@common/utils/enums';
import { useAppSelector } from '@store/hooks';
import _ from 'lodash';
import Status from '@common/ui/Status';
import { formatFieldData } from '@common/services/format';
import Highlighter from 'react-highlight-words';
import styles from './column.module.scss';
import { formatTimeSavedDate } from '@common/services/format/field-data';

const Column = ({
  tableName,
  heading,
  field,
  value,
  rowData
}: {
  tableName: ETableName;
  heading: string;
  field?: string;
  value: unknown;
  rowData?: any;
}) => {
  const search = useAppSelector(state => state.search[tableName]);

  const isMetric: boolean = useAppSelector((state: any) => state.auth.isMetric);
  const timeZone: string = useAppSelector((state: any) => state.auth.timeZoneId);
  const isActualTollTab = useAppSelector(state => state.helpers.isActualTollTab);
  // ? modify field data
  let modValue;
  if ([ETableName.TRIPS].includes(tableName) && heading === 'Time Saved') {
    let time = Number(value);
    modValue = formatTimeSavedDate(time);
  } else {
    modValue = formatFieldData(
      value,
      heading,
      tableName,
      isMetric,
      timeZone,
      field as keyof UnionDataInterface,
      rowData,
      isActualTollTab
    );
  }
  const getColor = (modValue?: any, Heading?: any) => {
    if (tableName === ETableName.TRIPS && Heading && Heading.toLowerCase() === 'npvt') {
      const timeSaved = modValue;
      if (timeSaved < 0) {
        return styles['data-red'];
      } else if (timeSaved > 0) {
        return styles['data-green'];
      }
    }
    return '';
  };
  const getNpvt = (value: any) => {
    if ([ETableName.TRIPS].includes(tableName) && heading === 'npvt') {
      if (value) {
        const numericValue = parseFloat(value);
        const formattedValue = numericValue.toFixed(2);
        return numericValue < 0
          ? `-$${Math.abs(Number(formattedValue))}/min`
          : `$${formattedValue}/min`;
      } else {
        return value;
      }
    }
  };
  return (
    <>
      {modValue && modValue !== 'null' && modValue !== 'None' && modValue !== 'none' ? (
        <p className={`${styles.container} ${styles[_.kebabCase(`${tableName} ${heading}`)]} `}>
          {heading.toLowerCase() !== 'status' ? (
            <span
              className={`${styles.data} ${getColor(modValue, heading)}`}
              title={modValue as string}
            >
              <Highlighter
                highlightClassName={styles['search-highlight']}
                searchWords={
                  search.by && _.kebabCase(search.field) === _.kebabCase(heading)
                    ? [`${search.by}`.trim()]
                    : []
                }
                autoEscape
                textToHighlight={
                  [ETableName.TRIPS].includes(tableName) && heading === 'npvt'
                    ? getNpvt(modValue)
                    : modValue.toString() || ''
                }
              />
            </span>
          ) : (
            <Status
              status={(modValue as string)?.toLowerCase() || ''}
              uppercase={!['transactions', 'toll_documents', 'toll_document'].includes(tableName)}
            >
              <Highlighter
                highlightClassName={styles['search-highlight']}
                searchWords={search.by ? [`${search.by}`.trim()] : []}
                autoEscape
                textToHighlight={modValue.toString() || ''}
              />
            </Status>
          )}
        </p>
      ) : (
        <p
          className={`${styles.container} ${styles[_.kebabCase(`${tableName} ${heading}`)]} ${
            styles['not-found']
          }`}
        >
          {heading === 'amount' ? (modValue === 0 ? '$0' : '-') : '-'}
        </p>
      )}
    </>
  );
};

export default Column;
