/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useAppSelector } from '@store/hooks';
import Logo from '@common/ui/Logo';
import LogoWithText from '@common/ui/Logo/LogoWithText';
import { v4 as uuid } from 'uuid';
import { Close, Hamburger } from '@assets/icons';
import { EModule } from '@common/utils/enums';
import { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { navigationLinks } from './utils/navigation-links';
import styles from './geotab-navigation.module.scss';
import NavItem from './NavItem';
import TabletMenu from './TabletMenu/TabletMenu';
import MobileMenu from './MobileMenu/MobileMenu';
import DashboardNavigation from './DashboardNavigation';

const GeotabNavigation = () => {
  const location = useLocation();
  const auth = useAppSelector(state => state.auth);
  const customer = useAppSelector(state => state.customer);
  const [openTabMenu, setOpenTabMenu] = useState(false);
  const handleOpenTabMenu = (value: boolean) => setOpenTabMenu(value);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [isTestUser, setIsTestUser] = useState(false);
  const handleOpenMobileMenu = (value: boolean) => setOpenMobileMenu(value);

  useEffect(() => {
    const valuesFromEnv = process.env.REACT_APP_TEST_EMAILS as string;
    if (valuesFromEnv) {
      const userEmail = auth.email;
      const testUsers = valuesFromEnv.split(',');
      setIsTestUser(testUsers.includes(userEmail));
    }
  }, [customer, auth]);

  return (
    <div className={styles['root-container']}>
      <div className={styles.container}>
        <div className={styles['logo-container']}>
          <Logo className={styles.logo} />
          <LogoWithText className={styles['tablet-logo']} />
        </div>
        {auth?.id && (
          <menu className={styles.menu}>
            <nav className={styles.left}>
              <ul>
                {navigationLinks
                  ?.filter(item => item.position === 'left')
                  ?.map(
                    navItem =>
                      ((navItem.title === EModule.DASHBOARD && customer.id) ||
                        (isTestUser &&
                          navItem.title.toUpperCase() === 'NEW DASHBOARD' &&
                          customer.id) ||
                        (navItem.title !== EModule.DASHBOARD &&
                          navItem.title.toUpperCase() !== 'NEW DASHBOARD' &&
                          navItem.isPermitted(navItem.link))) && (
                        <li key={uuid()}>
                          <NavItem navItem={navItem} />
                        </li>
                      )
                  )}
              </ul>
            </nav>
            <nav className={styles.right}>
              <ul>
                {navigationLinks
                  ?.filter(item => item.position === 'right')
                  ?.map(
                    navItem =>
                      navItem.isPermitted(navItem.link) && (
                        <li key={uuid()}>
                          <NavItem navItem={navItem} />
                        </li>
                      )
                  )}
              </ul>
            </nav>
          </menu>
        )}
        {/* //? tablet menu  */}
        <button
          type="button"
          className={styles['tablet-menu-icon']}
          onClick={() => handleOpenTabMenu(!openTabMenu)}
        >
          {!openTabMenu ? <Hamburger /> : <Close />}
        </button>
        <AnimatePresence exitBeforeEnter>
          {openTabMenu && <TabletMenu handleOpenTabMenu={handleOpenTabMenu} />}
        </AnimatePresence>
        {/* //? mobile menu  */}
        <div className={styles['mobile-menu']}>
          {navigationLinks
            ?.filter(item => item.mobileVisible)
            ?.map(
              navItem =>
                ((navItem.title === EModule.DASHBOARD && customer.id) ||
                  (navItem.title !== EModule.DASHBOARD && navItem.isPermitted(navItem.link))) && (
                  <button
                    className={styles.link}
                    type="button"
                    key={uuid()}
                    onClick={() => handleOpenMobileMenu(false)}
                  >
                    <NavItem
                      className={styles['mobile-visible-link']}
                      isMobile
                      navItem={{
                        ...navItem,
                        title:
                          // eslint-disable-next-line no-nested-ternary
                          navItem.title === 'toll documents'
                            ? 'toll document management'
                            : navItem.title === 'toll transactions'
                            ? 'transactions'
                            : navItem.title
                      }}
                    />
                  </button>
                )
            )}
          <button
            type="button"
            className={`${styles['mobile-menu-icon']} ${
              openMobileMenu ||
              navigationLinks
                ?.filter(item => !item.mobileVisible)
                ?.map(item => item.link)
                .includes(location.pathname)
                ? styles.active
                : ''
            }`}
            onClick={() => handleOpenMobileMenu(!openMobileMenu)}
          >
            <div className={styles['mobile-menu-icon-wrapper']}>
              <span className={styles.icon}>
                <Hamburger />
              </span>
              <span className={styles.text}>More</span>
            </div>
          </button>
          <AnimatePresence exitBeforeEnter>
            {openMobileMenu && <MobileMenu handleOpenMobileMenu={handleOpenMobileMenu} />}
          </AnimatePresence>
        </div>
      </div>
      <DashboardNavigation />
    </div>
  );
};

export default GeotabNavigation;
