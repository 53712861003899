export const statementTypesList = [
  {
    type: 'NTTA',
    description: 'North Texas Tollway Authority'
  },
  {
    type: 'MTABT',
    description: 'MTA Bridges and Tunnels'
  },
  {
    type: 'NJTA',
    description: 'New Jersey Turnpike Authority'
  },
  {
    type: 'PREPASS',
    description: 'PrePass (agency aggregator)'
  },
  {
    type: 'MdTA',
    description: 'Maryland Transportation Authority'
  }
];

export const vehicleClassesList = [
  {
    displayName: '1',
    classCode: 'CLASS_1'
  },
  {
    displayName: '2a',
    classCode: 'CLASS_2A'
  },
  {
    displayName: '2b',
    classCode: 'CLASS_2B'
  },
  {
    displayName: '3',
    classCode: 'CLASS_3'
  },
  {
    displayName: '4',
    classCode: 'CLASS_4'
  },
  {
    displayName: '5',
    classCode: 'CLASS_5'
  },
  {
    displayName: '6',
    classCode: 'CLASS_6'
  },
  {
    displayName: '7',
    classCode: 'CLASS_7'
  },
  {
    displayName: '8',
    classCode: 'CLASS_8'
  }
];
export const issuingAgenciesList = [
  {
    id: 3,
    name: 'New York State Thruway Authority'
  },
  {
    id: 4,
    name: 'North Texas Tollway Authority'
  },
  {
    id: 21,
    name: 'Kansas Turnpike Authority'
  },
  {
    id: 24,
    name: 'Maine Turnpike Authority'
  },
  {
    id: 44,
    name: 'Harris County Toll Road Authority'
  }
];

export const tagTypesList = [
  {
    displayName: 'TollTag',
    tagCode: 'TOLLTAG'
  },
  {
    displayName: 'E-ZPass',
    tagCode: 'EZPASS'
  },
  {
    displayName: 'SunPass',
    tagCode: 'SUNPASS'
  },
  {
    displayName: 'K-Tag',
    tagCode: 'KTAG'
  }
];

export const devicePlansList = [
  {
    displayName: 'Regular GeoTab',
    planCode: 'REGULAR'
  },
  {
    displayName: 'GeoTab Pro Plus',
    planCode: 'PRO_PLUS'
  },
  {
    displayName: 'Volvo OEM',
    planCode: 'VOLVO_OEM'
  },
  {
    displayName: 'Non-Volvo OEM',
    planCode: 'OEM'
  }
];

export const billingStatusList = [
  {
    displayName: 'Subscribed',
    statusCode: 'ACTIVE'
  },
  {
    displayName: 'Unsubscribed',
    statusCode: 'INACTIVE'
  },
  {
    displayName: 'Unsubscribed Permanently',
    statusCode: 'BLOCKED'
  },
  {
    displayName: 'Deleted',
    statusCode: 'DELETED'
  }
];
