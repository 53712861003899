/* eslint-disable */
import { FC } from 'react';
import { Time, Distance, Dollar } from '@assets/icons';
import { formatNumber } from '@common/services/format';
import { secondsToHms } from '@common/services/format/time';
import { useAppSelector } from '@store/hooks';
import _ from 'lodash';
import styles from './details.module.scss';
import { DetailsProps } from './types';

const Details: FC<DetailsProps> = ({
  className = '',
  duration,
  distance,
  cost,
  tripType,
  tollData
}) => {
  const isMetric = useAppSelector(state => state.auth.isMetric);
  return (
    <>
      {duration || cost || distance ? (
        <section
          className={`${styles.container} ${styles[_.kebabCase(`${tripType}`)]} ${className}`}
        >
          {cost !== undefined && (
            <p className={styles.money} title="cost">
              <span className={styles.icon}>
                <Dollar />
              </span>
              <span className={styles.text}>{formatNumber(cost, 2)}</span>
            </p>
          )}
          {duration && (
            <p className={styles.time} title="duration">
              <span className={styles.icon}>
                <Time />
              </span>
              <span className={styles.text}>{secondsToHms(duration)}</span>
            </p>
          )}
          {distance && (
            <p className={styles.time} title="distance">
              <span className={styles.icon}>
                <Distance />
              </span>
              <span className={styles.text}>
                {tripType === 'actual' ? '' : ''}
                {`${distance?.toFixed(2)} ${isMetric ? 'km' : 'mi'}`}
              </span>
            </p>
          )}
          {tollData.length > 0 && tripType === 'actual' && (
            <p className={styles.time} title="distance">
              <span className={styles.icon}>
                <Dollar />
              </span>
              <span className={styles.text}>
                {tollData[0].npvt === 0 ? (
                  '0/min'
                ) : (
                  <div className={styles.npvt}>
                    <p
                      className={
                        tollData[0]?.npvt < 0
                          ? styles['npvt-negative-text']
                          : styles['npvt-positive-text']
                      }
                    >
                      {Number(tollData[0].npvt).toFixed(2)}
                    </p>
                    /min
                  </div>
                )}
              </span>
            </p>
          )}
        </section>
      ) : (
        <section
          className={`${styles.container} ${styles[_.kebabCase(`${tripType}`)]} ${className}`}
        >
          <p className={styles['no-alternative']}>No Alternate trip for this route</p>
        </section>
      )}
    </>
  );
};

export default Details;
