import { TripDataInterface } from '@common/types/data';

export const initialValues: Omit<
  TripDataInterface,
  | 'id'
  | 'plateState'
  | 'plateNumber'
  | 'vehicleId'
  | 'customerId'
  | 'distanceInMiles'
  | 'distanceD'
  | 'vehicleType'
  | 'billedAmount'
> = {
  vehicleName: '',
  licensePlate: '',
  startAddress: '',
  endAddress: '',
  startDateTime: '',
  stopDateTime: '',
  tagType: '',
  tagTypeD: '',
  axles: 0,
  distance: 0,
  timeSpent: 0,
  moneySpent: 0,
  gpsReliability: ''
};
