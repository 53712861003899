/* eslint-disable */
import { useEffect, useMemo, useState } from 'react';
import { Button, Grid, Skeleton, useMediaQuery, useTheme } from '@mui/material';
import MaterialCard from '@common/ui/Mui/Card/MaterialCard';
import { Repeat } from '@assets/icons';
import AverageTollPerHour from './average-toll-per-hour/AverageTollPerHour';
import AverageTollPerMile from './average-toll-per-mile/AverageTollPerMile';
import TollTransactionCost from './toll-transaction-cost/TollTransactionCost';
import TollTransactionCount from './toll-transaction-count/TollTransactionCount';
import useTollInsightsData from './hooks/useTollInsightsData';
import styles from './toll-insights.module.scss'
import { ITollInsightsProps } from './types';

const TollInsights = ({ selectedMonth }: ITollInsightsProps) => {
  const { tollTransactionCostData,
    tollTransactionCountData,
    averageTollPerHourData,
    averageTollPerMileData, isLoading, showError, fetchTollInsightsData } =
    useTollInsightsData(selectedMonth);

  const commonDimension = useMemo(() => {
    return {
      height: '30rem',
      width: '100%'
    };
  }, []);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));
  const [cardHeight, setCardHeight] = useState('70rem');
  const titleName = selectedMonth.length > 0 ? selectedMonth.slice(0, -4) + " Tolls" : "Toll Insights";
  const helpLocation = selectedMonth.length > 0 ? "month_tolls" : "toll_insights";
  useEffect(() => {
    let newHeight = '71.5rem'; // Default height for LG
    switch (true) {
      case isLg:
        newHeight = '71.5rem';
        break;
      case isXl:
        newHeight = '71.5rem';
        break;
      case isMd:
        newHeight = '71.5rem';
        break;
      case isSm:
        newHeight = '80rem';
        break;
      default:
        newHeight = '80rem';
        break;
    }
    setCardHeight(newHeight);
  }, [isXs, isSm, isMd, isLg, isXl]);
  return (
    <>
      {!isLoading && !showError  && (tollTransactionCostData || tollTransactionCountData || averageTollPerHourData || averageTollPerMileData) && (
        <MaterialCard
          cardHeight={cardHeight}
          cardWidth='100%'
          title={titleName}
          helpLocation={helpLocation}
          hasBorder
          maxCols = {6}
        >
          <TollTransactionCost  line_data={tollTransactionCostData} />
          <TollTransactionCount  line_data={tollTransactionCountData} />
          <AverageTollPerHour  line_data={averageTollPerHourData} />
          <AverageTollPerMile  line_data={averageTollPerMileData} />
          
         </MaterialCard>
      )}
      {!isLoading && showError && (
        <MaterialCard
          cardHeight={cardHeight}
          cardWidth='100%'
          title={titleName}
          helpLocation={helpLocation}
          hasBorder
          noGrid
        >
          <div className={styles.error}>
            Something went wrong!
            <div className={styles.retry}>
              <Button
                size="small"
                variant="contained"
                onClick={fetchTollInsightsData}
                startIcon={<Repeat className={styles.icon} />}
              >
                Retry
              </Button>
            </div>
          </div>
                   </MaterialCard>
        )}
      {isLoading && (
        <MaterialCard
          cardHeight={cardHeight}
          title={titleName}
          helpLocation={helpLocation}
          hasBorder
          maxCols={6}
        >
          {[1, 2, 3, 4].map(() => (
            <Skeleton
              style={{ marginTop: '1rem' }}
              height="28rem"
              width={commonDimension.width}
              variant="rectangular"
            />
          ))}{' '}
        </MaterialCard>
      )}
    </>
  );
};

export default TollInsights;