/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect, useRef } from 'react';
import { ScriptableLineSegmentContext } from 'chart.js';
import moment from 'moment-timezone';
import api from '@api/services/common';
import { ChartEvent } from 'chart.js/dist/core/core.plugins';
import { ActiveElement } from 'chart.js/dist/plugins/plugin.tooltip';
import { useAppSelector } from '@store/hooks';
import { IAggData, IApiResponse, ILatestMonthAgg, ILineChartData, ITollSpendData } from '../type';

const useTollSpendData = (
  isParentLoading: boolean
): {
  tollSpendData: ILineChartData | undefined;
  isLoading: boolean;
  showError: boolean;
  aggData: IAggData;
  fetchTollSpendAggData: () => void;
  handleBarChartClick: (event: ChartEvent, elements: ActiveElement[]) => void;
  selectedIndex: number | null;
} => {
  const [tollSpendData, setTollSpendData] = useState<ILineChartData>();
  const customerId = useAppSelector(state => state.customer.id);
  const tollSpendRes = useRef<ITollSpendData[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [aggData, setAggData] = useState<IAggData>({
    totalTolls: 0,
    total: 0,
    tolls: [],
    amount: [],
    months: [],
    lastMonth: ''
  });
  const [showError, setShowError] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const highlightLastLine = (
    ctx: ScriptableLineSegmentContext,
    value: string,
    lineChartCoords?: {
      from: number;
      to: number;
    }
  ) => {
    const coordsToBeCompared = {
      from: lineChartCoords?.from !== undefined ? lineChartCoords.from : 3,
      to: lineChartCoords?.to !== undefined ? lineChartCoords.to : 3
    };

    if (coordsToBeCompared.from === 0 && coordsToBeCompared.to === 0) {
      return undefined;
    }

    return ctx.p0DataIndex === coordsToBeCompared.from && ctx.p1DataIndex === coordsToBeCompared.to
      ? value
      : undefined;
  };

  const getLatestMonthAggregate = (data: ITollSpendData[]): ILatestMonthAgg => {
    const latestEntry = data.reduce((prev, current) =>
      new Date(current.monthdate) > new Date(prev.monthdate) ? current : prev
    );

    const latestMonth = moment(latestEntry.monthdate).format('MMMM YYYY');

    return {
      month: latestMonth,
      totalTolls: parseInt(latestEntry.toll_count, 10),
      totalAmount: parseFloat(latestEntry.tollamount_sum),
      tolls: data.map(item => Math.floor(parseFloat(item.toll_count))),
      amount: data.map(item => Math.floor(parseFloat(item.tollamount_sum))),
      months: data.map(item => item.monthdate),
      lastMonth: latestMonth
    };
  };

  const transformDataForLineChart = (
    data: ITollSpendData[],
    selectedCoords?: {
      from: number;
      to: number;
    }
  ) => {
    const sortedData = data
      .slice()
      .sort((a, b) => new Date(a.monthdate).getTime() - new Date(b.monthdate).getTime());
    const labels = sortedData.map(item => moment(item.monthdate).format('MMMM'));
    const chartData: ILineChartData = {
      labels,
      datasets: [
        {
          label: 'Toll Spent',
          data: sortedData.map(item => Math.floor(parseFloat(item.tollamount_sum))),
          borderColor: sortedData.map((_, index, array) => {
            return index === array.length - 1 ? '#FFBF66' : '#4285F4';
          }),
          backgroundColor: sortedData.map((_, index, array) =>
            index === array.length - 1 ? '#FFBF66' : '#6D9EEB'
          ),
          segment: {
            borderColor: (ctx: ScriptableLineSegmentContext) =>
              highlightLastLine(ctx, '#FFBF66', selectedCoords)
          }
        }
      ]
    };
    aggData.total = chartData.datasets[0].data[chartData.datasets[0].data.length - 1] || 0;
    const lastMonthAgg = getLatestMonthAggregate(data);
    aggData.totalTolls = lastMonthAgg.totalTolls;
    aggData.amount = lastMonthAgg.amount;
    aggData.tolls = lastMonthAgg.tolls;
    aggData.months = lastMonthAgg.months;
    aggData.lastMonth = lastMonthAgg.lastMonth;
    setAggData({ ...aggData });
    setTollSpendData(chartData);
  };

  const fetchTollSpendAggData = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const response: IApiResponse = await api.post('/dashboard/toll-spend', {
        moduleId: 'INSIGHTS'
      });
      if (response.isSuccess && response.data) {
        tollSpendRes.current = response.data;
        transformDataForLineChart(response.data);
      } else {
        setShowError(true);
      }
    } catch (err) {
      console.log('Err in calling toll spend ==> ', err);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBarChartClick = (event: ChartEvent, elements: ActiveElement[]) => {
    if (elements.length > 0) {
      const dataIndex = elements[0].index;
      if (dataIndex >= 0 && tollSpendRes.current) {
        const lineChartCoords = {
          from: dataIndex,
          to: dataIndex + 1
        };
        transformDataForLineChart(tollSpendRes.current, lineChartCoords);
      }
      const toll = (aggData.tolls && aggData.tolls[dataIndex]) || 0;
      const amount = (aggData.amount && aggData.amount[dataIndex]) || 0;
      const selectedMonth = (aggData.months && aggData.months[dataIndex]) || '';

      setAggData(prevAggData => ({
        ...prevAggData,
        totalTolls: toll,
        total: amount,
        lastMonth: selectedMonth
      }));

      setSelectedIndex(dataIndex);

      setTollSpendData(prevTollData => {
        if (!prevTollData) {
          return prevTollData;
        }

        return {
          ...prevTollData,
          datasets: prevTollData.datasets.map(dataset => ({
            ...dataset,
            backgroundColor: dataset.backgroundColor.map((color, i) =>
              i === dataIndex ? '#FFBF66' : '#6D9EEB'
            )
            // borderColor: dataset.borderColor.map((color, i) =>
            //   i === dataIndex ? '#FFBF66' : '#6D9EEB'
            // )
          })),
          labels: prevTollData.labels
        };
      });
    }
  };

  useEffect(() => {
    if (customerId && !isParentLoading) {
      fetchTollSpendAggData().catch(err => {
        console.log('Error in toll spend data ==> ', err);
      });
    }
  }, [customerId]);

  return {
    tollSpendData,
    isLoading,
    showError,
    aggData,
    fetchTollSpendAggData,
    handleBarChartClick,
    selectedIndex
  };
};

export default useTollSpendData;
