import AccountOverview from './account-overview/AccountOverview';
import FleetTollOverview from './fleet-toll-overview/FleetTollOverview';
import styles from './insights.module.scss';
import RecentAlerts from './recent-alerts/RecentAlerts';
import SavingsOpportunities from './savings-opportunities/SavingsOpportunities';
import TollBillSummary from '../monthly-detail/toll-bill-summary/TollBillSummary';

const Insights = () => {
  return (
    <div className={styles.container}>
      <AccountOverview />
      <RecentAlerts />
      <SavingsOpportunities />
      <TollBillSummary selectedMonth="" />
      <FleetTollOverview />
    </div>
  );
};

export default Insights;
