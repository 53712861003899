/* eslint-disable @typescript-eslint/no-misused-promises */
import _ from 'lodash';
import { Box, Button, Card, Grid, Skeleton } from '@mui/material';
import { v4 as uuid } from 'uuid';
import moment from 'moment-timezone';
import MaterialCard from '@common/ui/Mui/Card/MaterialCard';
import { Repeat } from '@assets/icons';
import { formatCurrency, formatPercentage } from '@pages/dashboard/common/helper';
import styles from './account-overview.module.scss';
import TollBillHistory from './toll-bill-history/TollBillHistory';
import { ICategoryCard } from './type';
import useAccountOverviewData from './hooks/useAccountOverviewData';

const CategoryCard = ({ data }: { data: ICategoryCard }) => {
  const hideMomPercent = true;
  const showPlainColors = true;
  const defineAmtColorStyle = (dataToBeChecked: ICategoryCard) => {
    if (showPlainColors) {
      return styles['zero-amt'];
    }
    if (Number(dataToBeChecked.amount.value) && Number(dataToBeChecked.amount.value) !== 0) {
      if (dataToBeChecked.amount.isGained) {
        return styles['profit-amt'];
      }
      return styles['loss-amt'];
    }
    return styles['zero-amt'];
  };
  const defineMomAmtColorStyle = (dataToBeChecked: ICategoryCard) => {
    if (showPlainColors) {
      return styles['zero-percent'];
    }
    if (Number(dataToBeChecked.momAmt.value) && Number(dataToBeChecked.momAmt.value) !== 0) {
      if (_.kebabCase(dataToBeChecked.categoryName) === 'realized-savings') {
        if (Number(dataToBeChecked.momAmt.isGained)) {
          return styles['profit-amt'];
        }
        return styles['zero-percent'];
      }

      if (dataToBeChecked.amount.isGained) {
        return styles['profit-amt'];
      }
      return styles['loss-amt'];
    }
    return styles['zero-percent'];
  };
  const definePercentColorStyle = (dataToBeChecked: ICategoryCard) => {
    if (showPlainColors) {
      return styles.neutral;
    }
    if (
      Number(dataToBeChecked.momPercent.value) &&
      Number(dataToBeChecked.momPercent.value) !== 0
    ) {
      if (_.kebabCase(dataToBeChecked.categoryName) === 'realized-savings') {
        if (Number(dataToBeChecked.momPercent.isGained)) {
          return styles.profit;
        }
        return styles.neutral;
      }
      if (dataToBeChecked.amount.isGained) {
        return styles.profit;
      }
      return styles.loss;
    }
    return styles.neutral;
  };
  const isMainValueToBeZero = (dataToBeChecked: ICategoryCard) => {
    if (Number(dataToBeChecked.amount.value) < 0) {
      return true;
    }
    return false;
  };
  const isValueToBeHidden = (type: number): boolean => {
    if (type === 0 || type === 2) {
      return true;
    }
    return false;
  };
  return (
    <Card
      elevation={5}
      sx={{
        width: '100%',
        height: '100%'
      }}
      className={styles['category-card']}
    >
      <Grid container alignItems="center" sx={{ height: '100%' }}>
        <Grid xs={6} xl={6} sm={6} md={6} lg={6} textAlign="center">
          <div className={styles.total}>
            <div className={styles.divider}>
              <h3>{data.categoryName}</h3>
              {isValueToBeHidden(data.type) ? (
                <span
                  className={`${
                    isMainValueToBeZero(data) ? styles['zero-amt'] : defineAmtColorStyle(data)
                  }`}
                >
                  {isMainValueToBeZero(data) ? '$0' : formatCurrency(data.amount.value, true, true)}
                </span>
              ) : (
                <span className={styles['more-history']}>More history needed</span>
              )}
            </div>
          </div>
          {/* <div className={styles.divider} /> */}
        </Grid>
        <Grid xs={6} xl={6} sm={6} md={6} lg={6} textAlign="center">
          <div
            className={`${styles['comparitive-data']} ${
              data.type === 1 || data.type === 2 ? styles['alt-comparitive-data'] : ''
            }`}
          >
            <p className={styles['highlight-amt']}>
              <span className={defineMomAmtColorStyle(data)}>
                {/* {Number(data.momAmt.value) > 0 && <>+</>} */}
                {formatCurrency(data.momAmt.value, true, true)}
              </span>{' '}
              in {data.currentMonth}
            </p>
            {!hideMomPercent && (
              <p className={styles['highlight-percent']}>
                <span className={definePercentColorStyle(data)}>
                  {/* {(Number(data.momPercent.value) > 0 || 
                Number(data.momPercent.value) === 0) && <>+</>} */}
                  {formatPercentage(data.momPercent.value, false, true)}
                </span>{' '}
                {/* eslint-disable no-nested-ternary */}
                {Number(data.momPercent.value) > 0 ? (
                  <>more than</>
                ) : Number(data.momPercent.value) < 0 ? (
                  <>less than</>
                ) : (
                  ''
                )}{' '}
                in {data.previousMonth}
              </p>
            )}
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};
const AccountOverview = () => {
  const {
    dateRange,
    accOvrviewData,
    isLoading,
    showError,
    tollBillHistoryData,
    fetchAccountOverviewData
  } = useAccountOverviewData();

  return (
    <div className={styles.container}>
      <MaterialCard
        title="Account Overview"
        cardHeight="100%"
        noGrid
        helpLocation="dashboard_1"
        hasBorder
        subTitle={
          <>
            {isLoading && (
              <>
                <Skeleton height="1rem" width="25%" variant="rectangular" />
                <Skeleton
                  style={{ marginTop: '1rem' }}
                  height="1rem"
                  width="50%"
                  variant="rectangular"
                />
              </>
            )}
            {!isLoading && (
              <>
                <p className={styles['updated-by']}>
                  Last updated {moment.utc(dateRange.toDate).format('LL')}
                </p>
                <p>
                  Showing {moment.utc(dateRange.fromDate).format('LL')} to{' '}
                  {moment.utc(dateRange.toDate).format('LL')}
                </p>
              </>
            )}
          </>
        }
      >
        <Grid container className={styles['row-wrapper']}>
          <Grid xs={12} xl={6} sm={12} md={6} lg={6} textAlign="start">
            <Box sx={{ paddingRight: '8rem', height: '100%' }}>
              {isLoading && (
                <>
                  {[1, 2, 3, 4].map(() => (
                    <Skeleton
                      style={{ marginTop: '1rem' }}
                      height="8rem"
                      width="100%"
                      variant="rectangular"
                    />
                  ))}
                </>
              )}
              {!isLoading && !showError && accOvrviewData && (
                <Grid
                  container
                  spacing={2}
                  alignItems="stretch"
                  sx={{ height: '100%', alignSelf: 'center' }}
                >
                  {accOvrviewData.map(data => (
                    <Grid item xs={12}>
                      <CategoryCard key={uuid()} data={data} />
                    </Grid>
                  ))}
                </Grid>
              )}
              {!isLoading && (showError || !accOvrviewData) && (
                <div className={styles.error}>
                  Something went wrong!
                  <div className={styles.retry}>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={fetchAccountOverviewData}
                      startIcon={<Repeat className={styles.icon} />}
                    >
                      Retry
                    </Button>
                  </div>
                </div>
              )}
            </Box>
          </Grid>
          <Grid xs={12} xl={6} sm={12} md={6} lg={6}>
            <TollBillHistory
              tollBillHistoryData={tollBillHistoryData}
              isLoading={isLoading}
              showError={showError}
              fetchAccountOverviewData={fetchAccountOverviewData}
            />
          </Grid>
        </Grid>
      </MaterialCard>
    </div>
  );
};

export default AccountOverview;
