/* eslint-disable */
import {
  UnionDataInterface,
  TripDataInterface,
  TransactionDataInterface,
  VehicleDataInterface,
  UserDataInterface,
  TollDocumentsDataInterface
} from '@common/types/data';
import { ETableName } from '@common/utils/enums';
import dayjs from 'dayjs';
import { DATE_KEYS_ARRAY, invoiceTypes, tollDocumentStatuses } from '@common/utils/constants';
import { formatNumber } from './number';
import { TimeSavedSecondsToHms, secondsToHms } from './time';
import { UIDateTimeformatHelper, formatTimeZone, formatTransactionDate } from './formatTimeZone';
import _ from 'lodash';

export const formatTimeSavedDate = (value: any) => {
  value = TimeSavedSecondsToHms(value);
  return value;
};
export const formatFieldData = (
  value: unknown,
  heading: string,
  tableName: ETableName,
  isMetric: boolean,
  timeZone: string,
  field?: keyof UnionDataInterface,
  rowData?: any,
  isActualTollTab?: boolean
) => {
  value =
    typeof value === 'number' &&
    (field as keyof TripDataInterface) === 'distanceD' &&
    !isMetric &&
    value >= 0
      ? // ? `${Math.ceil(formatDistance(value, 'kilometres'))} mi`
        `${value} mi`
      : typeof value === 'number' &&
        (field as keyof TripDataInterface) === 'distanceD' &&
        value >= 0
      ? // @ts-ignore
        `${value} km`
      : value;
  value =
    typeof value === 'number' &&
    ![
      'timeSpent',
      'axles',
      'wheels',
      'id',
      'tollBillId',
      'invoice_type',
      'invoice_version',
      'status',
      'height',
      'weight',
      'vehicleName',
      'total_amount',
      'calculatedAmount'
    ].includes((field as keyof TripDataInterface) || heading) &&
    value > 0
      ? `${formatNumber(value, 2)}`
      : value;
  value =
    typeof value === 'number' && (field as keyof TripDataInterface) === 'timeSpent'
      ? secondsToHms(value)
      : value;
  value =
    typeof value === 'string' && (field as keyof TripDataInterface) === 'startDateTime'
      ? formatTimeZone(field as keyof TripDataInterface, value, timeZone, rowData)
      : value;
  value =
    typeof value === 'string' && (field as keyof TripDataInterface) === 'stopDateTime'
      ? formatTimeZone(field as keyof TripDataInterface, value, timeZone, rowData)
      : value;
  value =
    typeof value === 'string' && (field as keyof TransactionDataInterface) === 'date'
      ? formatTransactionDate(rowData, false, isActualTollTab)
      : // formatTimeZone(field as keyof TransactionDataInterface, value, timeZone, rowData)
        value;
  value =
    typeof value === 'string' &&
    ((field as keyof VehicleDataInterface) === 'createdAt' ||
      (field as keyof VehicleDataInterface) === 'billingStatusUpdatedAt' ||
      (field as keyof TransactionDataInterface) === 'uploadDate' ||
      (field as keyof TollDocumentsDataInterface) === 'uploaded_date')
      ? UIDateTimeformatHelper(value)
      : value;
  value =
    typeof value === 'string' &&
    ((field as keyof TripDataInterface) ||
      (field as keyof VehicleDataInterface) ||
      (field as keyof TransactionDataInterface)) === 'licensePlate'
      ? value.toUpperCase()
      : value;

  if ([ETableName.TOLLDOCUMENTS].includes(tableName) && heading === 'status') {
    value = tollDocumentStatuses[value as number];
  }

  if ([ETableName.TOLLDOCUMENTS].includes(tableName) && heading === 'invoice type') {
    value = invoiceTypes[value as number];
  }

  if (value && typeof value !== 'number') {
    if ([ETableName.ROLES].includes(tableName) && heading === 'users') {
      value = `${value as string} users`;
    }
    if (field && DATE_KEYS_ARRAY.includes(field)) {
      if ((field as keyof UserDataInterface) === 'lastLogin') {
        if (value && typeof value === 'string') {
          const isDateTimeStamp = value.split('T').length > 1;
          if (isDateTimeStamp) {
            value = dayjs(`${value as string}`)
              .local()
              .format('MM/DD/YYYY, hh:mm A');
          } else {
            value = dayjs(`${value as string} UTC`)
              .local()
              .format('MM/DD/YYYY, hh:mm A');
          }
        }
      }
      if (
        !(
          (field as keyof TripDataInterface) === 'startDateTime' ||
          (field as keyof TripDataInterface) === 'stopDateTime' ||
          (field as keyof TransactionDataInterface) === 'date' ||
          (field as keyof TransactionDataInterface) === 'uploadDate' ||
          (field as keyof VehicleDataInterface) === 'createdAt' ||
          (field as keyof VehicleDataInterface) === 'billingStatusUpdatedAt' ||
          (field as keyof TollDocumentsDataInterface) === 'uploaded_date'
        )
      )
        value = dayjs(value as string)
          .utc(true)
          .format('MM/DD/YYYY, hh:mm A');
    }
    // if (typeof value === 'string' && field === ('plateState' as keyof UnionDataInterface)) {
    //   value =
    //     [2, 3].includes(value?.length) || value?.split('-')[0].toUpperCase() === 'UM'
    //       ? value
    //       : getStatesOfCountry('US')?.find(
    //           state => _.kebabCase(state.name) === _.kebabCase(value as string)
    //         )?.isoCode || value;
    // }
    value = Array.isArray(value) ? value.join(', ') : value;
    if (['toll document number'].includes(heading)) {
      value = `#${value as string | number}`;
    }
  }
  if (
    [
      'amount',
      'toll',
      'tollogic™ Tolls',
      'total amount',
      'money spent',
      'calculatedAmount',
      'GPS Calculated Tolls'
    ].includes(heading)
  ) {
    value = value ? `$${value as string | number}` : value;
  }

  return value;
};
