/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useAppSelector } from '@store/hooks';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { Grid, Skeleton, Stack } from '@mui/material';
import { formatTimeZone } from '@common/services/format/formatTimeZone';
import { secondsToHms } from '@common/services/format/time';
import { formatCurrency } from '@pages/dashboard/common/helper';
import { SignalHigh, SignalLow, SignalMedium } from '@assets/icons';
import { EGPSReliability } from '@pages/tables/layouts/web-portal/components/table/Column/DataField/DataField';
import { ETableName } from '@common/utils/enums';
import { useCrossFiltering } from '@common/hooks';
import Button from '@common/ui/Button/Button';
import styles from './trip-details.module.scss';
import { ITripDetails } from './types';

const TripDetails: FC<{ data: ITripDetails }> = ({ data }) => {
  const fetchGpsIcon = (signal: string) => {
    const casedSignal = signal.toUpperCase();
    switch (casedSignal) {
      case EGPSReliability.high:
        return <SignalHigh className={styles['signal-icon']} />;
      case EGPSReliability.medium:
        return <SignalMedium className={styles['signal-icon']} />;
      case EGPSReliability.low:
        return <SignalLow className={styles['signal-icon']} />;
      default:
        return null;
    }
  };

  const { crossFilter } = useCrossFiltering();
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  const isMetric = useAppSelector(state => state.auth.isMetric);
  const distanceInMiles = data?.distanceInMiles
    ? `${data.distanceInMiles.toFixed(1)} ${data.distanceInMiles > 1 ? 'miles' : 'mile'}`
    : '0 mile';
  const distance = data?.distance
    ? `${data.distance.toFixed(1)} ${data.distance > 1 ? 'kilometers' : 'kilometer'}`
    : '0 kilometer';

  useEffect(() => {
    if (id) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
    return () => {};
  }, [id]);

  return (
    <div className={styles.container}>
      <Grid container direction="row" columnGap={4}>
        <Grid xs={2} lg={2} xl={2} sm={2} textAlign="end">
          <div className={styles.header}>Vehicle</div>
        </Grid>
        <Grid xs={8} lg={8} xl={8} sm={8} textAlign="start" direction="column">
          {loading ? (
            <Skeleton />
          ) : (
            <>
              <Grid container direction="row" textAlign="start">
                <Stack direction="row" gap={2}>
                  <span className={styles['soft-title']}>Name</span>{' '}
                  <span>
                    {`${
                      data?.vehicleType && typeof data.vehicleType === 'string'
                        ? _.startCase(data?.vehicleType?.toLowerCase())
                        : '-'
                    } ${data?.vehicleName}` || '-'}
                  </span>
                </Stack>
              </Grid>
              <Grid container direction="row">
                <Stack direction="row" gap={2.7}>
                  <span className={styles['soft-title']}>Plate</span>{' '}
                  <span>
                    {data?.plateState && data?.plateNumber
                      ? `${data.plateState}-${data.plateNumber}`
                      : data?.licensePlate || '-'}
                  </span>
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <Grid container direction="row" sx={{ marginTop: '2rem' }} columnGap={4}>
        <Grid xs={2} lg={2} xl={2} sm={2} textAlign="end">
          <div className={styles.header}>Start</div>
        </Grid>
        <Grid
          xs={8}
          lg={8}
          xl={8}
          sm={8}
          textAlign="start"
          direction="column"
          sx={{ lineHeight: 1 }}
        >
          <Grid container direction="row">
            <Grid xs={12} lg={12} xl={12} sm={12} textAlign="start">
              {loading ? <Skeleton /> : data?.startAddress || '-'}
            </Grid>
          </Grid>
          <Grid container direction="row" sx={{ marginTop: '0.5rem' }}>
            <Grid
              xs={12}
              lg={12}
              xl={12}
              sm={12}
              textAlign="start"
              className={styles['time-duration']}
            >
              {loading ? (
                <Skeleton />
              ) : (
                <>
                  {data?.startDateTime && data?.startTimeZone
                    ? formatTimeZone('startDateTime', data.startDateTime, data.startTimeZone, data)
                    : '-'}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction="row" sx={{ marginTop: '2rem' }} columnGap={4}>
        <Grid xs={2} lg={2} xl={2} sm={2} textAlign="end">
          <div className={styles.header}>End</div>
        </Grid>
        <Grid
          xs={8}
          lg={8}
          xl={8}
          sm={8}
          textAlign="start"
          direction="column"
          sx={{ lineHeight: 1 }}
        >
          <Grid container direction="row">
            <Grid xs={12} lg={12} xl={12} sm={12} textAlign="start">
              {loading ? <Skeleton /> : data?.endAddress || ''}
            </Grid>
          </Grid>
          <Grid container direction="row" sx={{ marginTop: '0.5rem' }}>
            <Grid
              xs={12}
              lg={12}
              xl={12}
              sm={12}
              textAlign="start"
              className={styles['time-duration']}
            >
              {loading ? (
                <Skeleton />
              ) : (
                <>
                  {data?.stopDateTime && data?.endTimeZone
                    ? formatTimeZone('endDateTime', data.stopDateTime, data.endTimeZone, data)
                    : '-'}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction="row" sx={{ marginTop: '2rem' }} columnGap={4}>
        <Grid xs={2} lg={2} xl={2} sm={2} textAlign="end">
          <div className={styles.header}>Trip</div>
        </Grid>
        <Grid
          xs={8}
          lg={8}
          xl={8}
          sm={8}
          textAlign="start"
          direction="column"
          sx={{ lineHeight: 1 }}
        >
          <Grid container direction="row">
            <Grid xs={12} lg={12} xl={12} sm={12} textAlign="start">
              {loading ? <Skeleton /> : <>{isMetric ? distance : distanceInMiles}</>}
            </Grid>
          </Grid>
          <Grid container direction="row" sx={{ marginTop: '0.5rem' }}>
            <Grid
              xs={12}
              lg={12}
              xl={12}
              sm={12}
              textAlign="start"
              className={styles['time-duration']}
            >
              {loading ? (
                <Skeleton />
              ) : (
                <>{data?.timeSpent ? secondsToHms(data.timeSpent, true) : '-'}</>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction="row" sx={{ marginTop: '2rem' }} columnGap={4}>
        <Grid xs={2} lg={2} xl={2} sm={2} textAlign="end">
          <div className={styles.header}>Tolls</div>
        </Grid>
        <Grid xs={8} lg={8} xl={8} sm={8} textAlign="start" direction="column">
          {loading ? (
            <>
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            <>
              <Grid container direction="row" textAlign="start">
                <Stack direction="row" gap={1.5}>
                  <span className={styles['soft-title']}>tollogic&#8482;</span>{' '}
                  <span>
                  {data?.moneySpent ? formatCurrency(data.moneySpent.toString()) : '$0'}
                  </span>
                </Stack>
              </Grid>
              <Grid container direction="row">
                <Stack direction="row" gap={1.5}>
                  <span className={styles['soft-title']}>Invoiced</span>{' '}
                  <span>
                    {data?.billedAmount ? formatCurrency(data.billedAmount.toString()) : '$0'}
                  </span>
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <Grid container direction="row" sx={{ marginTop: '2rem' }} columnGap={4}>
        <Grid xs={2} lg={2} xl={2} sm={2} textAlign="end">
          <div className={styles.header}>Tag Type</div>
        </Grid>
        <Grid xs={8} lg={8} xl={8} sm={8} textAlign="start" direction="column">
          {loading ? (
            <Skeleton />
          ) : (
            <>
              {data?.tagType ? (
                <>{data.tagType}</>
              ) : (
                <div className={styles['action-btn']}>
                  <Button
                    appearance="text"
                    label="Update Tag"
                    onClick={() => crossFilter(data?.vehicleName, ETableName.FLEET, true)}
                    variant="tertiary"
                    size="s"
                  />
                </div>
              )}
            </>
          )}
        </Grid>
      </Grid>

      <Grid container direction="row" sx={{ marginTop: '2rem' }} columnGap={4}>
        <Grid xs={2} lg={2} xl={2} sm={2} textAlign="end">
          <div className={styles.header}>Axle Count</div>
        </Grid>
        <Grid xs={8} lg={8} xl={8} sm={8} textAlign="start" direction="column">
          {loading ? <Skeleton /> : <>{data?.axles || '-'}</>}
        </Grid>
      </Grid>

      <Grid container direction="row" sx={{ marginTop: '2rem' }} columnGap={4}>
        <Grid xs={2} lg={2} xl={2} sm={2} textAlign="end">
          <div className={styles.header}>Trip ID</div>
        </Grid>
        <Grid xs={8} lg={8} xl={8} sm={8} textAlign="start" direction="column">
          {loading ? <Skeleton /> : <>{data?.id || '-'}</>}
        </Grid>
      </Grid>

      <Grid container direction="row" sx={{ marginTop: '2rem' }} columnGap={4}>
        <Grid xs={2} lg={2} xl={2} sm={2} textAlign="end" sx={{ marginTop: '1.3rem' }}>
          <div className={styles.header}>GPS</div>
        </Grid>
        <Grid xs={8} lg={8} xl={8} sm={8} textAlign="start" direction="column">
          {loading ? (
            <Skeleton />
          ) : (
            <>{data?.gpsReliability ? fetchGpsIcon(data.gpsReliability) : '-'}</>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default TripDetails;
