import Box from '@mui/material/Box';
import { ChevronRight, Dot } from '@assets/icons';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import Skeleton from '@mui/material/Skeleton';
import HtmlParse from 'html-react-parser';
import { formatCurrency } from '@pages/dashboard/common/helper';
import styles from './category.module.scss';
import { useGetCategoryData } from '../hooks';
import { categoryAmountsToHide, savingsCategory } from './types';

const Category = ({
  content,
  url,
  selectedMonth
}: {
  content: string;
  url: string;
  selectedMonth: string;
}) => {
  const { data, loader } = useGetCategoryData(url, selectedMonth);

  const updatedData = data.map(item => {
    const matchingCategory = savingsCategory.find(category => category.label === item.subcategory);
    if (matchingCategory) {
      const { isClickable, screen } = matchingCategory;
      return {
        ...item,
        isClickable,
        screen
      };
    }
    return item;
  });

  const getScoreStyle = (score: number) => {
    switch (score) {
      case 1:
      case 2:
        return {
          color: '#D31912'
        };
      case 3:
      case 4:
        return {
          color: '#D37E0A'
        };
      case 5:
        return {
          color: '#30A652'
        };
      default:
        return {
          color: '#757575'
        };
    }
  };

  const getBulletPointStyle = (score: number) => {
    switch (score) {
      case 1:
      case 2:
        return {
          color: '#FF746C'
        };
      case 3:
      case 4:
        return {
          color: '#FFBF66'
        };
      case 5:
        return {
          color: '#34C759'
        };
      default:
        return {
          color: '#757575'
        };
    }
  };

  return (
    <div className={styles.container}>
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ maxWidth: `80rem`, paddingRight: '5rem' }}>
            {loader ? (
              <Skeleton height="7rem" variant="rectangular" />
            ) : (
              <p className={styles.description}>{HtmlParse((content || '').trim())}</p>
            )}
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ minWidth: '25rem' }}>
              {loader ? (
                <Skeleton height="15rem" width="80%" variant="rectangular" />
              ) : (
                <>
                  {updatedData &&
                    updatedData.length > 0 &&
                    updatedData.map(item => (
                      <List
                        sx={{
                          width: '100%',
                          // height: '2rem',
                          maxWidth: 360,
                          padding: '0 !important',
                          bgcolor: 'background.paper'
                        }}
                        aria-label="sub-categories"
                        dense
                      >
                        <ListItem
                          sx={{ height: '3.2rem' }}
                          disablePadding
                          dense
                          secondaryAction={
                            <IconButton aria-label="navigate">
                              <ChevronRight />
                            </IconButton>
                          }
                        >
                          <ListItemButton dense sx={{ height: '3.2rem' }}>
                            <ListItemText
                              sx={{
                                textAlign: 'right',
                                maxHeight: '3.3rem',
                                width: '100%',
                                textOverflow: 'ellipsis'
                              }}
                            >
                              <Typography component="h3" noWrap fontWeight="700">
                                {item.subcategory_name}
                              </Typography>
                            </ListItemText>
                            <ListItemIcon
                              sx={{
                                ...getScoreStyle(item.score),
                                minWidth: '3rem !important',
                                fontWeight: '600'
                              }}
                              className={styles.numbers}
                            >
                              {item.instance_count}
                            </ListItemIcon>
                          </ListItemButton>
                        </ListItem>
                      </List>
                    ))}
                </>
              )}
            </Box>
            <Box sx={{ minWidth: '24rem' }}>
              {loader ? (
                <Skeleton height="15rem" width="60%" variant="rectangular" />
              ) : (
                <>
                  {data &&
                    data.length > 0 &&
                    data.map(item => (
                      <p key={uuid()} className={styles.amount}>
                        {categoryAmountsToHide.includes(item.subcategory_name)
                          ? ''
                          : formatCurrency(item.amount)}
                      </p>
                    ))}
                </>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                minWidth: '7rem'
              }}
            >
              {loader ? (
                <Skeleton height="15rem" width="90%" variant="rectangular" />
              ) : (
                <>
                  {data &&
                    data.length > 0 &&
                    data.map(item => (
                      <Dot
                        height="3.25rem"
                        key={uuid()}
                        color={getBulletPointStyle(item.score).color}
                      />
                    ))}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Category;
