import { useEffect, useState } from 'react';
import api from '@api/services/common';
import { useAppSelector } from '@store/hooks';
import { ICategoryData, ICategoryApiResponse } from '../category/types';

export const useGetCategoryData = (url: string) => {
  const customerId = useAppSelector(state => state.customer.id);
  const [data, setData] = useState<ICategoryData[]>([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (url) {
      api
        .post(`/dashboard/${url}`, { moduleId: 'INSIGHTS' })
        .then((res: ICategoryApiResponse) => {
          setLoader(true);
          if (res.isSuccess) {
            setData(res.data);
          }
        })
        .catch(err => {
          console.error('err ', err);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [url, customerId]);

  return {
    data,
    loader
  };
};
