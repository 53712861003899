/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC } from 'react';
import Legend from './Legend/Legend';
import styles from './map-header.module.scss';
import TripType from './TripType/TripType';
import { MapHeaderProps } from './types';

const MapHeader: FC<MapHeaderProps> = ({
  headerDetails,
  handleTripType,
  tripType,
  tollData,
  altTrips
}) => {
  return (
    <section className={styles.container}>
      <TripType
        headerDetails={headerDetails}
        tripType={tripType}
        handleTripType={handleTripType}
        altTrips={altTrips}
        tollData={tollData}
      />
      <Legend />
    </section>
  );
};

export default MapHeader;
