import { dashboardTabs } from '@common/utils/dashboard-tabs';
import { EModule } from '@common/utils/enums';
import { routes } from '@common/utils/route-names';
import { useAppSelector } from '@store/hooks';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { NavLink, useLocation } from 'react-router-dom';
import { userCanAccessRoute } from '@common/services/auth';
import styles from './geotab-navigation.module.scss';

const DashboardNavigation = () => {
  const location = useLocation();
  const [queryParamsValue, setQueryParamsValue] = useState('');
  const isNavCollapse = useAppSelector(state => state.helpers.isNavCollapse);

  const getNavPerms = (tabTitle: string): boolean => {
    const navPerms = userCanAccessRoute(`/${tabTitle}`);
    return navPerms || false;
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryValue = queryParams.get('tab');

    if (queryValue !== null) {
      setQueryParamsValue(queryValue);
    }
  }, [location.search]);

  const activeClassName = (isActive: boolean) => {
    return `${isActive ? styles.active : ''}`;
  };

  if (location.pathname === routes.dashboard) {
    return (
      <div
        className={`${styles['dashboard-tab']} ${
          isNavCollapse ? styles['nav-non-collapsed'] : styles['nav-collapsed']
        }`}
      >
        {getNavPerms(EModule.INSIGHTS) && (
          <NavLink
            to={`${routes.dashboard}?tab=${_.kebabCase(dashboardTabs[0].label)}`}
            className={activeClassName(
              queryParamsValue === _.kebabCase(dashboardTabs[0].label) || queryParamsValue === ''
            )}
          >
            {dashboardTabs[0].label}
          </NavLink>
        )}
        {getNavPerms(EModule.GPSCALCULATEDTOLLS) && (
          <NavLink
            to={`${routes.dashboard}?tab=${_.kebabCase(dashboardTabs[1].label)}`}
            className={activeClassName(queryParamsValue === _.kebabCase(dashboardTabs[1].label))}
          >
            {dashboardTabs[1].label}
          </NavLink>
        )}
        {getNavPerms(EModule.ACTUALTOLLCOST) && (
          <NavLink
            to={`${routes.dashboard}?tab=${_.kebabCase(dashboardTabs[2].label)}`}
            className={activeClassName(queryParamsValue === _.kebabCase(dashboardTabs[2].label))}
          >
            {dashboardTabs[2].label}
          </NavLink>
        )}
      </div>
    );
  }

  return <> </>;
};

export default DashboardNavigation;
