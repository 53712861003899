import { useAppDispatch, useAppSelector } from '@store/hooks';
import { useEffect, useState } from 'react';
import api from '@api/services/common';
import { getFleetQuickFilters } from '@store/data';
import moment from 'moment-timezone';
import { IRecentAlertAPIResponse, IRecentAlerts } from './type';

export const useGetRecentAlerts = (groupSize: number) => {
  const customerId = useAppSelector(state => state.customer.id);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<IRecentAlerts[][]>([]);
  const [loader, setLoader] = useState(true);

  const groupArray = (array: IRecentAlerts[], size: number) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  useEffect(() => {
    setLoader(true);
    if (customerId) {
      api
        .post(`/dashboard/recent-alerts`, { moduleId: 'FLEET' })
        .then((res: IRecentAlertAPIResponse) => {
          if (res.isSuccess) {
            const newData = [
              ...res.data,
              {
                vehicle_id: '',
                vehicle_name: '',
                trip_id: '',
                id: '',
                ts: '',
                alert_subtype: 0,
                alert_type: '',
                description: 'View All',
                action: ''
              }
            ];

            const formatDescriptionDate = (description: string) => {
              const regex = /(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})/g;
              const formattedDescription = description.replace(regex, match => {
                return moment(match).format('MM/DD/yyyy HH:mm');
              });

              return formattedDescription;
            };

            const formattedData = newData.map(item => ({
              ...item,
              description: formatDescriptionDate(item.description)
            }));

            setData(groupArray(formattedData, groupSize));
            dispatch(getFleetQuickFilters()).catch(err => {
              console.log('Err in fetching fleet quick filters in RA ==> ', err);
            });
          }
        })
        .catch(err => {
          console.error('err ', err);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [dispatch, groupSize, customerId]);

  return {
    data,
    loader
  };
};
