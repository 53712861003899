/* eslint-disable */
import { AUTH_BE_URL } from '@api/config';
import axios, { AxiosError } from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSSOContext } from '../context/SSOContext';
import { IGeotabVehicle, IGeotabUser } from '../types';
import { useRef } from 'react';
import { tabs } from '../utils/tabs';

export const useRegisterGeotabUser = () => {
  const { userName, database, sessionId } = useParams();
  const [searchParams] = useSearchParams();
  const customerId = useRef(null);
  const {
    isCustomerNew,
    stateList,
    gAgencyList,
    gTagTypeList,
    stripeURL,
    handleActiveTab,
    handleRegistrationSuccess,
    handleRegisterLoader,
    handleAccountId,
    handleCustomerId
  } = useSSOContext();

  const handleRegistration = async (values: IGeotabUser & { vehicles: IGeotabVehicle[] }) => {
    try {
      const currentState = stateList.find(
        state =>
          values.companyRegAddress?.state &&
          state.stateCode === values.companyRegAddress?.state.split('-')[0].trim() &&
          values.companyRegAddress?.state.split('-')[0].trim()
      );

      const body = {
        database,
        sessionId,
        username: userName,
        email: values.email,
        customerProfile: {
          federalID: values?.federalID,
          dbaName: values?.dbaName,
          companyRegAddress: values?.companyRegAddress,
          companyBillingAddress: values?.companyBillingAddress,
          companyShippingAddress: values?.companyShippingAddress,
          contactName: values?.contactName,
          contactPhone: values?.contactPhone,
          contactEmail: values?.contactEmail,
          billingName: values?.billingName,
          billingPhone: values?.billingPhone,
          billingEmail: values?.billingEmail
        },
        companyDetails: {
          companyName: values?.companyName,
          userEmail: values?.contactEmail,
          companyAddress: values.companyRegAddress?.street1,
          companySecondaryAddress: values.companyRegAddress?.street2,
          dbaName: values?.dbaName,
          state: {
            id: currentState?.id,
            stateName: currentState?.stateName,
            stateCode: currentState?.stateCode
          },
          city: values.companyRegAddress?.city,
          zipCode: values.companyRegAddress?.zipCode
        },
        subscriptionPlan: values.subscriptionPlan,
        vehicleDetails: values.vehicles.map(vehicle => {
          vehicle['LICENSE PLATE STATE'] =
            vehicle['LICENSE PLATE STATE'] !== 'None'
              ? vehicle['LICENSE PLATE STATE'].split('-')[0].trim()
              : '';
          if (gAgencyList && vehicle['TAG ISSUING AGENCY']) {
            const foundAgency = gAgencyList.find(x => x.name === vehicle['TAG ISSUING AGENCY']);
            vehicle['TAG ISSUING AGENCY'] = foundAgency ? foundAgency.id : '';
          } else {
            vehicle['TAG ISSUING AGENCY'] = '';
          }
          if (gTagTypeList && vehicle['TAG TYPE']) {
            const foundTagType = gTagTypeList.find(x => x.tag_type_name === vehicle['TAG TYPE']);
            vehicle['TAG TYPE'] = foundTagType ? foundTagType.tag_type_id?.toString() : '';
          } else {
            vehicle['TAG TYPE'] = '';
          }
          vehicle['tollConsolidator'] = vehicle['tollConsolidator'] || false;
          return vehicle;
        }),
        // vehicleDetails: values.vehicles,
        groupFilter: searchParams.get('groupFilter')
      };
      handleRegisterLoader(true);
      if (isCustomerNew) {
        handleActiveTab(tabs.length - 1);
      }
      await axios
        .post(`${AUTH_BE_URL}/registerGeoTabUser`, body)
        .then(res => {
          handleAccountId(res.data.data.accountId || '');
          handleCustomerId(res.data.data.customerId || '');
          if (res?.data?.status === 'FAILED') {
            handleRegistrationSuccess(
              false,
              res?.data?.error?.errorDescription || 'Please contact your administrator'
            );
          } else {
            handleRegistrationSuccess(true, '');
            if (isCustomerNew && res.data.data.customerId && res.data.data.accountId) {
              customerId.current = res.data.data.customerId;
              const fetchedStripeURL = `${stripeURL}?prefilled_email=${body.customerProfile.contactEmail}&client_reference_id=${res.data.data.customerId}`;
              const timeoutInterval =
                (Number(process.env.REACT_APP_PAYMENT_REDIRECTION_TIME_IN_SECS) || 5) * 1000;
              setTimeout(() => {
                if (window.top) {
                  window.top.location.href = fetchedStripeURL;
                }
              }, timeoutInterval);
            }
          }
        })
        .catch((err: AxiosError) => {
          console.log('Err in registration ==> ', err);
          handleRegistrationSuccess(false, 'Please contact your administrator');
          return err;
        })
        .finally(() => {
          if (!isCustomerNew) {
            handleActiveTab(1);
          }
        });
    } catch (error) {
      handleRegistrationSuccess(false, '');
    } finally {
      handleRegisterLoader(false);
    }
  };
  return { handleRegistration };
};
