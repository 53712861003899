/* eslint-disable */
import { memo, useEffect, useMemo, useState } from 'react';
import { ChartOptions } from 'chart.js';
import MaterialCard from '@common/ui/Mui/Card/MaterialCard';
import BarChart from '@common/ui/Chart/BarChart/BarChart';
import { Button, Grid, Skeleton, useMediaQuery } from '@mui/material';
import { Repeat } from '@assets/icons';
import { useTheme } from '@mui/material/styles';
import styles from './trip-insights.module.scss';
import useTripInsightsData from './hooks/useTripInsightsData';
import BarChartSkeleton from '../common/common';
import {
  formatMonth,
  formatNumberToCurrency,
  formatNumberToCurrencyNoDecimal,
  formatNumberToUSStandard
} from '@pages/dashboard/common/helper';
import AverageTollPerMile from '../trip-value/average-toll-per-mile/AverageTollPerMile';
import AverageTollPerHour from '../trip-value/average-toll-per-hour/AverageTollPerHour';
import TollTripCount from '../trip-value/toll-trip-count/TollTripCount';
import TollTripCost from '../trip-value/toll-trip-cost/TollTripCost';
import { Plugin } from 'chart.js';
import warningImage from '@assets/images/exclamation-triangle.svg'; // Import the image
import { ITripInsightsProps } from './types';

const TripInsights = ({ selectedMonth }: ITripInsightsProps) => {
  const {
    tripInsightsData,
    tollTripCostData,
    tollTripCountData,
    averageTollPerHourData,
    averageTollPerMileData,
    isLoading,
    showError,
    fetchTripInsightsData
  } = useTripInsightsData(selectedMonth);

  const commonDimension = useMemo(() => {
    return {
      height: '30rem',
      width: '100%'
    };
  }, []);
  const [cardHeight, setCardHeight] = useState('70rem');
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md')); // 1152 px
  const isMd2 = window.innerWidth > 950 && window.innerWidth < 970; /** range between 950 to 970 */
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));
  const titleName = selectedMonth.length > 0 ? selectedMonth.slice(0, -4) + " Trips" : "Trip Value";
  const helpLocation = selectedMonth.length > 0 ? "month_trips" : "trip_value";

  const customSymbol = useMemo<Plugin<'bar'>>(() => {
    const imageCache = new Map();

    return {
      id: 'customSymbol',
      afterDraw: chart => {
        const ctx = chart.ctx;
        // Enable high-quality image scaling
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';

        if (chart.data.labels) {
          chart.data.labels.forEach((label, index) => {
            let allZero = true;
            chart.data.datasets.forEach(dataset => {
              let data: any = dataset.data[index];
              if (Number(data.valued_trip_count) || Number(data.novalued_trip_count)) {
                allZero = false;
              }
            });

            if (allZero) {
              const meta = chart.getDatasetMeta(0); // Get meta for the first dataset to access bar coordinates
              const bar = meta.data[index];

              // Cache the image
              if (!imageCache.has(index)) {
                const image = new Image();
                image.src = warningImage; // Use the imported image
                image.onload = () => {
                  imageCache.set(index, image);
                  const x = bar.x + 5; // Adjust to center the image
                  const y = chart.scales.y.getPixelForValue(0) - 40; // Adjust to position the image above the x-axis
                  ctx.drawImage(image, x, y, 40, 40); // Adjust the size of the image
                };
              } else {
                const cachedImage = imageCache.get(index);
                const x = bar.x + 5; // Adjust to center the image
                const y = chart.scales.y.getPixelForValue(0) - 40; // Adjust to position the image above the x-axis
                ctx.drawImage(cachedImage, x, y, 40, 40); // Adjust the size of the image
              }
            }
          });
        }
      }
    };
  }, [warningImage]);

  const [chartOptions, setChartOptions] = useState<ChartOptions<'bar'>>({
    parsing: {
      xAxisKey: 'xaxis' // Add xAxisKey if you want to parse the x values as well
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      autoPadding: true,
      padding: {
        right: 200, // lg : 130 xl : 40 md : 200 sm : 0 xs : 0
        bottom: 80 // lg : 80 xl : 80 md : 80 sm : 0 xs : 0
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        reverse: false,
        align: 'center',
        labels: {
          usePointStyle: true,
          pointStyle: 'rect'
        }
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'top',
        offset: 0,
        textAlign: 'right',
        formatter: (value, context) => {
          let datasets: any = [context.chart.data.datasets[0]]; //take first array item only and convert to array.
          const valuedtotal = datasets.reduce(
            (sum: number, dataset: any) =>
              sum + Number(dataset.data[context.dataIndex]['valued_trip_count']),

            0
          );

          datasets = [context.chart.data.datasets[1]]; //take 2nd array item only and convert to array.
          const novaluedtotal = datasets.reduce(
            (sum: number, dataset: any) =>
              sum + Number(dataset.data[context.dataIndex]['novalued_trip_count']),
            0
          );

          return context.datasetIndex === 0
            ? valuedtotal
              ? valuedtotal
              : ''
            : novaluedtotal
            ? novaluedtotal
            : '';
        }
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (context) {
            return formatMonth(context[0].label);
          },
          label: function (context) {
            //console.log(context);
            const keyprefix = context.datasetIndex === 0 ? 'valued_' : 'novalued_';
            const rawdata: any = context.raw !== undefined ? context.raw : {};
            const datasetLabel = context.dataset.label || '';

            const avgtoll_per_hour = rawdata[keyprefix + 'avgtoll_per_hour'];

            const avgtoll_per_mile = rawdata[keyprefix + 'avgtoll_per_mile'];
            const tripcount = rawdata[keyprefix + 'trip_count'];
            const trip_total = rawdata[keyprefix + 'trip_total'];

            const Count = `${Math.floor(Number(tripcount)).toLocaleString()}`;
            const trip = `${formatNumberToCurrencyNoDecimal(Number(trip_total), '$')} (${Count})`;
            const per_hour = `${formatNumberToCurrency(Number(avgtoll_per_hour), '$')}/hour`;
            const per_mile = `${formatNumberToCurrency(Number(avgtoll_per_mile), '$')}/mile`;

            return [datasetLabel, trip, per_hour, per_mile];
          }
        }
      }
    },
    scales: {
      y: {
        stacked: false,
        title: {
          display: true,
          text: 'Trips (Count)',
          font: {
            weight: 'bold'
          }
        },
        min: 0,
        ticks: {
          display: true,
          font: {
            weight: 'bold'
          },
          callback: (value: string | number) => {
            if (typeof value === 'number') {
              if (value >= 100000) {
                return `${(value / 1000).toLocaleString()}K`;
              }
              return `${value.toLocaleString()}`;
            }
            return value;
          }
        }
      },
      x: {
        stacked: false,
        grid: {
          display: false
        },
        ticks: {
          font: {
            weight: 'bold'
          }
        }
      }
    }
  });
  useEffect(() => {
    let newHeight = '71.5rem'; // Default height for LG
    switch (true) {
      case isLg:
        newHeight = '71.5rem';
        break;
      case isXl:
        newHeight = '71.5rem';
        break;
      case isMd:
        newHeight = '71.5rem';
        break;
      case isSm:
        newHeight = '80rem';
        break;
      default:
        newHeight = '80rem';
        break;
    }
    setCardHeight(newHeight);
  }, [isXs, isSm, isMd, isLg, isXl]);
  useEffect(() => {
    let newPaddingRight = 20;
    let newPaddingBottom = 20;

    switch (true) {
      case isXs:
      case isSm:
        newPaddingRight = 0;
        newPaddingBottom = 0;
        break;
      case !isMd2 && isMd:
        newPaddingRight = 20;
        newPaddingBottom = 20;
        break;
      case isMd2:
        newPaddingRight = 20;
        newPaddingBottom = 20;
        break;
      case isLg:
        newPaddingRight = 20;
        break;
      case isXl:
        newPaddingRight = 20;
        break;
      default:
        newPaddingRight = 20;
        break;
    }

    setChartOptions(prevOptions => ({
      ...prevOptions,
      layout: {
        ...prevOptions.layout,
        padding: {
          right: newPaddingRight,
          bottom: newPaddingBottom
        }
      }
    }));
  }, [isXs, isSm, isMd, isMd2, isLg, isXl]);
  return (
    <>
      {selectedMonth.length === 0 && (
        <MaterialCard
          title="Trip Insights"
          minHeight="60vw"
          cardHeight="100%"
          noGrid
          helpLocation={'trip_insights'}
          hasBorder
        >
          {isLoading && (
            <>
              <BarChartSkeleton />
            </>
          )}
          {!isLoading && !showError && tripInsightsData && (
            <Grid container>
              <Grid container xs={12} sm={12} md={12} lg={12} className={styles.barChart}>
                <BarChart
                  data={tripInsightsData}
                  width="100%"
                  options={chartOptions}
                  plugins={[customSymbol]}
                />
              </Grid>
            </Grid>
          )}
          {!isLoading && (showError || !tripInsightsData) && (
            <div className={styles.error}>
              Something went wrong!
              <div className={styles.retry}>
                <Button
                  size="small"
                  variant="contained"
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={fetchTripInsightsData}
                  startIcon={<Repeat className={styles.icon} />}
                >
                  Retry
                </Button>
              </div>
            </div>
          )}
        </MaterialCard>
      )}
      {isLoading && (
        <MaterialCard
          cardHeight={cardHeight}
          title={titleName}
          helpLocation={helpLocation}
          hasBorder
          maxCols={6}
        >
          {[1, 2, 3, 4].map(() => (
            <Skeleton
              style={{ marginTop: '1rem' }}
              height="28rem"
              width={commonDimension.width}
              variant="rectangular"
            />
          ))}{' '}
        </MaterialCard>
      )}
      {!isLoading && !showError && tollTripCostData && (
        <MaterialCard
          cardHeight={cardHeight}
          cardWidth="100%"
          title={titleName}
          helpLocation={helpLocation}
          hasBorder
          maxCols={6}
        >
          <TollTripCost chart_data={tollTripCostData} />
          <TollTripCount chart_data={tollTripCountData} />
          <AverageTollPerHour chart_data={averageTollPerHourData} />
          <AverageTollPerMile chart_data={averageTollPerMileData} />
        </MaterialCard>
      )}
      {!isLoading && (showError || !tollTripCostData) && (
        <div className={styles.error}>
          Something went wrong!
          <div className={styles.retry}>
            <Button
              size="small"
              variant="contained"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={fetchTripInsightsData}
              startIcon={<Repeat className={styles.icon} />}
            >
              Retry
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(TripInsights);
