import { FC } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import { CustomDoughnutChartProps } from './type';

const CustomDoughnutChart: FC<CustomDoughnutChartProps> = ({
  data,
  chartOptions = {},
  width = 200,
  height = 200
}) => {
  const chartData: ChartData<'doughnut'> = {
    labels: data.labels,
    datasets: [
      {
        label: data.label,
        data: data.values,
        backgroundColor: data.colors,
        borderColor: data.borderColors,
        borderWidth: data.borderWidth
      }
    ]
  };

  return (
    <div style={{ width, height }}>
      <Doughnut data={chartData} options={chartOptions} />
    </div>
  );
};

export default CustomDoughnutChart;
