import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import TollBillSummary from './toll-bill-summary/TollBillSummary';
import MonthlyDetailHeader from './header/monthlyDetailHeader';
import TollInsights from '../history/toll-insights/TollInsights';
import styles from './index.module.scss';
import TripInsights from '../history/trip-insights/TripInsights';
import SavingsOpportunities from './savings-opportunities/SavingsOpportunities';
import { useDateRangeData } from './header/hooks/useDateRangeData';

const MonthlyDetail = () => {
  const { data } = useDateRangeData();

  const [selectedMonth, setSelectedMonth] = useState(moment.utc(new Date()).format('MMMM YYYY'));

  useEffect(() => {
    const generateMonthOptions = () => {
      return data.map(date => moment.utc(date).format('MMMM YYYY'));
    };
    if (data.length > 0) {
      const months = generateMonthOptions();
      if (!months.includes(selectedMonth)) {
        setSelectedMonth(months[0]);
      }
    }
  }, [data, selectedMonth]);

  return (
    <div className={styles.container}>
      <MonthlyDetailHeader
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        dateRanges={data}
      />
      <SavingsOpportunities selectedMonth={selectedMonth} />
      <TollBillSummary selectedMonth={selectedMonth} />
      <TollInsights selectedMonth={selectedMonth} />
      <TripInsights selectedMonth={selectedMonth} />
    </div>
  );
};

export default MonthlyDetail;
