/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import api from '@api/services/common';
import { ChartEvent } from 'chart.js/dist/core/core.plugins';
import { ActiveElement } from 'chart.js/dist/plugins/plugin.tooltip';
import { IAggData, IApiResponse, IBarChartData, IPtcData, ILatestMonthAgg } from '../type';

const usePlateTollData = (
  isParentLoading: boolean
): {
  plateTollData: IBarChartData | undefined;
  isLoading: boolean;
  showError: boolean;
  aggData: IAggData;
  fetchPlateTollAggData: () => void;
  handleBarChatClick: (event: ChartEvent, elements: ActiveElement[]) => void;
} => {
  const [plateTollData, setPlateTollData] = useState<IBarChartData>();
  const [isLoading, setIsLoading] = useState(false);
  const [aggData, setAggData] = useState<IAggData>({
    totalTolls: 0,
    total: 0,
    tolls: [],
    amount: [],
    months: [],
    lastMonth: ''
  });
  const [showError, setShowError] = useState(false);

  const getLatestMonthAggregate = (data: IPtcData[]): ILatestMonthAgg => {
    const latestEntry = data.reduce((prev, current) =>
      new Date(current.monthdate) > new Date(prev.monthdate) ? current : prev
    );

    const latestMonth = moment(latestEntry.monthdate).format('MMMM YYYY');

    const totalTolls = Number(latestEntry.plate_toll_count);

    const totalAmount = Math.floor(Number(latestEntry.total_plate_toll_cost));
    return {
      month: latestMonth,
      totalTolls,
      totalAmount,
      tolls: data.map(item => Math.floor(parseFloat(item.plate_toll_count))),
      amount: data.map(item => Math.floor(parseFloat(item.total_plate_toll_cost))),
      months: data.map(item => item.monthdate),
      lastMonth: latestMonth
    };
  };

  const transformDataForBarChart = (data: IPtcData[]) => {
    const sortedData = data
      .slice()
      .sort((a, b) => new Date(a.monthdate).getTime() - new Date(b.monthdate).getTime());

    const labels = sortedData.map(item => moment(item.monthdate).format('MMMM'));
    const chartData: IBarChartData = {
      labels,
      datasets: [
        {
          label: 'Plate Tolls',
          data: sortedData.map(item => parseFloat(item.plate_toll_count)),
          backgroundColor: sortedData.map((_, index, array) => {
            return index === array.length - 1 ? '#FFBF66' : '#6D9EEB';
          })
        }
      ]
    };
    const lastMonthAgg = getLatestMonthAggregate(data);
    aggData.totalTolls = lastMonthAgg.totalTolls;
    aggData.total = lastMonthAgg.totalAmount;
    aggData.amount = lastMonthAgg.amount;
    aggData.tolls = lastMonthAgg.tolls;
    aggData.months = lastMonthAgg.months;
    aggData.lastMonth = lastMonthAgg.lastMonth;
    setAggData({ ...aggData });
    setPlateTollData(chartData);
  };

  const fetchPlateTollAggData = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const response: IApiResponse = await api.post('/dashboard/plate-toll-conversion', {
        moduleId: 'INSIGHTS'
      });
      setShowError(false);
      if (response && response.isSuccess && response.data) {
        transformDataForBarChart(response.data);
      } else {
        setShowError(true);
      }
    } catch (err) {
      console.log('Err in calling ptc data ==> ', err);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBarChatClick = (event: ChartEvent, elements: ActiveElement[]) => {
    if (elements.length > 0) {
      const dataIndex = elements[0].index;
      const toll = (aggData.tolls && aggData.tolls[dataIndex]) || 0;
      const amount = (aggData.amount && aggData.amount[dataIndex]) || 0;
      const selectedMonth = (aggData.months && aggData.months[dataIndex]) || '';

      setAggData(prevAggData => ({
        ...prevAggData,
        totalTolls: toll,
        total: amount,
        lastMonth: selectedMonth
      }));

      setPlateTollData(prevTollData => {
        if (!prevTollData) {
          return prevTollData;
        }

        return {
          ...prevTollData,
          datasets: prevTollData.datasets.map(dataset => ({
            ...dataset,
            backgroundColor: dataset.backgroundColor.map((color, i) =>
              i === dataIndex ? '#FFBF66' : '#6D9EEB'
            )
          })),
          labels: prevTollData.labels
        };
      });
    }
  };

  useEffect(() => {
    if (!isParentLoading) {
      fetchPlateTollAggData().catch(err => {
        console.log('Error in ptc data ==> ', err);
      });
    }
  }, []);

  return {
    plateTollData,
    isLoading,
    showError,
    aggData,
    fetchPlateTollAggData,
    handleBarChatClick
  };
};

export default usePlateTollData;
