/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect } from 'react';
import { useAppSelector } from '@store/hooks';
import api from '@api/services/common';
import { IApiResponse, IFtoAggData } from '../type';

const useFtoAggData = (): {
  ftoAggData: IFtoAggData | undefined;
  isLoading: boolean;
  showError: boolean;
  fetchFtoAggData: () => void;
} => {
  const customerId = useAppSelector(state => state.customer.id);
  const [ftoAggData, setFtoAggData] = useState<IFtoAggData>();
  const [isLoading, setIsLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const fetchFtoAggData = async (): Promise<void> => {
    try {
      setIsLoading(true);
      setShowError(false);
      const response: IApiResponse = await api.post('/dashboard/fto-aggregate', {
        moduleId: 'INSIGHTS'
      });
      if (response.isSuccess && response.data) {
        setFtoAggData(response.data[0]);
      } else {
        setShowError(true);
      }
    } catch (err) {
      console.log('Error in calling ftoSavings ==> ', err);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (customerId) {
      fetchFtoAggData().catch(err => {
        console.log('Error in ftoSavings data ==> ', err);
      });
    }
  }, [customerId]);

  return { ftoAggData, isLoading, showError, fetchFtoAggData };
};

export default useFtoAggData;
