export interface ICategoryData {
  category: string;
  subcategory: string;
  subcategory_name: string;
  amount: string;
  instance_count: string;
  score: number;
  isClickable?: boolean;
  veh_id?: string[];
  screen?: string;
}

export interface ICategoryApiResponse {
  isSuccess: boolean;
  data: ICategoryData[];
}

export const categoryAmountsToHide = ['Partial GPS', 'Missing GPS', 'OEM Issue'];
export const savingsCategory = [
  {
    label: 'MAXTOLLS',
    isClickable: true,
    screen: 'transactions'
  },
  {
    label: 'EXACTDUPLICATE',
    isClickable: true,
    screen: 'transactions'
  },
  {
    label: 'NEARDUPLICATE',
    isClickable: true,
    screen: 'transactions'
  },
  {
    label: 'OVERCHARGE',
    isClickable: true,
    screen: 'transactions'
  },
  {
    label: 'LICENSEPLATETOLLS',
    isClickable: true,
    screen: 'transactions'
  },
  {
    label: 'MISSINGLICENSEPLATE',
    isClickable: false,
    screen: 'fleet'
  },
  {
    label: 'MISSINGTRANSPONDER',
    isClickable: false,
    screen: 'fleet'
  },
  {
    label: 'UNKNOWNVEHICLETOLLING',
    isClickable: false,
    screen: 'transactions'
  },
  {
    label: 'TRANSPONDERMISMATCHES',
    isClickable: false,
    screen: 'fleet'
  },
  {
    label: 'NOVALUETRIPS',
    isClickable: false,
    screen: 'fleet'
  },
  {
    label: 'PARTIALGPS',
    isClickable: false,
    screen: 'fleet'
  },
  {
    label: 'MISSINGGPS',
    isClickable: false,
    screen: 'fleet'
  },
  {
    label: 'OEMISSUE',
    isClickable: false,
    screen: 'fleet'
  },
  {
    label: 'ACTUALVIOLATION',
    isClickable: false,
    screen: 'fleet'
  },
  {
    label: 'POTENTIALVIOLATION',
    isClickable: false,
    screen: 'fleet'
  },
  {
    label: 'DELAYEDPOSTING',
    isClickable: false,
    screen: 'fleet'
  },
  {
    label: 'POTENTIALMISUSE',
    isClickable: false,
    screen: 'fleet'
  }
];
