import { Outlet } from 'react-router-dom';
import styles from './index.module.scss';

const SecondaryLayout = () => {
  return (
    <section className={styles.container}>
      <Outlet />
      <small className={styles.copyright}>Commerce Logic, Inc. All Rights Reserved</small>
    </section>
  );
};

export default SecondaryLayout;
