/* eslint-disable */
import {
  ReactNode,
  useContext,
  useEffect,
  useState,
  createContext,
  useMemo,
  useCallback
} from 'react';
import { useValidateGeotabUser } from '../hooks/useValidateGeotabUser';
import {
  ICalculatedAmount,
  IGeotabUser,
  IGeotabVehicle,
  IGeotabVehiclePlan,
  IRegistrationResponse,
  ISSOContext,
  IState,
  ITrialPeriod
} from '../types';
import { tabs } from '../utils/tabs';
import { IGeotabUserProgress } from '../types/user-progress';
import { useSaveUserProgress } from '../hooks/useSaveUserProgress';

const SSOContext = createContext<ISSOContext>({
  gUser: {} as IGeotabUser,
  gVehicles: [] as IGeotabVehicle[],
  initialVehicles: [] as IGeotabVehicle[],
  trialPeriod: {} as ITrialPeriod,
  stripeURL: '' as string,
  gSubscriptionStatus: false,
  gAgencyList: [],
  gTagTypeList: [],
  stateList: [] as IState[],
  taxDetails: {} as ICalculatedAmount,
  accountId: '' as string,
  customerId: '' as string,
  isCustomerNew: false,
  isInternalUser: false,
  loader: true,
  registerLoader: true,
  activeTabIndex: 0,
  saveUserProgressId: null,
  registrationSuccessful: {} as IRegistrationResponse,
  showSsoErr: false,
  paymentPlans: [] as IGeotabVehiclePlan[],
  handleSubscriptionStatus: () => {},
  handleUserDetails: () => {},
  selectSubscription: () => {},
  handleAccept: () => {},
  handleOverviewAccept: () => {},
  handleVehicleDetails: () => {},
  handleAccountId: () => {},
  handleCustomerId: () => {},
  handleSubscriptionPlan: () => {},
  handleNextTab: () => {},
  handlePrevTab: () => {},
  handleActiveTab: () => {},
  handleRegisterLoader: () => {},
  handleRegistrationSuccess: () => {},
  hideErrorMessage: () => {},
  reloadHandler: () => {}
});

export const SSOContextProvider = ({ children }: { children: ReactNode }) => {
  const {
    isInternalUser,
    isCustomerNew,
    userProgressId,
    loader,
    vehicles,
    initialVehicles,
    userDetails,
    lastCompletedStep,
    billingDetails,
    issuingAgencyList,
    subscriptionStatus,
    tagTypes,
    gStates,
    hideErrorMessage,
    paymentPlans,
    showSsoErr
  } = useValidateGeotabUser();

  const { handleSaveUserProgress } = useSaveUserProgress();
  const [activeTabIndex, setActiveTabIndex] = useState(lastCompletedStep || 0);
  const [registerLoader, setRegisterLoader] = useState(false);
  const [saveUserProgressId, setSaveUserProgressId] = useState<number | null>(
    userProgressId || null
  );
  const [registrationSuccessful, setRegistrationSuccessful] = useState({
    value: false,
    message: ''
  });

  const reloadHandler = () => {
    window.location.reload();
  };
  const handleRegistrationSuccess = useCallback(
    (value: boolean, message: string) => {
      registrationSuccessful.value = value;
      registrationSuccessful.message = message;
      setRegistrationSuccessful({ ...registrationSuccessful });
    },
    [registrationSuccessful]
  );
  const handleActiveTab = (index: number) => {
    setActiveTabIndex(index);
  };
  const handleRegisterLoader = (value: boolean) => setRegisterLoader(value);
  const handleNextTab = async (
    userDetails?: IGeotabUser | null,
    vehicleDetails?: IGeotabVehicle[] | null
  ) => {
    setActiveTabIndex(prev => (prev + 1 !== tabs.length ? prev + 1 : prev));
    if (activeTabIndex === 2 || activeTabIndex === 3 || activeTabIndex === 4) {
      const updateUserProgressBody: IGeotabUserProgress = {
        activeStep: activeTabIndex,
        companyDetails: userDetails ? userDetails : gUser,
        vehicleDetails: vehicleDetails ? vehicleDetails : gVehicles
      };
      if (activeTabIndex === 2 || saveUserProgressId) {
        const response = await handleSaveUserProgress(saveUserProgressId, updateUserProgressBody);
        if (response.isSuccess && response.data && response.data.id) {
          setSaveUserProgressId(response.data.id);
        }
      }
    }
  };
  const handlePrevTab = () => {
    setActiveTabIndex(prev => (prev !== 0 ? prev - 1 : prev));
  };
  const [gUser, setGUser] = useState(userDetails);
  const [gVehicles, setGVehicles] = useState(vehicles);
  const [gAgencyList, setGAgencyList] = useState(issuingAgencyList as []);
  const [gTagTypeList, setGTagTypeList] = useState(tagTypes as []);
  const [stateList, setStateList] = useState(gStates as []);
  const [taxDetails, setTaxDetails] = useState({ pricePerVehicle: 0, taxedTotal: 0, totalTax: 0 });
  const [stripeURL, setStripeURL] = useState('');
  const [gSubscriptionStatus, setGSubscriptionStatus] = useState(false);
  const [trialPeriod, setTrialPeriod] = useState({ trialStartDate: '', trialEndDate: '' });
  const [accountId, setAccountId] = useState('');
  const [customerId, setCustomerId] = useState('');
  const handleUserDetails = (values: IGeotabUser) => setGUser(values);
  const handleSubscriptionStatus = (value: boolean) => setGSubscriptionStatus(value);
  const handleVehicleDetails = (values: IGeotabVehicle[]) => setGVehicles(values || []);
  const handleAccountId = (value: string) => setAccountId(value);
  const handleCustomerId = (value: string) => setCustomerId(value);
  const handleOverviewAccept = useCallback(() => {
    setActiveTabIndex(1);
  }, []);
  const handleAccept = useCallback(() => {
    setActiveTabIndex(2);
    gUser.consent = true;
  }, [gUser, gVehicles, taxDetails, billingDetails]);

  const selectSubscription = useCallback(
    (index: number, subscription: 'ACTIVE' | 'INACTIVE') => {
      if (isCustomerNew) {
        gVehicles[index].SUBSCRIPTION = subscription;
        setGVehicles(prev => [...prev]);
        const totalPrice =
          gVehicles.filter(x => x.SUBSCRIPTION === 'ACTIVE').length *
          Number(billingDetails?.monthly_price_per_vehicle);
        const totalTaxAmount = billingDetails?.estimated_tax.reduce(
          (a, b) =>
            Number(a) +
            (totalPrice * (Number(b.tax_percentage) / 100) + Number(b.fixed_additional_amount)),
          0
        );
        taxDetails.pricePerVehicle = Number(billingDetails?.monthly_price_per_vehicle);
        taxDetails.taxedTotal = Number(totalPrice) + Number(totalTaxAmount);
        taxDetails.totalTax = Number(totalTaxAmount);
        setTaxDetails({ ...taxDetails });
      }
    },
    [gVehicles, billingDetails, taxDetails, isCustomerNew]
  );
  const handleSubscriptionPlan = useCallback(
    (value: string) => {
      gUser.subscriptionPlan = value;
      setGUser(prev => ({ ...prev }));
    },
    [gUser]
  );
  useEffect(() => {
    setGUser(userDetails);
  }, [userDetails]);
  useEffect(() => {
    handleSubscriptionStatus(subscriptionStatus);
  }, [subscriptionStatus]);

  useEffect(() => {
    if (billingDetails?.trialStartDate && billingDetails.trialEndDate) {
      trialPeriod.trialStartDate = billingDetails.trialStartDate;
      trialPeriod.trialEndDate = billingDetails.trialEndDate;
      setTrialPeriod({ ...trialPeriod });
    }
    if (billingDetails?.stripeURL) {
      setStripeURL(billingDetails.stripeURL);
    }
  }, [billingDetails]);

  useEffect(() => {
    if (isCustomerNew) {
      const totalPrice =
        gVehicles.filter(x => x.SUBSCRIPTION === 'ACTIVE').length *
        Number(billingDetails?.monthly_price_per_vehicle);
      const totalTaxAmount = billingDetails?.estimated_tax.reduce(
        (a, b) =>
          Number(a) +
          (totalPrice * (Number(b.tax_percentage) / 100) + Number(b.fixed_additional_amount)),
        0
      );
      taxDetails.pricePerVehicle = Number(billingDetails?.monthly_price_per_vehicle);
      taxDetails.taxedTotal = Number(totalPrice) + Number(totalTaxAmount);
      taxDetails.totalTax = Number(totalTaxAmount);
      setTaxDetails({ ...taxDetails });
    }
  }, [gVehicles, billingDetails, isCustomerNew]);

  useEffect(() => {
    setGVehicles(vehicles);
  }, [vehicles]);

  useEffect(() => {
    setSaveUserProgressId(userProgressId);
  }, [userProgressId]);

  useEffect(() => {
    setActiveTabIndex(lastCompletedStep);
  }, [lastCompletedStep]);

  useEffect(() => {
    setGAgencyList(issuingAgencyList as []);
  }, [gAgencyList, issuingAgencyList]);

  useEffect(() => {
    setGTagTypeList(tagTypes as []);
  }, [gTagTypeList, tagTypes]);

  useEffect(() => {
    setStateList(gStates as []);
  }, [stateList, gStates]);
  const memofiedValues = useMemo(
    () => ({
      gUser,
      gVehicles,
      initialVehicles,
      gAgencyList,
      gTagTypeList,
      stateList,
      taxDetails,
      trialPeriod,
      stripeURL,
      gSubscriptionStatus,
      accountId,
      customerId,
      isCustomerNew,
      isInternalUser,
      userProgressId,
      registrationSuccessful,
      loader,
      registerLoader,
      saveUserProgressId,
      activeTabIndex,
      paymentPlans,
      showSsoErr,
      handleSubscriptionPlan,
      selectSubscription,
      handleUserDetails,
      handleSubscriptionStatus,
      handleVehicleDetails,
      handleAccountId,
      handleCustomerId,
      handleActiveTab,
      handleRegisterLoader,
      handleAccept,
      handleOverviewAccept,
      handleNextTab,
      handlePrevTab,
      handleRegistrationSuccess,
      hideErrorMessage,
      reloadHandler
    }),
    [
      activeTabIndex,
      gUser,
      gVehicles,
      initialVehicles,
      accountId,
      customerId,
      gAgencyList,
      gTagTypeList,
      stateList,
      taxDetails,
      trialPeriod,
      stripeURL,
      gSubscriptionStatus,
      isCustomerNew,
      isInternalUser,
      userProgressId,
      loader,
      registerLoader,
      saveUserProgressId,
      registrationSuccessful,
      paymentPlans,
      showSsoErr,
      handleSubscriptionPlan,
      handleNextTab,
      handleAccept,
      handleOverviewAccept,
      selectSubscription,
      handleRegistrationSuccess,
      hideErrorMessage
    ]
  );

  return <SSOContext.Provider value={memofiedValues}>{children}</SSOContext.Provider>;
};

export const useSSOContext = () => {
  return useContext(SSOContext);
};
