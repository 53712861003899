/* eslint-disable @typescript-eslint/no-misused-promises */
import { memo, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { ChartOptions } from 'chart.js';
import MaterialCard from '@common/ui/Mui/Card/MaterialCard';
import CustomDoughnutChart from '@common/ui/Chart/DoughnutChart/DoughnutChart';
import { formatCurrency, formatNumberToUSStandard } from '@pages/dashboard/common/helper';
import { useTheme } from '@mui/material/styles';
import { Button, Grid, useMediaQuery } from '@mui/material';
import { Repeat } from '@assets/icons';
import styles from './dispute-tolls.module.scss';
import { IFtoProps } from '../type';
import useDisputableTollsData from './hooks/useDisputableTollsData';

const DisputableTolls = ({ width = '100%', height = '25.7rem' }: IFtoProps) => {
  const {
    fetchDisputableTollsData,
    updateTotalAmount,
    disputableTollsData,
    dateRange,
    total,
    count,
    isLoading,
    showError,
    aggData
  } = useDisputableTollsData();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));
  const [donutChartOptions, setDonutChartOptions] = useState<ChartOptions<'doughnut'>>({
    layout: {
      autoPadding: true,
      padding: {
        right: 50, // lg : 65, md: 125, sm: 0, xs: 50
        top: 10
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          pointStyleWidth: 10, // md : 7 lg: 13
          font: {
            size: 20, // md : 5 lg : 10
            weight: 'bold'
          }
        },
        onClick: (e, legendItem, legend) => {
          const dataIndex = legendItem.index || 0;
          legend.chart.toggleDataVisibility(dataIndex);

          const updateArray: boolean[] = [];

          for (let i = 0; i < legend.chart.data.datasets[0].data.length; i += 1) {
            updateArray.push(legend.chart.getDataVisibility(i));
          }

          updateTotalAmount(updateArray, legend.chart.data.datasets[0].data);
          legend.chart.update();
        }
      },
      datalabels: {
        display: true,
        color: 'black',
        font: {
          size: 14
        },
        formatter: (value, ctx) => {
          if (!value && typeof value !== 'number') {
            return '';
          }
          let sum = 0;
          const values: Array<number> = [];
          const dataArr = ctx.chart.data.datasets[0].data;
          dataArr.forEach(data => {
            if (data && typeof data === 'number') {
              values.push(data);
              sum += data;
            } else if (data === 0) {
              values.push(data);
            }
          });
          const minValue = Math.min(...values);
          if (value === minValue && (value * 100) / sum < 3) {
            return '';
          }
          const percentage = `${((value * 100) / sum).toFixed(1)}%`;
          return percentage;
        }
      },
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            const dataIndex = tooltipItem.dataIndex || 0;

            const counts = aggData?.count?.[dataIndex] ?? 0;
            const amount = Math.round(aggData?.amount?.[dataIndex]) ?? 0;

            const Amount = ` Amount: ${formatCurrency(amount.toString(), true)}`;
            const Counts = ` Toll Count: ${formatNumberToUSStandard(counts)}`;

            return [Counts, Amount];
          }
        }
      }
    }
  });
  useEffect(() => {
    let newPadding = { right: 50, top: 10 };
    let newPointStyleWidth = 13;
    let newFontSize = 10;

    switch (true) {
      case isLg:
        newPadding = { right: 65, top: 10 };
        newPointStyleWidth = 13;
        newFontSize = 10;
        break;
      case isXl:
        newPointStyleWidth = 10;
        newFontSize = 8;
        break;
      case isMd:
        newPadding = { right: 110, top: 0 };
        newPointStyleWidth = 8;
        newFontSize = 6;
        break;
      case isSm:
        newPadding = { right: 0, top: 0 };
        newPointStyleWidth = 7;
        newFontSize = 5;
        break;
      default:
        newPadding = { right: 50, top: 10 };
        newPointStyleWidth = 13;
        newFontSize = 10;
        break;
    }

    setDonutChartOptions(prevOptions => ({
      ...prevOptions,
      layout: {
        ...prevOptions.layout,
        padding: newPadding
      },
      plugins: {
        ...prevOptions.plugins,
        legend: {
          ...prevOptions.plugins?.legend,
          labels: {
            ...prevOptions.plugins?.legend?.labels,
            pointStyleWidth: newPointStyleWidth,
            font: {
              ...prevOptions.plugins?.legend?.labels?.font,
              size: newFontSize
            }
          }
        }
      }
    }));
  }, [isXs, isSm, isMd, isLg, isXl]);
  return (
    <div className={styles.container}>
      <MaterialCard
        title="Disputes"
        subTitle={
          !isLoading && (
            <>
              Showing <b>{moment.utc(dateRange.from).format('MMM YYYY')} </b> to{' '}
              <b>{moment.utc(dateRange.to).format('MMM YYYY')}</b>
            </>
            // <>
            //   Showing <b>{formatMonthYear(dateRange.from)} </b> to{' '}
            //   <b>{formatMonthYear(dateRange.to)}</b>
            // </>
          )
        }
        cardVariant="child"
        cardWidth={width}
        cardHeight={height}
        maxCols={6}
        noGrid
        showHeaderArrow
      >
        {isLoading && <div className={styles.loader}>Loading...</div>}

        {!isLoading && !showError && disputableTollsData && (
          <Grid container>
            <Grid xs={6} sm={6} md={6} lg={6}>
              <div className={styles.aggregate}>
                <span>
                  <h4>Count</h4>
                  <p>{formatNumberToUSStandard(count)}</p>
                </span>
                <span>
                  <h4>Total</h4>
                  <p>{formatCurrency(total, true)}</p>
                </span>
              </div>
            </Grid>
            <Grid xs={6} sm={6} md={6} lg={6}>
              <CustomDoughnutChart
                chartOptions={donutChartOptions}
                data={disputableTollsData}
                width={250}
                height={220}
              />
            </Grid>
          </Grid>
        )}
        {!isLoading && (showError || !disputableTollsData) && (
          <div className={styles.error}>
            Something went wrong!
            <div className={styles.retry}>
              <Button
                size="small"
                variant="contained"
                onClick={fetchDisputableTollsData}
                startIcon={<Repeat className={styles.icon} />}
              >
                Retry
              </Button>
            </div>
          </div>
        )}
      </MaterialCard>
    </div>
  );
};

export default memo(DisputableTolls);
