export function Location({ className }: ClassName) {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.92676 4.48633C8.48175 4.48633 8.04674 4.61829 7.67673 4.86552C7.30672 5.11275 7.01833 5.46416 6.84803 5.87529C6.67773 6.28642 6.63318 6.73882 6.71999 7.17528C6.80681 7.61174 7.0211 8.01265 7.33577 8.32732C7.65044 8.64199 8.05135 8.85628 8.48781 8.9431C8.92426 9.02991 9.37666 8.98535 9.7878 8.81506C10.1989 8.64476 10.5503 8.35637 10.7976 7.98636C11.0448 7.61635 11.1768 7.18134 11.1768 6.73633C11.1761 6.1398 10.9388 5.5679 10.517 5.14609C10.0952 4.72428 9.52329 4.48701 8.92676 4.48633ZM8.92676 7.86133C8.70425 7.86133 8.48675 7.79535 8.30174 7.67173C8.11674 7.54811 7.97254 7.37241 7.88739 7.16685C7.80225 6.96128 7.77997 6.73508 7.82338 6.51685C7.86678 6.29862 7.97393 6.09817 8.13126 5.94083C8.2886 5.7835 8.48905 5.67635 8.70728 5.63294C8.92551 5.58954 9.15171 5.61182 9.35728 5.69696C9.56284 5.78211 9.73855 5.92631 9.86216 6.11131C9.98578 6.29632 10.0518 6.51382 10.0518 6.73633C10.0514 7.03459 9.93278 7.32054 9.72187 7.53144C9.51097 7.74235 9.22502 7.86098 8.92676 7.86133Z"
        fill="currentColor"
      />
      <path
        d="M13.251 2.40948C12.1819 1.34074 10.755 0.70587 9.24538 0.627392C7.73581 0.548914 6.25067 1.03239 5.07661 1.98452C3.90254 2.93664 3.12278 4.28992 2.88781 5.78315C2.65285 7.27639 2.97933 8.80374 3.80426 10.0704L8.0549 16.5959C8.14949 16.7411 8.27882 16.8604 8.43118 16.943C8.58353 17.0255 8.75409 17.0688 8.92739 17.0688C9.10068 17.0688 9.27124 17.0255 9.4236 16.943C9.57595 16.8604 9.70529 16.7411 9.79988 16.5959L14.0507 10.0704C14.8161 8.89539 15.1544 7.49296 15.0088 6.09819C14.8632 4.70342 14.2426 3.40109 13.251 2.40948ZM13.108 9.45634L8.92741 15.8741L4.74673 9.45634C3.46704 7.49184 3.74151 4.86286 5.39933 3.20496C5.86264 2.74164 6.41267 2.37411 7.01802 2.12336C7.62337 1.87261 8.27218 1.74355 8.92741 1.74355C9.58263 1.74355 10.2314 1.87261 10.8368 2.12336C11.4421 2.37411 11.9922 2.74164 12.4555 3.20496C14.1133 4.86286 14.3877 7.49184 13.108 9.45634Z"
        fill="currentColor"
      />
    </svg>
  );
}
