import { v4 as uuid } from 'uuid';
import Help from '@components/help';
import styles from './header.module.scss';
import { tabs } from '../../../utils/tabs';
import { useSSOContext } from '../../../context/SSOContext';

const Header = () => {
  const { isCustomerNew, activeTabIndex } = useSSOContext();
  return (
    <div className={styles.container}>
      {
        isCustomerNew ? (
          <ul className={styles.steps}>
            {tabs.map((tab, tabIndex) => (
              <li className={tabIndex === activeTabIndex ? styles.active : ''} key={uuid()}>
                <div className={styles.text}>
                  <h4 className={tabIndex === activeTabIndex ? styles.heading : ''}>
                    {tabIndex === activeTabIndex ? (
                      tab.label
                    ) : (
                      <p
                        style={
                          tabIndex < activeTabIndex
                            ? { background: '#80a3e5' }
                            : { background: '#d8dee9' }
                        }
                        className={styles['step-label']}
                      >
                        {tabIndex + 1}
                      </p>
                    )}
                  </h4>
                  {tabIndex === activeTabIndex && (
                    <p className={styles['sub-heading']}>{tab.subHeading}</p>
                  )}
                </div>
                {tabIndex === activeTabIndex && (
                  <Help location={`geotab_onboarding_${tabIndex}`} className={styles.help} />
                )}
              </li>
            ))}
          </ul>
        ) : (
          <div className={styles['only-text']}>User Enrollment</div>
        )
        //   // <div className={styles['only-text']}>
        //   <ul className={styles.steps}>
        //     {tabs
        //       .filter(x => x.isSecondary)
        //       .map((tab, tabIndex) => (
        //         <li className={tabIndex === activeTabIndex ? styles.active : ''} key={uuid()}>
        //           <div className={styles.text}>
        //             <h4 className={tabIndex === activeTabIndex ? styles.heading : ''}>
        //               {tabIndex === activeTabIndex ? (
        //                 // tab.label
        //                 `Step ${activeTabIndex + 1}/${tabs.filter(x => x.isSecondary).length}`
        //               ) : (
        //                 <p
        //                   style={
        //                     tabIndex < activeTabIndex
        //                       ? { background: '#80a3e5' }
        //                       : { background: '#d8dee9' }
        //                   }
        //                   className={styles['step-label']}
        //                 >
        //                   {tabIndex + 1}
        //                 </p>
        //               )}
        //             </h4>
        //             {tabIndex === activeTabIndex && (
        //               <p className={styles['sub-heading']}>{tab.subHeading}</p>
        //             )}
        //           </div>
        //           {tabIndex === activeTabIndex && (
        //             <Help location={`geotab_onboarding_${tabIndex}`} className={styles.help} />
        //           )}
        //         </li>
        //       ))}
        //   </ul>
      }
    </div>
  );
};

export default Header;
