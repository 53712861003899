/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect } from 'react';
import api from '@api/services/common';
import { IApiResponse, IDonutChartData, IFtoSavingsData } from '../type';

const useFtoSavingsData = (
  isParentLoading: boolean
): {
  ftoSavingsData: IDonutChartData | undefined;
  dateRange: { from: string; to: string };
  isLoading: boolean;
  total: string;
  showError: boolean;
  fetchFtoSavingsData: () => void;
  updateTotalAmount: (visibilityArray: boolean[], data?: number[]) => void;
} => {
  const [ftoSavingsData, setFtoSavingsData] = useState<IDonutChartData>();
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [dateRange, setDateRange] = useState({ from: '', to: '' });
  const [total, setTotal] = useState('');

  const transformDataForDonutChart = (data: IFtoSavingsData[]) => {
    const labels = data.map(item => item.categoryname);
    const values = data.map(item => Number(item.total_amount));
    let totalAmount = 0;

    const calculatePercentages = (percentValues: number[]): string[] => {
      const percentTotal = percentValues.reduce((acc, val) => acc + val, 0);
      return percentValues.map(val => `${((val / percentTotal) * 100).toFixed(1)}%`);
    };

    const percentages = calculatePercentages(values);

    data.forEach(item => {
      totalAmount += Number(item.total_amount);
    });

    setTotal(totalAmount.toFixed(2));

    const chartData: IDonutChartData = {
      labels: labels.map((label, index) => `${label} (${percentages[index]})`),
      values,
      colors: ['#FFE599', '#9FC5E8', '#3C78D8', '#FFBF66'],
      borderColors: ['#FFE599', '#9FC5E8', '#3C78D8', '#FFBF66'],
      borderWidth: 1,
      label: 'Savings'
    };
    setFtoSavingsData(chartData);
  };

  const updateTotalAmount = (visibilityArray: boolean[], data?: number[]): void => {
    const filteredValues = data?.reduce((result, value, index) => {
      if (visibilityArray[index]) {
        result.push(value);
      }
      return result;
    }, [] as number[]);

    const sumOfFilteredValues = filteredValues?.reduce((sum, value) => sum + value, 0);
    setTotal(sumOfFilteredValues?.toFixed(2) || '');
  };

  const fetchFtoSavingsData = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const response: IApiResponse = await api.post('/dashboard/fto-savings', {
        moduleId: 'INSIGHTS'
      });
      setShowError(false);
      if (response && response.isSuccess && response.data) {
        transformDataForDonutChart(response.data.data);
        dateRange.from = response.data.dateRange.start_date;
        dateRange.to = response.data.dateRange.end_date;
        setDateRange({ ...dateRange });
      } else {
        setShowError(true);
      }
    } catch (err) {
      console.log('Error in calling ftoSavings ==> ', err);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isParentLoading) {
      fetchFtoSavingsData().catch(err => {
        console.log('Error in ftoSavings data ==> ', err);
      });
    }
  }, []);

  return {
    ftoSavingsData,
    dateRange,
    total,
    isLoading,
    showError,
    fetchFtoSavingsData,
    updateTotalAmount
  };
};

export default useFtoSavingsData;
