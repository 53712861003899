import { useAppSelector } from '@store/hooks';
import { useDateRangeData } from '@pages/dashboard/monthly-detail/header/hooks/useDateRangeData';
import moment from 'moment-timezone';
import styles from './dashboard-header.module.scss';

const DashboardHeader = () => {
  const companyName = useAppSelector(state => state.customer.companyName);
  const { data } = useDateRangeData();
  const lastUpdated = moment.utc(data[0]).endOf('month').format('MMMM DD, YYYY');
  const to = moment.utc(data[0]).format('MMMM YYYY');
  const from = moment.utc(data[0]).subtract(11, 'months').format('MMMM YYYY');

  return (
    <header className={styles.container}>
      <section className={styles.title}>
        <span className={styles.customer}>{companyName}</span>
        <p className={styles['updated-by']}>Last updated - {lastUpdated}</p>
        <p>
          Showing the last 12 months ({from} to {to})
        </p>
      </section>
    </header>
  );
};

export default DashboardHeader;
