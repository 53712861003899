import { CustomerDataInterface } from '@common/types/data';

export const initialValues: Omit<
  CustomerDataInterface,
  | 'id'
  | 'statusCode'
  | 'sourceType'
  | 'companyLogo'
  | 'logoContent'
  | 'isVisible'
  | 'loading'
  | 'isSuperAdmin'
> & {
  logo: File | null;
} = {
  adminEmail: '',
  companyName: '',
  companyAddress: '',
  geotabIntegration: false,
  geotabDatabase: '',
  geotabServiceUser: '',
  geotabServicePassword: '',
  phoneNumber: '',
  logo: null
};
