/* eslint-disable */
import { v4 as uuid } from 'uuid';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import styles from './subscribeTable.module.scss';
import Loader from '@common/ui/Loader/Loader';
import {
  addToSubscribe,
  addToUnSubscribe,
  getUnSubscribedVehicle,
  removeFromSubscribe,
  removeFromUnSubscribe,
  setSortingValue
} from '@store/features/subscribe';
import { ArrowsFilled } from '@assets/icons';

interface SubscribeTableProps {
  subscribe: boolean;
}

const SubscribeTable: React.FC<SubscribeTableProps> = ({ subscribe }) => {
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(state => state.customer.id);
  const loading = useAppSelector(state => state.subscribe.loading);
  const data = useAppSelector(state => state.subscribe.data);
  const subscribedVehicle = useAppSelector(state => state.subscribe.subscribedVehicle);
  const unSubscribedVehicle = useAppSelector(state => state.subscribe.unSubscribedVehicle);
  const previousSortValue = useAppSelector(state => state.subscribe.sorting);

  const header = [
    'name',
    'vin',
    'state',
    'license plate',
    'make',
    'model',
    'year',
    'billing status'
  ];

  function sortHelper(value: string) {
    let sortObject = {
      sort_by: '',
      sort_order: '',
      subscribe: subscribe
    };

    if (previousSortValue.sort_by === value) {
      if (previousSortValue.sort_order === '') {
        sortObject.sort_by = value;
        sortObject.sort_order = 'ASC';
      } else if (previousSortValue.sort_order === 'ASC') {
        sortObject.sort_by = value;
        sortObject.sort_order = 'DESC';
      }
    } else {
      sortObject.sort_by = value;
      sortObject.sort_order = 'ASC';
    }
    dispatch(setSortingValue(sortObject));
    dispatch(
      //@ts-ignore
      getUnSubscribedVehicle(sortObject)
    );
  }

  const iconStyle = `${styles.icon} ${
    previousSortValue.sort_order ? styles[previousSortValue.sort_order] : ''
  }`;

  return loading ? (
    <Loader className={styles.loader} />
  ) : (
    <section className={styles.tableWrapper}>
      <div className={styles.container}>
        {data.length > 0 ? (
          <>
            <div className={styles.header}>
              <section className={styles.row}>
                <section className={styles.text}>
                  {header.map(heading => (
                    <>
                      <h3
                        onClick={() => {
                          sortHelper(
                            heading === 'state'
                              ? 'plate_state'
                              : heading === 'license plate'
                              ? 'plate_number'
                              : heading === 'billing status'
                              ? 'billing_status'
                              : heading
                          );
                        }}
                        className={
                          previousSortValue.sort_by.length > 0 &&
                          heading === previousSortValue.sort_by
                            ? iconStyle
                            : heading === 'state' && 'plate_state' === previousSortValue.sort_by
                            ? iconStyle
                            : heading === 'license plate' &&
                              'plate_number' === previousSortValue.sort_by
                            ? iconStyle
                            : heading === 'billing status' &&
                              'billing_status' === previousSortValue.sort_by
                            ? iconStyle
                            : styles.icon
                        }
                      >
                        {heading} <ArrowsFilled />
                      </h3>
                    </>
                  ))}
                  <h3 style={{ marginLeft: '3rem' }}>SUBSCRIBE</h3>
                </section>
              </section>
            </div>
            <div className={styles.body}>
              {data &&
                data.map((item: any) => (
                  <section className={styles.row} key={uuid()}>
                    <section className={styles.text}>
                      <p id="name">{item?.name ? item.name : '-'}</p>
                      <p className={styles.vin} id="vin">
                        {item?.vin ? item.vin : '-'}
                      </p>
                      <p className={styles.state} id="state">
                        {item?.plate_state ? item.plate_state.toUpperCase() : '-'}
                      </p>
                      <p id="licensePlate">
                        {item?.plate_number ? item.plate_number.toUpperCase() : '-'}
                      </p>
                      <p id="make">{item?.make ? item.make : '-'}</p>
                      <p id="model">{item?.model ? item.model : '-'}</p>
                      <p id="year">{item?.year ? item.year : '-'}</p>
                      <p id="status">
                        {item?.billing_status
                          ? item?.billing_status === 'INACTIVE'
                            ? 'Unsubscribed'
                            : item?.billing_status === 'DELETED'
                            ? 'Deleted'
                            : item?.billing_status === 'ACTIVE'
                            ? 'Subscribed'
                            : 'Unsubscribed Permanently'
                          : '-'}
                      </p>
                      <p id="subscribe" style={{ marginLeft: '5rem' }}>
                        <input
                          type="checkbox"
                          checked={
                            subscribe
                              ? unSubscribedVehicle.some(el => el.vehicle_id === item.vehicle_id)
                              : subscribedVehicle.some(el => el.vehicle_id === item.vehicle_id)
                          }
                          onChange={e => {
                            if (e.target.checked) {
                              if (subscribe) {
                                dispatch(addToUnSubscribe(item));
                              } else {
                                dispatch(addToSubscribe(item));
                              }
                            } else {
                              if (subscribe) {
                                dispatch(removeFromUnSubscribe(item));
                              } else {
                                dispatch(removeFromSubscribe(item));
                              }
                            }
                          }}
                        />
                      </p>
                    </section>
                  </section>
                ))}
            </div>
          </>
        ) : (
          <div className={styles.noData}>
            <h3>
              {subscribe
                ? 'All vehicles are already unsubscribed'
                : 'All vehicles are already subscribed'}{' '}
            </h3>
          </div>
        )}
      </div>
    </section>
  );
};

export default SubscribeTable;
