/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { ETableName } from '@common/utils/enums';
import { IHeading } from '@store/features/headings';
import SubscribeButton from '@pages/tables/layouts/commonComponents/subscribe/SubcribeButton';
import { useEditPermission } from '@pages/tables/hooks/useEditPermission';
import { clearAllSelectionIDs } from '@store/features/selection';
import { routes } from '@common/utils/route-names';
import Button from '@common/ui/Button';
import { Notifications } from '@assets/icons';
import Modal from '@common/ui/Modal';
import Alert from '@pages/tables/layouts/web-portal/components/modals/RowModal/Vehicle/Alert';
import Toggle from '@common/ui/Toggle';
import { setTransactionTab } from '@store/features/helpers';
import { setCurrentPage } from '@store/features/pagination';
import { clearAlertFilters } from '@store/data/fleetTabs';
import ExportDropdown from '@pages/tables/layouts/web-portal/components/export/ExportDropdown';
import { refetchTableData } from '@store/data';
import SelectInput from '@common/ui/FilterDropdown/SelectInput';
import {
  fleetHelperTypes,
  transactionHelperTypes
} from '@pages/tables/layouts/web-portal/components/smart-filters/QuickFilters/helper';
import {
  clearFleetVehicleNames,
  clearFleetmissingAssetsAndTransponders,
  clearTransactionsType,
  setFleetmissingAssetsAndTransponders,
  setTransactionsType
} from '@store/features/quick-filters';
import queryString from 'query-string';
import styles from './actions.module.scss';
import DateFilters from './date-filters/DateFilters';
import ExportButtons from './export/Export';
import ModifyColumnsModal from '../../table/modify-columns-modal/ModifyColumnsModal';

const Actions = ({
  tableName,
  datesFiltering
}: {
  tableName: ETableName;
  datesFiltering?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { activeModal: modal, id: storedModalId } = useAppSelector(state => state.modal);
  let headings = useAppSelector(state => state.headings[tableName]) as IHeading[];

  const pagination = useAppSelector(state => state.pagination[tableName]);
  const customerId = useAppSelector(state => state.customer.id);
  const hasEditAccess = !useEditPermission(routes.fleet);
  const [showAlert, setShowAlert] = useState(false);
  const isActualTollTab = useAppSelector(state => state.helpers.isActualTollTab);
  const quickFiltersSelection = useAppSelector(state => state.quickFilters);
  const table = useAppSelector(state => state.table);

  useEffect(() => {
    const params = queryString.parse(window.location.search, {
      parseBooleans: true,
      parseNumbers: true
    });
    const crossFilterSource = params['cross-filter'] ? (params.source as ETableName) : undefined;
    if (crossFilterSource && (params.destination as ETableName)) {
      dispatch(clearFleetVehicleNames());
      /* Enable to include MissingType in Cross filter */
      // if (params?.searchId && params.destination === ETableName.FLEET) {
      //   const data = [];
      //   data.push(params.searchId);
      //   dispatch(setFleetmissingAssetsAndTransponders(data as string[]));
      // }
    }
  }, [window.location.search]);

  if (tableName === ETableName.TRANSACTIONS) {
    if (isActualTollTab) {
      const fieldsToRemove = ['trip_id'];
      headings = headings.filter(item => !fieldsToRemove.includes(item.field));
    } else {
      const fieldsToRemove = ['statusMessage', 'feeType', 'feeSubType', 'uploadDate', 'tollBillId'];
      headings = headings.filter(item => !fieldsToRemove.includes(item.field));
    }
  }

  const changeTransactionToggle = () => {
    dispatch(clearAllSelectionIDs());
    dispatch(setTransactionTab(!isActualTollTab));
    if (pagination.currentPage === 1) {
      dispatch(refetchTableData(true));
    } else {
      dispatch(setCurrentPage({ table: ETableName.TRANSACTIONS, currentPage: 1 }));
    }
  };

  return (
    <>
      <div className={styles.container}>
        {tableName === ETableName.TRANSACTIONS && (
          <>
            {isActualTollTab && (
              <SelectInput
                handleChange={data => {
                  dispatch(setTransactionsType(data as string[]));
                }}
                values={transactionHelperTypes.map(x => x.label)}
                selection={quickFiltersSelection.transactions.transactionsType}
                clearFilterSelection={() => dispatch(clearTransactionsType())}
                disabled={table.isLoading}
                type="tags"
                placeholder="Type"
              />
            )}
            <div className={styles['toggle-div']}>
              <Toggle
                label1="Actual Tolls"
                label2="GPS Tolls"
                state={isActualTollTab}
                handleToggle={() => changeTransactionToggle()}
              />
            </div>
          </>
        )}
        {datesFiltering && (
          <DateFilters
            tableName={tableName}
            startLabel={
              tableName === ETableName.TRIPS ? 'Trip Start Date From' : 'Document Date From'
            }
            endLabel={tableName === ETableName.TRIPS ? 'Trip Start Date To' : 'Document Date To'}
          />
        )}
        {customerId && tableName === ETableName.FLEET && (
          <>
            <SelectInput
              className={`${styles.input}`}
              handleChange={data => {
                dispatch(setFleetmissingAssetsAndTransponders(data as string[]));
              }}
              includeSearch
              values={fleetHelperTypes.map(x => x.label)}
              selection={quickFiltersSelection.fleet.missingItems}
              clearFilterSelection={() => {
                dispatch(clearFleetmissingAssetsAndTransponders());
              }}
              disabled={table.isLoading}
              type="tags"
              placeholder="Missing Item"
            />
            <Button
              className={styles.map}
              variant="tertiary"
              appearance="icon"
              size="s"
              icon={<Notifications className={styles.icon} />}
              onClick={() => setShowAlert(!showAlert)}
            />
          </>
        )}
        {showAlert && (
          <Modal
            activeTitle="Alerts"
            navigation={['Alerts']}
            primaryButton={false}
            customCloseFunc={() => {
              dispatch(setCurrentPage({ table: 'alerts' as ETableName, currentPage: 1 }));
              dispatch(clearAlertFilters());
              setShowAlert(false);
            }}
          >
            <AnimatePresence exitBeforeEnter initial={false}>
              <Alert />
            </AnimatePresence>
          </Modal>
        )}
        {tableName === ETableName.FLEET && <SubscribeButton disabled={hasEditAccess} />}
        {tableName !== ETableName.TRIPS ? (
          <ExportButtons tableName={tableName} />
        ) : (
          <ExportDropdown />
        )}
      </div>
      <AnimatePresence>
        {modal === 'table-display' && tableName === storedModalId && (
          <ModifyColumnsModal headings={headings} tableName={tableName} />
        )}
      </AnimatePresence>
    </>
  );
};

export default Actions;
