import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Meta from '@common/components/meta';
import { ETableName } from '@common/utils/enums';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { initSearch } from '@store/features/search';
import _ from 'lodash';
import { useSetTable } from './hooks';
import ViewTrip from './modals/view/View';
import GeotabTable from '../layouts/geotab-addin';
import WebPortalTable from '../layouts/web-portal';

const Trips = () => {
  const metaOptions = {
    title: 'Trips',
    keywords: 'Trips'
  };
  const name = ETableName.TRIPS;
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (location.search && queryParams.get('tripId')) {
      dispatch(
        initSearch({
          table: ETableName.TRIPS,
          searchBy: queryParams.get('tripId') || '',
          field: 'TRIP ID'
        })
      );
    }
  }, [dispatch, location.search]);

  useSetTable({ name });
  const user = useAppSelector(state => state.user);
  const isGeotab = user?.sourceType
    ? _.kebabCase(user.sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;
  return (
    <>
      {/* <Help location="trips" /> */}
      <Meta {...metaOptions} />
      {isGeotab ? (
        <GeotabTable title={name} tableName={name} datesFiltering ViewModal={ViewTrip} />
      ) : (
        <WebPortalTable title="trips" />
      )}
    </>
  );
};

export default Trips;
