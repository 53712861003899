/* eslint-disable */
import { memo, useEffect, useMemo, useState } from 'react';
import { ChartOptions } from 'chart.js';
import MaterialCard from '@common/ui/Mui/Card/MaterialCard';
import { Button, Grid, useMediaQuery } from '@mui/material';
import {
  formatCurrency,
  formatMonth,
  formatNumberToUSStandard
} from '@pages/dashboard/common/helper';
import { Repeat } from '@assets/icons';
import { useTheme } from '@mui/material/styles';
import styles from './potential-savings.module.scss';
import usePotentialSavingsData from './hooks/usePotentialSavingsData';
import { IFtoProps } from './type';
import BarChartSkeleton from '../common/common';
import warningImage from '@assets/images/exclamation-triangle.svg'; // Import the image
import BarChart from '@common/ui/Chart/BarChart/BarChart';
import { Plugin } from 'chart.js';

const PotentialSavings = ({ width = '100%', height = '25.7rem', isParentLoading }: IFtoProps) => {
  const { potentialSavingsData, isLoading, showError, aggData, fetchPotentialSavingsData } =
    usePotentialSavingsData(isParentLoading);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md')); // 1152 px
  const isMd2 = window.innerWidth > 950 && window.innerWidth < 970; /** range between 950 to 970 */
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  const customSymbol = useMemo<Plugin<'bar'>>(() => {
    const imageCache = new Map();

    return {
      id: 'customSymbol',
      afterDraw: chart => {
        const ctx = chart.ctx;
        // Enable high-quality image scaling
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';

        if (chart.data.labels) {
          chart.data.labels.forEach((label, index) => {
            let allZero = true;
            chart.data.datasets.forEach(dataset => {
              if (dataset.data[index] !== 0) {
                allZero = false;
              }
            });

            if (allZero) {
              const meta = chart.getDatasetMeta(0); // Get meta for the first dataset to access bar coordinates
              const bar = meta.data[index];

              // Cache the image
              if (!imageCache.has(index)) {
                const image = new Image();
                image.src = warningImage; // Use the imported image
                image.onload = () => {
                  imageCache.set(index, image);
                  const x = bar.x - 20; // Adjust to center the image
                  const y = chart.scales.y.getPixelForValue(0) - 40; // Adjust to position the image above the x-axis
                  ctx.drawImage(image, x, y, 40, 40); // Adjust the size of the image
                };
              } else {
                const cachedImage = imageCache.get(index);
                const x = bar.x - 20; // Adjust to center the image
                const y = chart.scales.y.getPixelForValue(0) - 40; // Adjust to position the image above the x-axis
                ctx.drawImage(cachedImage, x, y, 40, 40); // Adjust the size of the image
              }
            }
          });
        }
      }
    };
  }, [warningImage]);

  const [chartOptions, setChartOptions] = useState<ChartOptions<'bar'>>({
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      autoPadding: true,
      padding: {
        right: 80, // lg : 130 xl : 40 md : 200 sm : 0 xs : 0
        bottom: 80 // lg : 80 xl : 80 md : 80 sm : 0 xs : 0
      }
    },

    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        reverse: true,
        align: 'center',
        labels: {
          usePointStyle: true,
          pointStyle: 'rect'
        }
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'top',
        formatter: (value, context) => {
          let dataArray: any = [];
          context.chart.data.datasets.forEach(dataset => {
            if (dataset.data[context.dataIndex]) {
              dataArray.push(dataset.data[context.dataIndex]);
            }
          });
          function totalSum(total: any, data: any) {
            return total + data;
          }
          let sum = dataArray.reduce(totalSum, 0);
          if (context.datasetIndex === 3 && Number(sum)) {
            return `$${Math.floor(sum).toLocaleString()}`;
          } else {
            return '';
          }
        }
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (context) {
            return formatMonth(context[0].label);
          },
          label: function (context) {
            const datasetLabel = context.dataset.label || '';
            const value = context.raw !== undefined ? context.raw : 0;
            let data: any = context.dataset;
            let instance_count = data.instanceCount[context.dataIndex];

            const Amount = `$${Math.floor(
              Number(value)
            ).toLocaleString()} (${instance_count.toLocaleString()})`;
            return [datasetLabel, Amount];
          }
        }
      }
    },
    scales: {
      y: {
        stacked: true,
        title: {
          display: false
        },
        min: 0,
        ticks: {
          display: true,
          font: {
            weight: 'bold'
          },
          callback: (value: string | number) => {
            if (typeof value === 'number') {
              if (value >= 100000) {
                return `$${(value / 1000).toLocaleString()}K`;
              }
              return `$${value.toLocaleString()}`;
            }
            return value;
          }
        }
      },
      x: {
        stacked: true,
        grid: {
          display: false
        },
        ticks: {
          font: {
            weight: 'bold'
          }
        }
      }
    }
  });

  useEffect(() => {
    let newPaddingRight = 10;
    let newPaddingBottom = 20;

    switch (true) {
      case isXs:
      case isSm:
        newPaddingRight = 5;
        newPaddingBottom = 0;
        break;
      case !isMd2 && isMd:
        newPaddingRight = 10;
        newPaddingBottom = 20;
        break;
      case isMd2:
        newPaddingRight = 15;
        newPaddingBottom = 20;
        break;
      case isLg:
        newPaddingRight = 20;
        break;
      case isXl:
        newPaddingRight = 20;
        break;
      default:
        newPaddingRight = 20;
        break;
    }

    setChartOptions(prevOptions => ({
      ...prevOptions,
      layout: {
        ...prevOptions.layout,
        padding: {
          right: newPaddingRight,
          bottom: newPaddingBottom
        }
      }
    }));
  }, [isXs, isSm, isMd, isMd2, isLg, isXl]);
  return (
    <MaterialCard
      title="Potential Savings"
      minHeight="60vw"
      cardHeight="100%"
      noGrid
      helpLocation="potential_savings"
      hasBorder
    >
      {isLoading && (
        <>
          <BarChartSkeleton />
        </>
      )}
      {!isLoading && !showError && potentialSavingsData && (
        <Grid container>
          <Grid container className={styles.gridContainerTop}>
            <p style={{ margin: 0 }}>
              <span className={styles.totalText}>12-month</span>
              <span className={styles.totalTextvalue}>
                {formatCurrency(aggData.totalAmount.toString(), true)}
              </span>
              <span className={styles.totalTolls}>
                ({formatNumberToUSStandard(aggData.totalTolls)})
              </span>
            </p>
          </Grid>
          <Grid container className={styles.gridContainer}>
            <p style={{ margin: 0 }}>
              <span className={styles.totalText}>Lifetime</span>
              <span className={styles.totalTextvalue}>
                {formatCurrency((aggData.grandTotalAmount ?? 0).toString(), true)}
              </span>
              <span className={styles.totalTolls}>
                ({formatNumberToUSStandard(aggData.grandTotalCount ?? 0)})
              </span>
            </p>
          </Grid>
          <Grid container xs={12} sm={12} md={12} lg={12} className={styles.barChart}>
            <BarChart
              data={potentialSavingsData}
              width="100%"
              options={chartOptions}
              plugins={[customSymbol]}
            />
          </Grid>
        </Grid>
      )}
      {!isLoading && (showError || !potentialSavingsData) && (
        <div className={styles.error}>
          Something went wrong!
          <div className={styles.retry}>
            <Button
              size="small"
              variant="contained"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={fetchPotentialSavingsData}
              startIcon={<Repeat className={styles.icon} />}
            >
              Retry
            </Button>
          </div>
        </div>
      )}
    </MaterialCard>
  );
};

export default memo(PotentialSavings);
