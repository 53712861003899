/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect, useRef } from 'react';
import { ScriptableLineSegmentContext } from 'chart.js';
import moment from 'moment-timezone';
import { formatMiles } from '@pages/dashboard/common/helper';
import api from '@api/services/common';
import { ChartEvent } from 'chart.js/dist/core/core.plugins';
import { ActiveElement } from 'chart.js/dist/plugins/plugin.tooltip';
import { IAggData, IApiResponse, ILatestMonthAgg, ILineChartData, ITotalDistData } from '../type';

const useTotalDistanceData = (
  isParentLoading: boolean
): {
  totalDistanceData: ILineChartData | undefined;
  isLoading: boolean;
  showError: boolean;
  aggData: IAggData;
  fetchTotalDistAggData: () => void;
  handleBarChartClick: (event: ChartEvent, elements: ActiveElement[]) => void;
  selectedIndex: number | null;
} => {
  const [totalDistanceData, setTotalDistanceData] = useState<ILineChartData>();
  const totalDistRes = useRef<ITotalDistData[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [aggData, setAggData] = useState<IAggData>({
    totalTolls: 0,
    totalMiles: '0',
    trips: [],
    distance: []
  });
  const [showError, setShowError] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const highlightLastLine = (
    ctx: ScriptableLineSegmentContext,
    value: string,
    lineChartCoords?: {
      from: number;
      to: number;
    }
  ) => {
    const coordsToBeCompared = {
      from: lineChartCoords?.from !== undefined ? lineChartCoords.from : 3,
      to: lineChartCoords?.to !== undefined ? lineChartCoords.to : 3
    };
    if (coordsToBeCompared.from === 0 && coordsToBeCompared.to === 0) {
      return undefined;
    }
    return ctx.p0DataIndex === coordsToBeCompared.from && ctx.p1DataIndex === coordsToBeCompared.to
      ? value
      : undefined;
  };
  const getLatestMonthAggregate = (data: ITotalDistData[]): ILatestMonthAgg => {
    const latestEntry = data.reduce((prev, current) =>
      new Date(current.monthdate) > new Date(prev.monthdate) ? current : prev
    );

    const latestMonth = moment(latestEntry.monthdate).format('MMMM YYYY');

    return {
      month: latestMonth,
      totalTolls: parseInt(latestEntry.trip_count, 10),
      totalMiles: formatMiles(latestEntry.distance_travelled_in_miles),
      trips: data.map(item => Math.floor(parseFloat(item.trip_count))),
      distance: data.map(item => Math.floor(parseFloat(item.distance_travelled_in_miles))),
      months: data.map(item => item.monthdate),
      lastMonth: latestMonth
    };
  };

  const transformDataForLineChart = (
    data: ITotalDistData[],
    selectedCoords?: {
      from: number;
      to: number;
    }
  ) => {
    const sortedData = data
      .slice()
      .sort((a, b) => new Date(a.monthdate).getTime() - new Date(b.monthdate).getTime());

    const labels = sortedData.map(item => moment(item.monthdate).format('MMMM'));
    const chartData: ILineChartData = {
      labels,
      datasets: [
        {
          label: 'Total Distance Travelled',
          data: sortedData.map(item => Math.floor(parseFloat(item.distance_travelled_in_miles))),
          borderColor: sortedData.map((_, index, array) => {
            return index === array.length - 1 ? '#FFBF66' : '#4285F4';
          }),
          segment: {
            borderColor: (ctx: ScriptableLineSegmentContext) =>
              highlightLastLine(ctx, '#FFBF66', selectedCoords)
          },
          backgroundColor: sortedData.map((_, index, array) => {
            return index === array.length - 1 ? '#FFBF66' : '#6D9EEB';
          })
        }
      ]
    };
    const lastMonthAgg = getLatestMonthAggregate(data);
    aggData.totalTolls = lastMonthAgg.totalTolls;
    aggData.totalMiles = lastMonthAgg.totalMiles;
    aggData.trips = lastMonthAgg.trips;
    aggData.distance = lastMonthAgg.distance;
    aggData.months = lastMonthAgg.months;
    aggData.lastMonth = lastMonthAgg.lastMonth;
    setAggData({ ...aggData });
    setTotalDistanceData(chartData);
  };

  const fetchTotalDistAggData = async (): Promise<void> => {
    try {
      setIsLoading(true);
      setShowError(false);
      const response: IApiResponse = await api.post('/dashboard/total-distance-traveled', {
        moduleId: 'INSIGHTS'
      });
      if (response.isSuccess && response.data) {
        totalDistRes.current = response.data;
        transformDataForLineChart(response.data);
      } else {
        setShowError(true);
      }
    } catch (err) {
      console.log('Err in calling total distance travelled hook ==> ', err);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBarChartClick = (event: ChartEvent, elements: ActiveElement[]) => {
    if (elements.length > 0) {
      const dataIndex = elements[0].index;
      const trips = (aggData.trips && aggData.trips[dataIndex]) || 0;
      const distance = (aggData.distance && aggData.distance[dataIndex]) || 0;
      const selectedMonth = (aggData.months && aggData.months[dataIndex]) || '';

      if (dataIndex >= 0 && totalDistRes.current) {
        const lineChartCoords = {
          from: dataIndex,
          to: dataIndex + 1
        };
        transformDataForLineChart(totalDistRes.current, lineChartCoords);
      }
      setAggData(prevAggData => ({
        ...prevAggData,
        totalTolls: trips,
        totalMiles: formatMiles(distance.toString()),
        lastMonth: selectedMonth
      }));

      setSelectedIndex(dataIndex);

      setTotalDistanceData(prevTollData => {
        if (!prevTollData) {
          return prevTollData;
        }

        return {
          ...prevTollData,
          datasets: prevTollData.datasets.map(dataset => ({
            ...dataset,
            backgroundColor: dataset.backgroundColor.map((color, i) =>
              i === dataIndex ? '#FFBF66' : '#6D9EEB'
            )
            // borderColor: dataset.borderColor.map((color, i) =>
            //   i === dataIndex ? '#FFBF66' : '#6D9EEB'
            // )
          })),
          labels: prevTollData.labels
        };
      });
    }
  };

  useEffect(() => {
    if (!isParentLoading) {
      fetchTotalDistAggData().catch(err => {
        console.log('Err in calling total distance travelled hook ==> ', err);
      });
    }
  }, []);

  return {
    totalDistanceData,
    isLoading,
    showError,
    aggData,
    fetchTotalDistAggData,
    handleBarChartClick,
    selectedIndex
  };
};

export default useTotalDistanceData;
