/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { useEffect, useState } from 'react';
import api from '@api/services/common';
import { useAppSelector } from '@store/hooks';
import moment from 'moment-timezone';
import { IAccOverviewApiResponse, IAccOverviewDateRange, ICategoryCard } from '../type';
import { ILineChartData, ITollBillHistory } from '../toll-bill-history/type';

const useAccountOverviewData = (): {
  dateRange: IAccOverviewDateRange;
  accOvrviewData: ICategoryCard[];
  isLoading: boolean;
  showError: boolean;
  tollBillHistoryData: ILineChartData | undefined;
  fetchAccountOverviewData: () => Promise<void>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const customerId = useAppSelector(state => state.customer.id);
  const [dateRange, setDateRange] = useState<IAccOverviewDateRange>({
    fromDate: '',
    toDate: '',
    lastUpdatedDate: ''
  });
  const [accOvrviewData, setAccOvrviewData] = useState<ICategoryCard[]>([]);
  const [tollBillHistoryData, setTollBillHistoryData] = useState<ILineChartData>();

  const transformDataForLineChart = (data: ITollBillHistory[]) => {
    const sortedData = data
      .slice()
      .sort((a, b) => new Date(a.monthdate).getTime() - new Date(b.monthdate).getTime());

    const monthLabels = sortedData.map(x => moment(x.monthdate).format('MMMM'));
    // eslint-disable-next-line
    const formatDatasetData = (value: any): number => {
      const parsedValue = parseFloat(value);
      const finalValue = Number.isNaN(parsedValue) ? 0 : parseFloat(parsedValue.toFixed(2));
      return finalValue;
    };
    const datasets: ILineChartData['datasets'] = [
      {
        label: 'Pre tollogic',
        data: sortedData.map(item => formatDatasetData(item.pre_toll_logic_sum)),
        borderColor: '#C00',
        backgroundColor: '#C00',
        borderWidth: 2.5,
        borderDash: [10, 5],
        pointRadius: 0
      },
      {
        label: 'Actual',
        data: sortedData.map(item => formatDatasetData(item.actual_sum)),
        borderColor: '#000',
        backgroundColor: '#000',
        borderWidth: 2,
        borderDash: [0, 0],
        pointRadius: 0
      },
      {
        label: 'Calculated',
        data: sortedData.map(item => formatDatasetData(item.managed_sum)),
        borderColor: '#6D9EEB',
        backgroundColor: '#6D9EEB',
        borderWidth: 3.5,
        borderDash: [2, 2],
        pointRadius: 0
      }
      // {
      //   label: 'Alternative',
      //   data: sortedData.map(item => formatDatasetData(item.alternative_sum)),
      //   borderColor: '#F6B26B',
      //   backgroundColor: '#F6B26B',
      //   borderWidth: 3.5,
      //   borderDash: [2, 2],
      //   pointRadius: 0
      // },
      // {
      //   label: 'Optimal',
      //   data: sortedData.map(item => formatDatasetData(item.optimal_sum)),
      //   borderColor: 'green',
      //   backgroundColor: '#93C47D',
      //   borderWidth: 3.5,
      //   borderDash: [2, 2],
      //   pointRadius: 0
      // }
    ];
    const sampleData: ILineChartData = {
      labels: monthLabels,
      datasets
    };
    setTollBillHistoryData(sampleData);
  };

  const fetchAccountOverviewData = async (): Promise<void> => {
    try {
      setIsLoading(true);
      setShowError(false);
      const response: IAccOverviewApiResponse = await api.post('/dashboard/account-overview', {
        moduleId: 'INSIGHTS'
      });
      const checkIsGained = (type: 0 | 1 | 2 | 3, value: string | number) => {
        const valueToBeCheckd = Number(value);
        if (Number.isNaN(valueToBeCheckd)) {
          return false;
        }
        if (valueToBeCheckd === 0) {
          return true;
        }
        if (type === 0) {
          return valueToBeCheckd >= 0;
        }
        return !(valueToBeCheckd >= 0);
      };
      if (response && response.isSuccess && response.data) {
        const modCategories: ICategoryCard[] = [];
        response.data.accountOverview.forEach(item => {
          const categoryData: ICategoryCard = {
            type: item.type,
            categoryName: item.title,
            amount: {
              value: !item.amount || Number.isNaN(item.amount) ? '0' : item.amount.toString(),
              isGained: checkIsGained(item.type, item.amount)
            },
            currentMonth: item.current_month_name,
            momAmt: {
              value:
                !item.momAmount || Number.isNaN(item.momAmount) ? '0' : item.momAmount.toString(),
              isGained: checkIsGained(item.type, item.momAmount)
            },
            previousMonth: item.previous_month_name,
            momPercent: {
              value:
                !item.momPercent || Number.isNaN(item.momPercent)
                  ? '0'
                  : item.momPercent.toString(),
              isGained: checkIsGained(item.type, item.momPercent)
            }
          };
          modCategories.push(categoryData);
        });
        transformDataForLineChart(response.data.tollBillHistory);
        setAccOvrviewData(modCategories);
        setDateRange(response.data.dateRange);
      } else {
        setShowError(true);
      }
    } catch (err) {
      console.log('Err in acc overview ==> ', err);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (customerId) {
      fetchAccountOverviewData().catch(err => {
        console.log('Error in fetch Account Overview Data data ==> ', err);
      });
    }
  }, [customerId]);
  return {
    dateRange,
    accOvrviewData,
    isLoading,
    showError,
    tollBillHistoryData,
    fetchAccountOverviewData
  };
};

export default useAccountOverviewData;
