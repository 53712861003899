/* eslint-disable */
import { TransactionDataInterface } from '@common/types/data';
import { RootState } from '@store/types';
import _ from 'lodash';
import { routes } from '@common/utils/route-names';
import { store } from '@store/index';
import { SessionLogOut } from '@api/config/sessionTimeOutCheck';
import { baseApi, GPS_TOLLS_API, TRANSACTIONS_API } from '../config';
import { BEResponse } from '../types';

export const transactionsApi = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Transactions', 'Transaction']
  })
  .injectEndpoints({
    endpoints: builder => ({
      getTransactions: builder.query<
        BEResponse<TransactionDataInterface>,
        [string, number, number]
      >({
        queryFn: async (
          [customer_id, page_index, page_size],
          _queryApi,
          _extraOptions,
          fetchWithBQ
        ) => {
          const getTransactions = await fetchWithBQ({
            url: TRANSACTIONS_API,
            params: { 'customer-id': customer_id, 'page-index': page_index, 'page-size': page_size }
          });
          if (getTransactions.meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          }
          if (getTransactions.error) {
            return { error: getTransactions.error };
          }
          const transactionsRes = getTransactions.data as BEResponse<TransactionDataInterface>;
          const transactions = transactionsRes.pageContent.map(transaction => ({
            ...transaction,
            id: transaction.tollBillId,
            toll: transaction.tollAmount ? transaction.tollAmount : 0,
            tagTransponder: transaction.tagNumber
          }));
          return { data: { ...transactionsRes, pageContent: transactions } };
        },
        providesTags: result =>
          result
            ? [
                ...result.pageContent.map(({ id }) => ({ type: 'Transactions' as const, id })),
                { type: 'Transactions', id: 'LIST' }
              ]
            : [{ type: 'Transactions', id: 'LIST' }]
      }),
      searchTransactions: builder.mutation<BEResponse<TransactionDataInterface>, unknown>({
        queryFn: async (body: any, _queryApi, _extraOptions, fetchWithBQ) => {
          const isGeotab = (_queryApi.getState() as RootState).user?.sourceType
            ? _.kebabCase((_queryApi.getState() as RootState).user.sourceType) ===
              _.kebabCase('GEOTAB')
            : window.location !== window.parent.location;
          const isMobileView = window.matchMedia('(max-width: 480px)').matches;

          const getTransactions = await fetchWithBQ({
            url: body.isActualTollTab
              ? `${TRANSACTIONS_API}/${isGeotab && !isMobileView ? 'searchAll' : 'search'}`
              : `${GPS_TOLLS_API}`,
            method: 'post',
            body
          });
          if (getTransactions.meta?.response?.status === 401) {
            SessionLogOut(isGeotab);
          }
          if (getTransactions.meta?.response?.status === 403) {
            window.location.assign(`${process.env.REACT_APP_SOURCE_URL}${routes.noPermission}`);
          }
          if (getTransactions.error) {
            return { error: getTransactions.error };
          }
          const transactionsRes = getTransactions.data as BEResponse<TransactionDataInterface>;
          let transactions: any;
          if (body.isActualTollTab) {
            transactions = transactionsRes.pageContent.map(transaction => ({
              ...transaction,
              id: transaction.tollBillId,
              toll: transaction.tollAmount ? transaction.tollAmount : 0,
              date: transaction.timeZoneConvertedDate,
              tagTransponder: transaction.tagNumber,
              tripId: transaction?.tripId ? transaction?.tripId : null,
              feeType: transaction?.feeType
                ? transaction.feeType === 'NONE'
                  ? null
                  : transaction?.feeType
                : null
            }));
          } else {
            transactions = transactionsRes.pageContent.map(transaction => ({
              ...transaction,
              id: transaction.trip_toll_details_id,
              calculatedAmount: transaction.calculated_amount ? transaction.calculated_amount : 0,
              tollAgency: transaction.toll_agency,
              licensePlate: transaction.license_plate,
              tagNumber: transaction.tag_number,
              tagTransponder: transaction.tag_number,
              tripId: transaction.trip_id
            }));
          }
          return { data: { ...transactionsRes, pageContent: transactions } };
        }
        // invalidatesTags: [{ type: 'Transactions', id: 'LIST' }]
      }),
      getSingleTransaction: builder.query<TransactionDataInterface, string>({
        query: toll_bill_id => `${TRANSACTIONS_API}/${toll_bill_id}`,
        providesTags: ['Transaction'],
        transformResponse(baseQueryReturnValue: any, meta, arg) {
          if (meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          } else {
            return baseQueryReturnValue;
          }
        }
      }),
      uploadTransaction: builder.mutation<unknown, [FormData, string, string]>({
        queryFn: async (
          [body, customer_id, statement_type],
          _queryApi,
          _extraOptions,
          fetchWithBQ
        ) => {
          const uploadResult = await fetchWithBQ({
            url: `${TRANSACTIONS_API}/file`,
            params: { 'customer-id': customer_id, 'statement-type': statement_type || 'ntta' },
            method: 'post',
            body,
            headers: {
              'Content-type': 'file'
            },
            responseHandler: response => response.json()
          });
          if (uploadResult.meta?.response?.status === 401) {
            const isGeotab = store.getState().user?.sourceType
              ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
              : window.location !== window.parent.location;
            SessionLogOut(isGeotab);
          }
          if (uploadResult.error) {
            return { error: uploadResult.error };
          }
          const matchResult = await fetchWithBQ({
            url: `${TRANSACTIONS_API}/match`,
            params: {
              'customer-id': customer_id,
              'statement-id': (uploadResult.data as { statementId: string }).statementId
            },
            method: 'post',
            headers: {
              'Content-type': 'application/json'
            }
          });
          if (matchResult.error) {
            return { error: matchResult.error };
          }
          return { data: matchResult.data };
        },
        invalidatesTags: [{ type: 'Transactions', id: 'LIST' }]
      }),
      deleteTransaction: builder.mutation<unknown, string>({
        query: toll_bill_id => ({
          url: `${TRANSACTIONS_API}/${toll_bill_id}`,
          method: 'delete',
          headers: {
            'Content-type': 'application/json'
          }
        }),
        invalidatesTags: [{ type: 'Transactions', id: 'LIST' }, 'Transaction']
      }),
      deleteAllTransactions: builder.mutation<unknown, string>({
        query: customer_id => ({
          url: TRANSACTIONS_API,
          params: { 'customer-id': customer_id },
          method: 'delete',
          headers: {
            'Content-type': 'application/json'
          }
        }),
        invalidatesTags: [{ type: 'Transactions', id: 'LIST' }, 'Transaction']
      })
    })
  });

export const {
  useGetTransactionsQuery,
  useSearchTransactionsMutation,
  useUploadTransactionMutation,
  useDeleteTransactionMutation,
  useDeleteAllTransactionsMutation
} = transactionsApi;
