/* eslint-disable */
import Dashboard from '@pages/dashboard';
import NewDashboard from '@pages/dashboard/new/Dashboard';
import InsightDashboard from "@pages/dashboard/newInsights/index"
import { Fleet, Customers, Trips, Transactions, TollDocuments, Roles, Users } from '@pages/tables';
import QuicksightMap from '@pages/quicksight';
import Settings from '@pages/settings';
import Notifications from '@pages/notifications';
import { routes } from '@common/utils/route-names';
import { Route } from 'react-router-dom';
import { MainLayout, SecondaryLayout } from '@app/layouts';
import AdditionalServices from '@pages/additionalServices';
import ServiceAgreement from '@pages/geotab/components/serviceAgreement';
import ProtectedRoute from '../protected/Protected';

const MainRoutes = () => {
  return (
    <Route element={<ProtectedRoute />}>
      {/* //? start: routes for ALL users */}
      <Route element={<MainLayout />}>
        <Route path={routes.dashboard} element={<Dashboard />} />
        <Route path={routes.newDashboard} element={<InsightDashboard />} />
        <Route path={routes.settings} element={<Settings />} />
        <Route path={routes.notifications} element={<Notifications />} />
      </Route>
      <Route path={routes.quicksight_map} element={<QuicksightMap />} />
      {/* //? end: routes for ALL users */}

      {/* //? start: routes for VIEWERS, SUPERVISORS, CUSTOMER ADMINS and SUPER ADMINS */}
      <Route element={<ProtectedRoute />}>
        <Route element={<MainLayout />}>
          <Route path={routes.trips} element={<Trips />} />
          <Route path={routes.tripDetail} element={<Trips />} />
          <Route path={routes.fleet} element={<Fleet />} />
          {/* <Route path={routes.profile} element={<ProfileView />} /> */}
          <Route path={routes.transactions} element={<Transactions />} />
          <Route path={routes.toll_documents} element={<TollDocuments />} />
        </Route>
      </Route>
      {/* //? end: routes for VIEWERS, SUPERVISORS, CUSTOMER ADMINS and SUPER ADMINS */}

      {/* //? start: routes for only SUPERVISORS, CUSTOMER ADMINS and SUPER ADMINS */}
      <Route element={<ProtectedRoute />}>
        <Route element={<MainLayout />}>
          <Route path={routes.additional_services} element={<AdditionalServices />} />
          <Route path={routes.roles} element={<Roles />} />
        </Route>
      </Route>
      {/* //? end: routes for only SUPERVISORS, CUSTOMER ADMINS and SUPER ADMINS */}

      {/* //? start: routes for only SUPER ADMINS */}
      <Route element={<ProtectedRoute />}>
        <Route element={<MainLayout />}>
          <Route path={routes.customers} element={<Customers />} />
        </Route>
      </Route>
      {/* //? end: routes for only SUPER ADMINS */}

      <Route element={<SecondaryLayout />}>
        <Route path={routes.service_agreement} element={<ServiceAgreement />} />
      </Route>
    </Route>
  );
};

export default MainRoutes;
