/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import api from '@api/services/common';
import { ChartEvent } from 'chart.js/dist/core/core.plugins';
import { ActiveElement } from 'chart.js/dist/plugins/plugin.tooltip';
import {
  IAggData,
  IApiResponse,
  IBarChartData,
  IDuplicateTollData,
  ILatestMonthAgg
} from '../type';

const useDuplicateTollData = (
  isParentLoading: boolean
): {
  duplicateTollData: IBarChartData | undefined;
  isLoading: boolean;
  showError: boolean;
  aggData: IAggData;
  fetchDupTollAggData: () => Promise<void>;
  handleBarChatClick: (event: ChartEvent, elements: ActiveElement[]) => void;
} => {
  const [duplicateTollData, setDuplicateTollData] = useState<IBarChartData>();
  const [isLoading, setIsLoading] = useState(false);
  const [aggData, setAggData] = useState<IAggData>({
    totalTolls: 0,
    total: 0,
    duplicates: [],
    amount: [],
    months: []
  });
  const [showError, setShowError] = useState(false);

  const getLatestMonthAggregate = (data: IDuplicateTollData[]): ILatestMonthAgg => {
    const latestEntry = data.reduce(
      (prev, current) => (new Date(current.monthdate) > new Date(prev.monthdate) ? current : prev),
      data[0]
    );

    const latestMonth = moment(latestEntry.monthdate).format('MMMM YYYY');

    const totalTolls = Number(latestEntry.duplicate_count);

    const totalAmount = Math.floor(Number(latestEntry.duplicate_total));
    return {
      month: latestMonth,
      totalTolls,
      totalAmount,
      duplicates: data.map(item => Math.floor(parseFloat(item.duplicate_count))),
      amount: data.map(item => Math.floor(parseFloat(item.duplicate_total))),
      months: data.map(item => item.monthdate),
      lastMonth: latestMonth
    };
  };

  const transformDataForBarChart = (data: IDuplicateTollData[]) => {
    const sortedData = data
      .slice()
      .sort((a, b) => new Date(a.monthdate).getTime() - new Date(b.monthdate).getTime());

    const labels = sortedData.map(item => moment(item.monthdate).format('MMMM'));
    const chartData: IBarChartData = {
      labels,
      datasets: [
        {
          label: 'Plate Tolls',
          data: sortedData.map(item => parseFloat(item.duplicate_total)),
          backgroundColor: sortedData.map((_, index, array) => {
            return index === array.length - 1 ? '#FFBF66' : '#6D9EEB';
          })
        }
      ]
    };
    const lastMonthAgg = getLatestMonthAggregate(data);
    aggData.totalTolls = lastMonthAgg.totalTolls;
    aggData.total = lastMonthAgg.totalAmount;
    aggData.duplicates = lastMonthAgg.duplicates;
    aggData.amount = lastMonthAgg.amount;
    aggData.months = lastMonthAgg.months;
    aggData.lastMonth = lastMonthAgg.lastMonth;
    setAggData({ ...aggData });
    setDuplicateTollData(chartData);
  };

  const fetchDupTollAggData = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const response: IApiResponse = await api.post('/dashboard/duplicate-tolls', {
        moduleId: 'INSIGHTS'
      });
      setShowError(false);
      if (response && response.isSuccess && response.data) {
        transformDataForBarChart(response.data);
      } else {
        setShowError(true);
      }
    } catch (err) {
      console.log('Err in calling duplicate tolls ==> ', err);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBarChatClick = (event: ChartEvent, elements: ActiveElement[]) => {
    if (elements.length > 0) {
      const dataIndex = elements[0].index;
      const toll = (aggData.duplicates && aggData.duplicates[dataIndex]) || 0;
      const amount = (aggData.amount && aggData.amount[dataIndex]) || 0;
      const selectedMonth = (aggData.months && aggData.months[dataIndex]) || '';

      setAggData(prevAggData => ({
        ...prevAggData,
        totalTolls: toll,
        total: amount,
        lastMonth: selectedMonth
      }));

      setDuplicateTollData(prevTollData => {
        if (!prevTollData) {
          return prevTollData;
        }

        return {
          ...prevTollData,
          datasets: prevTollData.datasets.map(dataset => ({
            ...dataset,
            backgroundColor: dataset.backgroundColor.map((color, i) =>
              i === dataIndex ? '#FFBF66' : '#6D9EEB'
            )
          })),
          labels: prevTollData.labels
        };
      });
    }
  };

  useEffect(() => {
    if (!isParentLoading) {
      fetchDupTollAggData().catch(err => {
        console.log('Error in duplicate tolls data ==> ', err);
      });
    }
  }, []);

  return {
    duplicateTollData,
    isLoading,
    showError,
    aggData,
    fetchDupTollAggData,
    handleBarChatClick
  };
};

export default useDuplicateTollData;
