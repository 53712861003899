/* eslint-disable */
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { AUTH_BE_URL } from '@api/config';
import { IGeotabUserProgress } from '../types/user-progress';

export const useSaveUserProgress = () => {
  const { userName, database, sessionId } = useParams();

  const handleSaveUserProgress = async (progressId: number | null, values: IGeotabUserProgress) => {
    try {
      const body = {
        id: progressId,
        geotab_databasename: database,
        geotab_sessionid: sessionId,
        user_name: userName,
        onboarding_json: values
      };
      const response = (await axios.post(`${AUTH_BE_URL}/onboarding-session`, body)).data;
      return response;
    } catch (err) {
      console.log('Err in saving user progress ==> ', err);
    }
  };

  return { handleSaveUserProgress };
};
