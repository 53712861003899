import Company from '../../company';
import Vehicles from '../../vehicles/Vehicles';
import ThankYou from '../../thank-you/ThankYou';
import { tabs } from '../../../utils/tabs';
import styles from './body.module.scss';
import { useSSOContext } from '../../../context/SSOContext';
import Header from '../header/Header';
import Eula from '../../eula/Eula';
import OverView from '../../overview';

const Body = () => {
  const { isCustomerNew, activeTabIndex, gSubscriptionStatus } = useSSOContext();
  return (
    <div className={styles.container}>
      <Header />
      {isCustomerNew ? (
        <>
          {tabs[activeTabIndex].value === 'step1' && <OverView />}
          {tabs[activeTabIndex].value === 'step2' && <Eula />}
          {tabs[activeTabIndex].value === 'step3' && <Company />}
          {tabs[activeTabIndex].value === 'step4' && <Vehicles />}
          {tabs[activeTabIndex].value === 'step5' && <ThankYou />}
        </>
      ) : (
        <>
          {gSubscriptionStatus ? (
            <>
              {tabs[activeTabIndex].value === 'step1' && <Company />}
              {tabs[activeTabIndex].value === 'step2' && <ThankYou />}
            </>
          ) : (
            <ThankYou />
          )}
        </>
      )}
    </div>
  );
};

export default Body;
