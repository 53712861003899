import LineChart from '@common/ui/Chart/LineChart/LineChart';
import { useState } from 'react';
import { ChartOptions } from 'chart.js';
import { Button, Skeleton } from '@mui/material';
import { formatCurrency } from '@pages/dashboard/common/helper';
import { Repeat } from '@assets/icons';
import styles from './tbh.module.scss';
import { ITollBillHistoryProps } from './type';

const TollBillHistory = ({
  tollBillHistoryData,
  isLoading,
  showError,
  fetchAccountOverviewData
}: ITollBillHistoryProps) => {
  const [lineChartOptions] = useState<ChartOptions<'line'>>({
    layout: {
      autoPadding: true
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxHeight: 1,
          boxWidth: 40,
          font: {
            weight: 'bold'
          }
        }
      },
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            let label = (tooltipItem.raw as string) || '';

            if (label) {
              if (Number.isNaN(Number(label))) {
                return tooltipItem.formattedValue || '';
              }
              label = formatCurrency(label);
            }
            return label;
          }
        }
      },
      datalabels: {
        display: false
      }
    },
    scales: {
      x: {
        grid: {
          display: true,
          drawTicks: false
        },
        ticks: {
          font: {
            weight: 'bold'
          },
          padding: 10
        }
      },
      y: {
        grid: {
          display: true,
          drawTicks: false
        },
        ticks: {
          font: {
            weight: 'bold'
          },
          padding: 10,
          callback: (value: string | number) => {
            if (typeof value === 'number') {
              return formatCurrency(value.toString(), true);
            }
            return value;
          }
        }
      }
    }
  });
  return (
    <div className={styles.container}>
      <div className={styles.title}>Toll Bill History</div>
      {isLoading && (
        <div className={styles.loader}>
          <div>
            <Skeleton height="25rem" width="100%" variant="rectangular" />
          </div>
          <div className={styles['legend-loader']}>
            <Skeleton height="4rem" style={{ marginTop: '1rem' }} variant="rectangular" />
          </div>
        </div>
      )}
      {!isLoading && !showError && tollBillHistoryData && (
        <LineChart data={tollBillHistoryData} height="60%" options={lineChartOptions} />
      )}
      {!isLoading && showError && !tollBillHistoryData && (
        <div className={styles.error}>
          Something went wrong!
          <div className={styles.retry}>
            <Button
              size="small"
              variant="contained"
              onClick={fetchAccountOverviewData}
              startIcon={<Repeat className={styles.icon} />}
            >
              Retry
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TollBillHistory;
