import MaterialAccordion from '@common/ui/Mui/Accordion/MaterialAccordion';
import { TollID, Truck, Dollar, GMap, Invoice, Location } from '@assets/icons';
import { formatCurrency, formatPercentage } from '@pages/dashboard/common/helper';
import { Chip } from '@mui/material';
import MaterialCard from '@common/ui/Mui/Card/MaterialCard';
import moment from 'moment-timezone';
import Skeleton from '@mui/material/Skeleton';
import { useState } from 'react';
import { Title } from './types';
import styles from './savings.module.scss';
import Category from './category/Category';
import { useGetSOData } from './hooks';

const SavingsOpportunities = () => {
  const { data, loader, dateRange, handleActiveTab } = useGetSOData();
  const [openAccordionId, setOpenAccordionId] = useState<string | null>(null);
  const showTodayDateForTo = false;
  const totalAmountSum = data.reduce((sum, item) => {
    const amount = parseFloat(item.savings);

    if (!Number.isNaN(amount)) {
      return sum + amount;
    }
    return sum;
  }, 0);

  const findChipStatusColor = (status: string) => {
    switch (status) {
      case 'Great':
        return {
          backgroundColor: '#EBF9EE',
          color: '#026c1d',
          border: '#026c1d'
        };
      case 'Fair':
        return {
          backgroundColor: '#FFF4E6',
          color: '#c87605',
          border: '#c87605'
        };
      case 'Bad':
        return {
          backgroundColor: '#FFE8E7',
          color: '#ff0e00',
          border: '#ff0e00'
        };
      default:
        return {
          backgroundColor: '#757575',
          color: '#ffffff',
          border: '#ffffff'
        };
    }
  };

  const getPerStyle = (sign: string) => {
    switch (sign) {
      case '-':
        return {
          color: 'red'
        };
      case '+':
        return {
          color: 'green'
        };
      default:
        return {
          color: 'black'
        };
    }
  };

  const getIconByCategory = (value: string): JSX.Element => {
    const category = value.toUpperCase();
    switch (category) {
      case Title.POTENTIALMISUSE:
        return <Location className={styles.icon} />;
      case Title.ASSETSTOLLTRANSPONDERS:
        return <Truck className={styles.icon} />;
      case Title.DISCOUNTS:
        return <Dollar className={styles.icon} />;
      case Title.TRIPS:
        return <GMap className={styles.icon} />;
      case Title.VIOLATIONS:
        return <Invoice className={styles.icon} />;
      default:
        return <TollID className={styles.icon} />;
    }
  };

  const addSign = (str: string) => {
    const number = Number(str);

    if (Number.isNaN(number)) {
      return '';
    }

    if (number > 0) {
      return '+';
    }

    if (number < 0) {
      return '-';
    }

    return '';
  };

  return (
    <div className={styles.container}>
      <MaterialCard
        title="Unrealized Savings Opportunities"
        helpLocation="dashboard_1"
        hasBorder
        subTitle={
          <>
            {loader ? (
              <Skeleton width="30%" variant="rectangular" />
            ) : (
              <p className={styles['updated-by']}>
                Last updated {moment.utc(dateRange.last_updated_date).format('LL')}
              </p>
            )}
            {loader ? (
              <Skeleton style={{ marginTop: '1rem' }} width="40%" variant="rectangular" />
            ) : (
              <p>
                Showing {moment.utc(dateRange.start_date).format('LL')} to{' '}
                {showTodayDateForTo ? (
                  <>{moment.utc(new Date()).format('LL')}</>
                ) : (
                  <>{moment.utc(dateRange.end_date).format('LL')}</>
                )}
              </p>
            )}
          </>
        }
        noGrid
        cardHeight="100%"
      >
        {loader ? (
          <Skeleton style={{ marginRight: '25rem', marginLeft: '50rem' }} variant="rectangular" />
        ) : (
          <p className={styles['potential-savings']}>
            Total Potential Savings{' '}
            <span className={styles.value}>{formatCurrency(totalAmountSum.toString())}</span>
          </p>
        )}

        <div className={styles.content}>
          {loader ? (
            [1, 2, 3, 4, 5, 6].map(() => (
              <Skeleton
                style={{ marginTop: '1rem' }}
                height="4rem"
                width="100%"
                variant="rectangular"
              />
            ))
          ) : (
            <>
              {data.map(item => (
                <MaterialAccordion
                  key={item.id}
                  height="5rem"
                  elevation={0}
                  onClick={(evt, value) => {
                    setOpenAccordionId(value ? item.id : null);
                    handleActiveTab(item.id, value);
                  }}
                  isOpen={item.id === openAccordionId}
                  title={
                    <p className={styles.title}>
                      {getIconByCategory(item.category)}
                      {item.title}
                    </p>
                  }
                  summary={
                    <div className={styles.summary}>
                      <span className={styles.savings}>{formatCurrency(item.savings)}</span>
                      <span className={styles['mom-percent']}>
                        <div className={styles['mom-number']}>
                          <p
                            className={styles['mom-value']}
                            title={`${addSign(item.momInPercent)}${formatPercentage(
                              Math.round(Number(item.momInPercent.replace('-', ''))).toString()
                            )}`}
                            style={getPerStyle(addSign(item.momInPercent))}
                          >
                            {addSign(item.momInPercent)}
                            {`${formatPercentage(
                              Math.round(Number(item.momInPercent.replace('-', ''))).toString(),
                              false
                            )}`}
                            <span className={styles['mom-per']}>MoM</span>
                          </p>
                        </div>
                      </span>
                      <div className={styles['chip-wrapper']}>
                        <Chip
                          className={styles['status-chip']}
                          label={item.status}
                          variant="outlined"
                          size="small"
                          style={findChipStatusColor(item.status)}
                        />
                      </div>
                    </div>
                  }
                  iconPosition="start"
                >
                  <div>
                    {item.category === Title.TOLLTRANSACTION && (
                      <Category
                        dateRange={{ from: dateRange.start_date, to: dateRange.end_date }}
                        content={item.content}
                        url="toll-transaction"
                      />
                    )}
                    {item.category === Title.ASSETSTOLLTRANSPONDERS && (
                      <Category
                        dateRange={{ from: dateRange.start_date, to: dateRange.end_date }}
                        content={item.content}
                        url="assets-toll-transponders"
                      />
                    )}
                    {item.category === Title.TRIPS && (
                      <Category
                        dateRange={{ from: dateRange.start_date, to: dateRange.end_date }}
                        content={item.content}
                        url="trips"
                      />
                    )}
                    {item.category === Title.VIOLATIONS && (
                      <Category
                        dateRange={{ from: dateRange.start_date, to: dateRange.end_date }}
                        content={item.content}
                        url="violation"
                      />
                    )}
                    {item.category === Title.DISCOUNTS && (
                      <Category
                        dateRange={{ from: dateRange.start_date, to: dateRange.end_date }}
                        content={item.content}
                        url=""
                      />
                    )}
                    {item.category === Title.POTENTIALMISUSE && (
                      <Category
                        dateRange={{ from: dateRange.start_date, to: dateRange.end_date }}
                        content={item.content}
                        url="potential-misuse"
                      />
                    )}
                  </div>
                </MaterialAccordion>
              ))}
            </>
          )}
        </div>
      </MaterialCard>
    </div>
  );
};

export default SavingsOpportunities;
