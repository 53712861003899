/*eslint-disable*/
import { useEffect } from 'react';
import { PageHeader } from '@components/layouts/headers';
import Meta from '@components/meta';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Insights from '../insights/Insights';

const Dashboard = () => {
  useEffect(() => {
    /* Register all ChartJS components that are to be used for dashboard */
    ChartJS.register(
      ArcElement,
      CategoryScale,
      LinearScale,
      BarElement,
      PointElement,
      LineElement,
      // TreemapController,
      // TreemapElement,
      Legend,
      Tooltip,
      ChartDataLabels,
      Title
    );
  }, []);
  const metaOptions = {
    title: 'New Dashboard',
    keywords: 'NewDashboard'
  };

  return (
    <>
      <Meta {...metaOptions} />
      <PageHeader title="Dashboard (Beta)" hideButtons />
      <Insights />
    </>
  );
};

export default Dashboard;
