/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unneeded-ternary */
import { memo, useEffect, useState } from 'react';
import { ChartOptions } from 'chart.js';
import { formatCurrency, formatNumberToUSStandard } from '@pages/dashboard/common/helper';
import moment from 'moment-timezone';
import MaterialCard from '@common/ui/Mui/Card/MaterialCard';
import { Button, Grid } from '@mui/material';
import LineChart from '@common/ui/Chart/LineChart/LineChart';
import { Repeat } from '@assets/icons';
import styles from './toll-spend.module.scss';
import useTollSpendData from './hook/useTollSpendData';
import { IFtoProps } from '../type';

const TollSpend = ({ width = '100%', height = '25.7rem', isParentLoading }: IFtoProps) => {
  const {
    tollSpendData,
    isLoading,
    showError,
    aggData,
    fetchTollSpendAggData,
    handleBarChartClick,
    selectedIndex
  } = useTollSpendData(isParentLoading);

  const isXl3 = window.innerWidth > 1800;
  const isXl2 = window.innerWidth > 1700 && window.innerWidth <= 1800;
  const isXl1 = window.innerWidth > 1600 && window.innerWidth <= 1700;

  const [lineChartOptions, setChartOptions] = useState<ChartOptions<'line'>>({
    layout: {
      autoPadding: true,
      padding: {
        bottom: 10
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            const dataIndex = tooltipItem.dataIndex || 0;

            const tolls = aggData?.tolls?.[dataIndex] ?? 0;
            const amount = aggData?.amount?.[dataIndex] ?? 0;

            const Amount = ` Amount: $${formatNumberToUSStandard(amount)}`;
            const Toll = ` Toll Count: ${formatNumberToUSStandard(tolls)}`;

            return [Toll, Amount];
          }
        }
      },
      datalabels: {
        display: false
      }
    },
    scales: {
      y: {
        ticks: {
          font: {
            weight: 'bold'
          },
          callback: (value: string | number) => {
            if (typeof value === 'number') {
              if (value >= 1000) {
                return `$${(value / 1000).toLocaleString()}k`;
              }
              return `$${value}`;
            }
            return value; // return original value for non-numeric values
          }
        }
      },
      x: {
        ticks: {
          font: {
            weight: 'bold'
          }
        }
      }
    },
    elements: {
      point: {
        radius: context => {
          const lastIndex = context.dataset.data.length - 1;
          return context.dataIndex === lastIndex ? 6 : 4;
        },
        hoverRadius: 7
      }
    },
    onClick: (event, element) => handleBarChartClick(event, element)
  });

  useEffect(() => {
    let newPaddingBottom = 80;

    switch (true) {
      case isXl1:
        newPaddingBottom = 20;
        break;
      case isXl2:
        newPaddingBottom = 50;
        break;
      case isXl3:
        newPaddingBottom = 100;
        break;
      default:
        newPaddingBottom = 10;
        break;
    }
    setChartOptions(prevOptions => ({
      ...prevOptions,
      layout: {
        ...prevOptions.layout,
        padding: {
          bottom: newPaddingBottom
        }
      },
      elements: {
        ...prevOptions.elements,
        point: {
          ...prevOptions.elements?.point,
          radius: context =>
            context.dataIndex ===
            (selectedIndex !== null ? selectedIndex : context.dataset.data.length - 1)
              ? 6
              : 4
        }
      }
    }));
  }, [isXl1, isXl2, isXl3, selectedIndex]);
  return (
    <div className={styles.container}>
      <MaterialCard
        title="tolls"
        subTitle={
          !isLoading && (
            <>
              Showing <b>{moment.utc(aggData.lastMonth).format('MMMM')}</b>
            </>
          )
        }
        cardVariant="child"
        cardWidth={width}
        cardHeight={height}
        noGrid
        showHeaderArrow
      >
        {isLoading && <div className={styles.loader}>Loading...</div>}
        {!isLoading && !showError && tollSpendData && (
          <Grid container>
            <Grid xs={6} sm={6} md={6} lg={6}>
              <div className={styles.total}>
                <h4>Total Tolls</h4>
                <p>{formatNumberToUSStandard(aggData.totalTolls)}</p>
              </div>
            </Grid>
            <Grid xs={6} sm={6} md={6} lg={6}>
              <div className={styles.total}>
                <h4>Total</h4>
                <p>{formatCurrency(aggData.total.toString(), true)}</p>
              </div>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12}>
              <LineChart data={tollSpendData} width="230rem" options={lineChartOptions} />
            </Grid>
          </Grid>
        )}
        {!isLoading && (showError || !tollSpendData) && (
          <div className={styles.error}>
            Something went wrong!
            <div className={styles.retry}>
              <Button
                size="small"
                variant="contained"
                onClick={fetchTollSpendAggData}
                startIcon={<Repeat className={styles.icon} />}
              >
                Retry
              </Button>
            </div>
          </div>
        )}
      </MaterialCard>
    </div>
  );
};

export default memo(TollSpend);
