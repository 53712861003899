import { memo, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import MaterialCard from '@common/ui/Mui/Card/MaterialCard';
import CustomDoughnutChart from '@common/ui/Chart/DoughnutChart/DoughnutChart';
import { formatCurrency } from '@pages/dashboard/common/helper';
import { useTheme } from '@mui/material/styles';
import { Button, Grid, useMediaQuery } from '@mui/material';
import { Repeat } from '@assets/icons';
import { CustomChartOptions } from '@common/ui/Chart/DoughnutChart/type';
import { IFtoProps } from '../type';
import useFtoSavingsData from './hooks/useFtoSavingsData';
import styles from './savings.module.scss';

const Savings = ({ width = '100%', height = '25.7rem', isParentLoading }: IFtoProps) => {
  const {
    fetchFtoSavingsData,
    updateTotalAmount,
    ftoSavingsData,
    dateRange,
    total,
    isLoading,
    showError
  } = useFtoSavingsData(isParentLoading);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  const [donutChartOptions, setDonutChartOptions] = useState<CustomChartOptions>({
    layout: {
      autoPadding: true,
      padding: {
        right: 50, // lg : 65, md: 125, sm: 0, xs: 50
        top: 10,
        bottom: 5
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          pointStyleWidth: 13, // md : 7 lg: 13
          font: {
            size: 10, // md : 5 lg : 10
            weight: 'bold'
          }
        },
        onClick: (e, legendItem, legend) => {
          const dataIndex = legendItem.index || 0;
          legend.chart.toggleDataVisibility(dataIndex);
          const updateArray: boolean[] = [];
          legend.chart.data.datasets[0].data.forEach((_, i) => {
            updateArray.push(legend.chart.getDataVisibility(i));
          });
          updateTotalAmount(updateArray, legend.chart.data.datasets[0].data);
          legend.chart.update();
        }
      },
      datalabels: {
        display: true,
        color: 'black',
        font: {
          size: 10
        },
        formatter: (value, ctx) => {
          if (!value && typeof value !== 'number') {
            return '';
          }
          let sum = 0;
          // If max percent goes beyond this other percent will be hidden
          const maxThresholdPercent = 85;
          const values: Array<number> = [];
          const dataArr = ctx.chart.data.datasets[0].data;
          dataArr.forEach(data => {
            if (data && typeof data === 'number') {
              values.push(data);
              sum += data;
            } else if (data === 0) {
              values.push(data);
            }
          });
          const minValue = Math.min(...values);
          const maxValue = Math.max(...values);
          const maxPercentValue = (maxValue * 100) / sum;
          const currentPercentValue = (value * 100) / sum;
          const isMaxPerValHighThanThresh = maxPercentValue > maxThresholdPercent;
          if (currentPercentValue !== maxPercentValue && isMaxPerValHighThanThresh) {
            return '';
          }
          if (value === minValue && currentPercentValue < 3) {
            return '';
          }
          const percentage = `${currentPercentValue.toFixed(1)}%`;
          return percentage;
        }
      },
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            let label = tooltipItem.formattedValue || '';

            if (label) {
              label = `Amount : $${label}`;
            }
            return label;
          }
        }
      }
    }
  });

  useEffect(() => {
    let newPadding = { right: 50, top: 10, bottom: 5 };
    let newPointStyleWidth = 13;
    let newFontSize = 10;
    switch (true) {
      case isLg:
        newPadding = { right: 65, top: 10, bottom: 5 };
        newPointStyleWidth = 13;
        newFontSize = 10;
        break;
      case isMd:
        newPadding = { right: 120, top: 0, bottom: 5 };
        newPointStyleWidth = 8;
        newFontSize = 6;
        break;
      case isSm:
        newPadding = { right: 0, top: 0, bottom: 5 };
        newPointStyleWidth = 7;
        newFontSize = 5;
        break;
      default:
        newPadding = { right: 50, top: 10, bottom: 5 };
        newPointStyleWidth = 13;
        newFontSize = 10;
        break;
    }

    setDonutChartOptions(prevOptions => ({
      ...prevOptions,
      layout: {
        ...prevOptions.layout,
        padding: newPadding
      },
      plugins: {
        ...prevOptions.plugins,
        legend: {
          ...prevOptions.plugins?.legend,
          labels: {
            ...prevOptions.plugins?.legend?.labels,
            pointStyleWidth: newPointStyleWidth,
            font: {
              ...prevOptions.plugins?.legend?.labels?.font,
              size: newFontSize
            }
          }
        }
      }
    }));
  }, [isXs, isSm, isMd, isLg, isXl]);
  return (
    <div className={styles.container}>
      <MaterialCard
        title="Unrealized Savings"
        subTitle={
          !isLoading && (
            <>
              Showing <b>{moment.utc(dateRange.from).format('MMM YYYY')} </b> to{' '}
              <b>{moment.utc(dateRange.to).format('MMM YYYY')}</b>
            </>
            // <>
            //   Showing <b>{formatMonthYear(dateRange.from)} </b> to{' '}
            //   <b>{formatMonthYear(dateRange.to)}</b>
            // </>
          )
        }
        cardVariant="child"
        cardWidth={width}
        cardHeight={height}
        maxCols={6}
        noGrid
        showHeaderArrow
      >
        {isLoading && <div className={styles.loader}>Loading...</div>}
        {!isLoading && !showError && ftoSavingsData && (
          <Grid container>
            <Grid xs={6} sm={6} md={6} lg={6}>
              <div className={styles.total}>
                <h3>Total</h3>
                <br /> <span>{formatCurrency(total, true)}</span>
              </div>
            </Grid>
            <Grid xs={6} sm={6} md={6} lg={6}>
              <CustomDoughnutChart
                chartOptions={donutChartOptions}
                data={ftoSavingsData}
                width={250}
                height={230}
              />
            </Grid>
          </Grid>
        )}
        {!isLoading && (showError || !ftoSavingsData) && (
          <div className={styles.error}>
            Something went wrong!
            <div className={styles.retry}>
              <Button
                size="small"
                variant="contained"
                onClick={fetchFtoSavingsData}
                startIcon={<Repeat className={styles.icon} />}
              >
                Retry
              </Button>
            </div>
          </div>
        )}
      </MaterialCard>
    </div>
  );
};

export default memo(Savings);
