import { PageHeader } from '@components/layouts/headers';
import Meta from '@components/meta';
import DashboardNavigation from '@app/layouts/main/components/GeotabNavigation/DashboardNavigation';
import Main from './Dashboard';

const Dashboard = () => {
  const metaOptions = {
    title: 'Dashboard',
    keywords: 'Dashboard'
  };
  return (
    <>
      <DashboardNavigation />
      <Meta {...metaOptions} />
      {/* <Help location="dashboard" /> */}
      <PageHeader title="dashboard" />
      <Main />
    </>
  );
};

export default Dashboard;
