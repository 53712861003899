import Meta from '@components/meta';
import DashboardNavigation from './InsightDashboardNavigation';
import Main from './InsightDashboards';

const Dashboard = () => {
  const metaOptions = {
    title: 'Dashboard',
    keywords: 'Dashboard'
  };
  return (
    <div>
      <DashboardNavigation />
      <Meta {...metaOptions} />
      <Main />
    </div>
  );
};

export default Dashboard;
