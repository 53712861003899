/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState, useEffect } from 'react';
import api from '@api/services/common';
import { IApiResponse, IDonutChartData, IDisputableTollsData } from '../type';

const useDisputableTollsData = (): {
  disputableTollsData: IDonutChartData | undefined;
  dateRange: { from: string; to: string };
  isLoading: boolean;
  showError: boolean;
  total: string;
  count: number;
  fetchDisputableTollsData: () => Promise<void>;
  updateTotalAmount: (visibilityArray: boolean[], data?: number[]) => void;
  aggData: { amount: number[]; count: number[] };
} => {
  const [disputableTollsData, setDisputableTollsData] = useState<IDonutChartData>();
  const [total, setTotal] = useState('');
  const [count, setCount] = useState(0);
  const [dateRange, setDateRange] = useState({ from: '', to: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  let counts: number[] = [];
  const [aggData, setAggData] = useState<{ amount: number[]; count: number[] }>({
    amount: [],
    count: []
  });

  const tooltip = (data: IDisputableTollsData[]) => {
    return {
      countData: data.map(item => Math.floor(parseFloat(item.total_instance_count))),
      amountData: data.map(item => Math.round(parseFloat(item.total_amount)))
    };
  };

  const transformDataForDonutChart = (data: IDisputableTollsData[]) => {
    const labels = data.map(item => item.categoryname);
    let totalAmount = 0;
    let totalCount = 0;
    const values = data.map(item => Number(item.total_amount));
    counts = data.map(item => Number(item.total_instance_count));

    const calculatePercentages = (percentValues: number[]): string[] => {
      const percentTotal = percentValues.reduce((acc, val) => acc + val, 0);
      return percentValues.map(val => `${((val / percentTotal) * 100).toFixed(1)}%`);
    };

    data.forEach(item => {
      totalAmount += parseFloat(item.total_amount);
      totalCount += parseFloat(item.total_instance_count);
    });

    setTotal(Math.round(totalAmount).toString());
    setCount(totalCount);

    const percentages = calculatePercentages(values);

    const chartData: IDonutChartData = {
      labels: labels.map((label, index) => `${label} (${percentages[index]})`),
      values,
      colors: ['#CFE2F3', '#6D9EEB', '#FFE599'],
      borderColors: ['#CFE2F3', '#6D9EEB', '#FFE599'],
      borderWidth: 1,
      label: 'Disputable tolls'
    };
    const tooltipData = tooltip(data);
    aggData.count = tooltipData.countData;
    aggData.amount = tooltipData.amountData;
    setAggData({ ...aggData });
    setDisputableTollsData(chartData);
  };

  const updateTotalAmount = (visibilityArray: boolean[], data?: number[]): void => {
    const filteredValues = data?.reduce((result, value, index) => {
      if (visibilityArray[index]) {
        result.push(value);
      }
      return result;
    }, [] as number[]);

    const filteredCounts = counts?.reduce((result, value, index) => {
      if (visibilityArray[index]) {
        result.push(value);
      }
      return result;
    }, [] as number[]);

    const sumOfFilteredPercentage = filteredCounts?.reduce((sum, value) => sum + value, 0);

    const sumOfFilteredValues = filteredValues?.reduce((sum, value) => sum + value, 0);
    setTotal(Math.floor(Number(sumOfFilteredValues)).toString());
    setCount(sumOfFilteredPercentage);
  };

  const fetchDisputableTollsData = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const response: IApiResponse = await api.post('/dashboard/disputable-tolls', {
        moduleId: 'INSIGHTS'
      });
      if (response.isSuccess && response.data) {
        transformDataForDonutChart(response.data.data);
        dateRange.from = response.data.dateRange.start_date;
        dateRange.to = response.data.dateRange.end_date;
        setDateRange({ ...dateRange });
      } else {
        setShowError(true);
      }
    } catch (err) {
      console.log('Error in calling disputable tolls ==> ', err);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDisputableTollsData().catch(err => {
      console.log('Error in disputable tolls data ==> ', err);
    });
  }, []);

  return {
    disputableTollsData,
    dateRange,
    total,
    count,
    isLoading,
    showError,
    fetchDisputableTollsData,
    updateTotalAmount,
    aggData
  };
};

export default useDisputableTollsData;
