/* eslint-disable */
import { useEffect, useCallback } from 'react';
import _ from 'lodash';
import { store } from '@store/index';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { clearTable, getTripQuickFilters, setTable, setTableName } from '@store/data';
import { setPagination } from '@store/features/pagination';
import { useModifyData } from '@pages/tables/hooks/useModifyData';
import { useSearchTripsMutation } from '@api/services/trips';
import { ETableName } from '@common/utils/enums';

export const useSetTable = ({ name }: { name: ETableName }, quickFilters?: any) => {
  const dispatch = useAppDispatch();

  const body = useModifyData(name);
  const [searchTrips] = useSearchTripsMutation();

  const customerId = useAppSelector(state => state.customer.id);
  const isGeotab = store.getState().user?.sourceType
    ? _.kebabCase(store.getState().user.sourceType) === _.kebabCase('GEOTAB')
    : window.location !== window.parent.location;

  // const pagination = useAppSelector(state => state.pagination);
  // const { data, isLoading, isSuccess, isError, error, isFetching } = useGetTripsQuery(
  //   [customerId, pagination.trips.currentPage - 1, pagination.trips.showBy],
  //   {
  //     skip: !customerId,
  //     pollingInterval: 600000
  //   }
  // );

  const handleTrips = useCallback(async () => {
    dispatch(clearTable());
    dispatch(setTableName(name));
    if (quickFilters) {
      body.quickFilter = {
        vehicleNames: quickFilters.vehicleNames,
        licensePlateNumbers: quickFilters.licensePlateNumbers
      };
      if (quickFilters?.amount) {
        body.toll = {
          from: quickFilters.amount?.min || 0,
          to: quickFilters.amount?.max || 0
        };
      }
    }
    if (
      customerId &&
      body.customerId &&
      name === ETableName.TRIPS &&
      customerId === body.customerId
    ) {
      //   dispatch(setTable({ data, isLoading, isError, isSuccess, error, name, isFetching }));
      //   dispatch(
      //     setPagination({
      //       table: name,
      //       dataLength: totalElements || 0,
      //       totalPages: totalPages || 0
      //     })
      //   );
      searchTrips({ ...body })
        .unwrap()
        .then(res => {
          dispatch(
            setTable({
              data: res.pageContent,
              isLoading: false,
              isError: false,
              isSuccess: true,
              error: undefined,
              isFetching: false
            })
          );
          dispatch(
            setPagination({
              table: ETableName.TRIPS,
              dataLength: res.totalElements || 0,
              totalPages: res.totalPages || 0
            })
          );
          if (!isGeotab) {
            dispatch(getTripQuickFilters()).catch(err => {
              console.log('Err in fetching trip quick filters ==> ', err);
            });
          }
        })
        .catch(e => {
          console.log(e);
          dispatch(
            setTable({
              data: undefined,
              isLoading: false,
              isError: true,
              isSuccess: false,
              error: undefined,
              isFetching: false
            })
          );
        });
    } else {
      dispatch(
        setTable({
          data: undefined,
          isLoading: false,
          isError: false,
          isSuccess: true,
          error: undefined,
          isFetching: false
        })
      );
    }
  }, [isGeotab, body, dispatch, searchTrips, quickFilters, name, customerId]);

  useEffect(() => {
    handleTrips().catch(e => console.log(e));
  }, [handleTrips]);
};
