export function PieChart({ className }: ClassName) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Layer_1"
      viewBox="0 0 242.588 242.588"
      xmlSpace="preserve"
      width="1em"
      height="1em"
    >
      <g>
        <circle style={{ fill: '#32A5B9' }} cx="121.294" cy="121.294" r="121.294" />
        <path
          style={{ fill: '#2B8A9A' }}
          d="M121.294,242.588C54.305,242.588,0,188.283,0,121.294S54.305,0,121.294,0V242.588z"
        />
        <g>
          <path
            style={{ fill: '#E45527' }}
            d="M121.294,0v121.294h121.294C242.588,54.305,188.283,0,121.294,0z"
          />
        </g>
        <path
          style={{ fill: '#F8B242' }}
          d="M121.294,121.294l90.846,80.358c16.323-18.44,27.123-41.882,29.796-67.739 c0.429-4.148,0.651-8.357,0.651-12.619H121.294z"
        />
      </g>
    </svg>
  );
}
