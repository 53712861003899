/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */
import { ChartOptions } from 'chart.js';
import { memo, useMemo, useState } from 'react';
import MaterialCard from '@common/ui/Mui/Card/MaterialCard';
import { createTheme, Grid, useMediaQuery } from '@mui/material';
import {
  formatMonth,
  formatNumberToCurrency,
  formatNumberToCurrencyNoDecimal,
  formatNumberToUSStandard
} from '@pages/dashboard/common/helper';
import styles from '../trip-value.module.scss';
import BarChart from '@common/ui/Chart/BarChart/BarChart';
import { Plugin } from 'chart.js';
import warningImage from '@assets/images/exclamation-triangle.svg'; // Import the image

const TollTripCost = (chartData: any) => {
  const width = '100%';
  const height = '30rem';

  const data = chartData?.chart_data;
  const value_total = Math.floor(data?.datasets[0]?.trip_total) || 0;
  const no_value_total = Math.floor(data?.datasets[1]?.trip_total) || 0;
  const total = Number(value_total) + Number(no_value_total) || 0;

  const theme = createTheme();
  const useResponsiveFontSize = () => {
    const xs = useMediaQuery(theme.breakpoints.down('xs')); // Extra-small screens
    const sm = useMediaQuery(theme.breakpoints.between('xs', 'sm')); // Small screens
    const md = useMediaQuery(theme.breakpoints.between('sm', 'md')); // Medium screens
    const lg = useMediaQuery(theme.breakpoints.between('md', 'lg')); // Large screens
    const xl = useMediaQuery(theme.breakpoints.up('lg')); // Extra-large screens

    if (xs) return 6;
    if (sm) return 7;
    if (md) return 7;
    if (lg) return 7;
    return 9; // xl
  };
  const fontSize = useResponsiveFontSize();
  const customSymbol = useMemo<Plugin<'bar'>>(() => {
    const imageCache = new Map();

    return {
      id: 'customSymbol',
      afterDraw: chart => {
        const ctx = chart.ctx;
        // Enable high-quality image scaling
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';

        if (chart.data.labels) {
          chart.data.labels.forEach((label, index) => {
            let allZero = true;
            chart.data.datasets.forEach(dataset => {
              let data: any = dataset.data[index];
              if (Number(data.valued_trip_total) || Number(data.novalued_trip_total)) {
                allZero = false;
              }
            });

            if (allZero) {
              const meta = chart.getDatasetMeta(0); // Get meta for the first dataset to access bar coordinates
              const bar = meta.data[index];

              // Cache the image
              if (!imageCache.has(index)) {
                const image = new Image();
                image.src = warningImage; // Use the imported image
                image.onload = () => {
                  imageCache.set(index, image);
                  const x = bar.x - 15; // Adjust to center the image
                  const y = chart.scales.y.getPixelForValue(0) - 30; // Adjust to position the image above the x-axis
                  ctx.drawImage(image, x, y, 30, 30); // Adjust the size of the image
                };
              } else {
                const cachedImage = imageCache.get(index);
                const x = bar.x - 15; // Adjust to center the image
                const y = chart.scales.y.getPixelForValue(0) - 30; // Adjust to position the image above the x-axis
                ctx.drawImage(cachedImage, x, y, 30, 30); // Adjust the size of the image
              }
            }
          });
        }
      }
    };
  }, [warningImage]);

  const [chartOptions, setChartOptions] = useState<ChartOptions<'bar'>>({
    parsing: {
      xAxisKey: 'xaxis' // Add xAxisKey if you want to parse the x values as well
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      autoPadding: true
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'top',
        offset: 0,
        textAlign: 'right',
        font: {
          size: fontSize
        },
        formatter: (value, context) => {
          let datasets: any = [context.chart.data.datasets[0]]; //take first array item only and convert to array.
          const valuedtotal = datasets.reduce(
            (sum: number, dataset: any) =>
              sum + Number(dataset.data[context.dataIndex]['valued_trip_total']),

            0
          );

          datasets = [context.chart.data.datasets[1]]; //take 2nd array item only and convert to array.
          const novaluedtotal = datasets.reduce(
            (sum: number, dataset: any) =>
              sum + Number(dataset.data[context.dataIndex]['novalued_trip_total']),
            0
          );

          let sum = valuedtotal + novaluedtotal;
          if (context.datasetIndex === 1 && Number(sum)) {
            return `$${Math.floor(sum).toLocaleString()}`;
          } else {
            return '';
          }
          // return context.datasetIndex === 1 ? valuedtotal + novaluedtotal: '';
        }
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (context) {
            return formatMonth(context[0].label);
          },

          label: function (context) {
            //console.log(context);
            const keyprefix = context.datasetIndex === 0 ? 'valued_' : 'novalued_';
            const rawdata: any = context.raw !== undefined ? context.raw : {};
            const trip_total = rawdata[keyprefix + 'trip_total'];

            const trip = `${formatNumberToCurrencyNoDecimal(Number(trip_total), '$')}`;
            return [trip];
          }
        }
      }
    },
    scales: {
      y: {
        stacked: true,
        title: {
          display: false
        },
        min: 0,
        ticks: {
          display: true,
          font: {
            weight: 'bold'
          },
          callback: (value: string | number) => {
            if (typeof value === 'number') {
              if (value >= 100000) {
                return `${(value / 1000).toLocaleString()}K`;
              }
              return `$${value.toLocaleString()}`;
            }
            return value;
          }
        }
      },
      x: {
        stacked: true,
        grid: {
          display: false
        },
        ticks: {
          font: {
            weight: 'bold'
          }
        }
      }
    }
  });
  return (
    <MaterialCard
      title="Toll Trip Cost"
      cardVariant="child"
      cardWidth={width}
      cardHeight={height}
      noGrid
      showHeaderArrow
    >
      <Grid container spacing={2} className={styles.gridContainer}>
        <Grid item xs={9} sm={9} md={9} lg={9} xl={9.7}>
          <BarChart data={data} width="100%" options={chartOptions} plugins={[customSymbol]} />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={2.3}>
          <div className={styles.total}>
            <div className={styles.totalItem}>
              <div className={`${styles.squareBox} ${styles.invoiced}`} />
              <h4>No-value</h4>
            </div>
            <p className={styles.value}>{formatNumberToCurrency(no_value_total, '$')}</p>
            <div className={styles.totalItem}>
              <div className={`${styles.squareBox} ${styles.tollogic}`} />
              <h4>Value</h4>
            </div>
            <p className={styles.value}>{formatNumberToCurrency(value_total, '$')}</p>
            <div className={styles.totalItem}>
              <div className={styles.squareBox} />
              <h4>Total</h4>
            </div>
            <p className={styles.value}>{formatNumberToCurrency(total, '$')}</p>
          </div>
        </Grid>
      </Grid>
    </MaterialCard>
  );
};
export default memo(TollTripCost);
