import { CustomerDataInterface } from '@common/types/data';

export const initialValues: Omit<
  CustomerDataInterface,
  | 'id'
  | 'companyLogo'
  | 'phoneNumber'
  | 'logoContent'
  | 'sourceType'
  | 'isVisible'
  | 'loading'
  | 'isSuperAdmin'
> & { logo: File | null } = {
  adminEmail: '',
  companyName: '',
  companyAddress: '',
  geotabIntegration: false,
  geotabDatabase: '',
  geotabServiceUser: '',
  geotabServicePassword: '',
  statusCode: 'INACTIVE',
  logo: null
};
